import { CONTEST_TEMPLATES } from '../../constants';
import api from '../../utils/api';
import updateContests from './update';

export default () => async (dispatch, getState) => {
  try {
    if (!getState().user?.isLoggedIn) return;

    const contests = (await api.get('/user/contests'))?.data;
    const contestNames = Object.keys(contests);

    if (contestNames.length <= 0) {
      return;
    }

    const contestsDetails = {};

    for (let i = 0; i < contestNames.length; i++) {
      const name = contestNames[i];
      contestsDetails[name] = {
        contestData: (await api.get(`/user/contest/${name}`))?.data,
        state: contests[name],
        name,
        template: CONTEST_TEMPLATES[name],
      };
    }

    if (!getState().user?.isLoggedIn) return;

    dispatch(updateContests(contestsDetails));
  } catch (err) {
    console.log({ contestError: err });
    console.log('could not load contest');
  }
};
