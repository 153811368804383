import '../MyContent/MyContent.scss';
import './Videos.scss';

import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar/Scrollbar';
import GalleryInfo from './../GalleryInfo';
import StartingPhotosInfo from '../Photos/StartingPhotosInfo';
import { getValidationScheme } from './Videos.functions';
import VideosInfo from './VideosInfo';

function Videos({ items, initialValues, galleries }) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const location = useLocation();
  const intl = useIntl();
  const [totalMovies, setTotalMovies] = useState(null);

  const [, totalMoviesReq] = useAxios(
    {
      url: `galleries/total?type=movies`,
    },
    { manual: true }
  );

  useEffect(() => {
    let isMounted = true;
    totalMoviesReq()
      .then((response) => {
        isMounted && setTotalMovies(response?.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (isMounted = false);
  }, [totalMoviesReq]);

  const routes = items.map((route, i) => {
    const Component = route.component;
    return (
      <Route
        key={`/my-content/videos${route.path}`}
        path={route.path}
        element={<Component />}
      />
    );
  });

  return (
    <Scrollbar visible={!isLargeScreen}>
      .
      <div className="my-content-wrapper videos-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Routes>
              {routes}
              <Route path={'*'} element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            {location.pathname.includes('my-videos') && (
              <>{totalMovies > 0 ? <VideosInfo /> : <StartingPhotosInfo />}</>
            )}{' '}
            {location.pathname.includes('edit-gallery') && (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={getValidationScheme(intl)}
              >
                <GalleryInfo />
              </Formik>
            )}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Videos;
