import { useDropzone } from 'react-dropzone';

function useGetVideoGalleriesDropzone({
  acceptedVideoFileTypes,
  acceptedThumbnailFileTypes,
  dropzoneProps,
  onDrop,
  onDropThumbnail,
  containsVideo,
}) {
  const {
    getRootProps,
    getInputProps,
    open: openVideo,
  } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: containsVideo,
    accept: acceptedVideoFileTypes,
  });

  const {
    getRootProps: getRootPropsThumbnail,
    getInputProps: getInputPropsThumbnail,
    open: openThumbnail,
  } = useDropzone({
    ...dropzoneProps,
    onDrop: onDropThumbnail,
    noClick: true,
    noKeyboard: true,
    accept: acceptedThumbnailFileTypes,
  });

  return {
    getRootProps,
    getInputProps,
    openVideo,
    getRootPropsThumbnail,
    getInputPropsThumbnail,
    openThumbnail,
  };
}

export default useGetVideoGalleriesDropzone;
