import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import SettingsDE from '../../../../../assets/img/wiki/wiki_settings_de.jpg';
import SettingsEN from '../../../../../assets/img/wiki/wiki_settings_en.jpg';
import BackButton from '../../../../BackButton';

function Settings({ locale, unconfirmed, onBackButtonClicked }) {
  return (
    <div className="wiki-content wiki-settings-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="SETTINGS_HEADLINE" />
      </h1>
      <p className="has-margin-bottom">
        <FormattedMessage
          id="SETTINGS_INTRO"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="SETTINGS_INTRO_LINK" />
            ) : (
              <Link
                key="settings-link-options"
                to="/my-account/settings/options"
              >
                <FormattedMessage id="SETTINGS_INTRO_LINK" />
              </Link>
            ),
          }}
        />
      </p>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="SETTINGS_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SETTINGS_CONTENT_STEP_1_CONTENT" />
          </p>
          <p className="has-margin-bottom">
            <FormattedMessage
              id="SETTINGS_CONTENT_STEP_1_1_CONTENT"
              values={{
                link: (
                  <Link
                    key="settings-link-creator-tags"
                    to="/wiki/general#creatorTags"
                  >
                    <FormattedMessage id="SETTINGS_CONTENT_STEP_1_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="SETTINGS_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p className="has-margin-bottom">
            <FormattedMessage
              id="SETTINGS_CONTENT_STEP_2_CONTENT"
              values={{
                link: (
                  <Link
                    key="settings-link-livecam-settings"
                    to="/wiki/general#livecamSetting"
                  >
                    <FormattedMessage id="SETTINGS_CONTENT_STEP_2_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>

      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive border-light-gray"
            src={SettingsEN}
            alt="Settings"
          />
        ) : (
          <img
            className="img-responsive border-light-gray"
            src={SettingsDE}
            alt="Einstellungen"
          />
        )}
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="SETTINGS_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p className="has-margin-bottom">
            <FormattedMessage
              id="SETTINGS_CONTENT_STEP_3_CONTENT"
              values={{
                link: (
                  <Link
                    key="settings-link-lovense"
                    to="/wiki/general#lovenseInstallation"
                  >
                    <FormattedMessage id="SETTINGS_CONTENT_STEP_3_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
        <div className="wiki-list-item">
          <div className="wiki-list-headline">
            <span className="number">4</span>
            <span className="bold">
              <FormattedMessage id="SETTINGS_CONTENT_STEP_4" />
            </span>
          </div>
          <div className="wiki-list-content">
            <p>
              <FormattedMessage id="SETTINGS_CONTENT_STEP_4_CONTENT" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
