import api from '../../../utils/api';
import userUtils from '../../../utils/user';
import { USER_PROFILE_GET } from '../../actions';
import { errorLog } from '../../log';

export default function (user) {
  return async (dispatch, getState) => {
    if (!getState().user?.isLoggedIn) {
      return;
    }
    let userId = user?.userId;
    let partner;
    if (user?.partner) {
      partner = user?.partner;
    } else if (userUtils.hasPartner(user)) {
      partner = userUtils.getPartner(user);
    } else {
      return;
    }
    let personalResponse;
    try {
      personalResponse = await api.get(`/user/ignored_by/${partner}`);
      const profile = {
        ignored_by: personalResponse?.data?.status,
      };
      dispatch({
        type: USER_PROFILE_GET,
        payload: { userId, ...profile },
      });
    } catch (error) {
      const errorObject = {
        location: 'getUserProfilePersonal',
        error,
      };
      dispatch(errorLog(errorObject));
      console.error(error);
    }
  };
}
