import './LivecamFaq.scss';

import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import adminIcon from '../../../../../assets/img/svg/admin.svg';
import camIcon from '../../../../../assets/img/svg/cam.svg';
import bubbleIcon from '../../../../../assets/img/svg/chatbubble.svg';
import desktopIcon from '../../../../../assets/img/svg/desktop.svg';
import exclusiveIcon from '../../../../../assets/img/svg/exclusive.svg';
import mobileIcon from '../../../../../assets/img/svg/mobile.svg';
import speakerOffIcon from '../../../../../assets/img/svg/speaker-off.svg';
import voyeurIcon from '../../../../../assets/img/svg/voyeur.svg';
import exclusiveDE from '../../../../../assets/img/wiki/faq-livecam/exclusive_de.png';
import exclusiveEN from '../../../../../assets/img/wiki/faq-livecam/exclusive_en.png';
import hasVoyeurDE from '../../../../../assets/img/wiki/faq-livecam/has-voyeur_de.png';
import hasVoyeurEN from '../../../../../assets/img/wiki/faq-livecam/has-voyeur_en.png';
import kickDE from '../../../../../assets/img/wiki/faq-livecam/kick_de.png';
import kickEN from '../../../../../assets/img/wiki/faq-livecam/kick_en.png';
import noVoyeurDE from '../../../../../assets/img/wiki/faq-livecam/no-voyeur_de.png';
import noVoyeurEN from '../../../../../assets/img/wiki/faq-livecam/no-voyeur_en.png';
import BackButton from '../../../../BackButton';

function LivecamFaq({
  locale,
  isLivecamCreator,
  nickname,
  onBackButtonClicked,
}) {
  const intl = useIntl();
  const account = nickname
    ? nickname
    : intl.formatMessage({ id: 'LINK_TEXT_ACCOUNT' });
  return (
    <div className="wiki-content livecam-faq-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="LIVECAM_FAQ_HEADLINE" />
      </h1>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_1">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_1_2" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_2" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_3" />
          </p>
          <p className="list-headline">
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_3_1" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_3_1_1" />
            </li>
            <li>
              <FormattedMessage
                id="LIVECAM_FAQ_ANSWER_3_1_2"
                values={{
                  link: isLivecamCreator ? (
                    <Link
                      key="livecam-faq-link-livecam"
                      to="/my-account/settings/livecam"
                    >
                      <FormattedMessage
                        id="LINK_TEXT_CAM_SETTINGS"
                        values={{ account }}
                      />
                    </Link>
                  ) : (
                    <FormattedMessage
                      id="LINK_TEXT_CAM_SETTINGS"
                      values={{ account }}
                    />
                  ),
                }}
              />
            </li>
          </ul>

          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_3_2" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_3_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <div className="icon-box">
            <div className="icon-description round">
              <ReactSVG
                src={camIcon}
                wrapper="span"
                className="icon cam active"
              />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_4" />
            </div>
            <div className="icon-description round">
              <ReactSVG
                src={voyeurIcon}
                wrapper="span"
                className="icon voyeur active"
              />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_4_1" />
            </div>
            <div className="icon-description round">
              <ReactSVG src={exclusiveIcon} wrapper="span" className="icon" />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_4_2" />
            </div>
            <div className="icon-description round">
              <ReactSVG src={camIcon} wrapper="span" className="icon" />
              <ReactSVG src={voyeurIcon} wrapper="span" className="icon" />
              <ReactSVG
                src={exclusiveIcon}
                wrapper="span"
                className="icon exclusive active"
              />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_4_3" />
            </div>
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">5</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_5" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="LIVECAM_FAQ_ANSWER_5"
              values={{
                link: isLivecamCreator ? (
                  <Link
                    key="livecam-faq-link-prices"
                    to="/my-account/settings/prices"
                  >
                    <FormattedMessage
                      id="LINK_TEXT_PRICE"
                      values={{ account }}
                    />
                  </Link>
                ) : (
                  <FormattedMessage id="LINK_TEXT_PRICE" values={{ account }} />
                ),
              }}
            />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">6</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_6" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_6" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_6_1" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">7</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_7" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_7" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive"
                src={exclusiveEN}
                alt="Start exclusive"
              />
            ) : (
              <img
                className="img-responsive"
                src={exclusiveDE}
                alt="Exklusiv Show"
              />
            )}
          </div>
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_7_1" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">8</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_8" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_8" />
          </p>
          <div className="image-wrapper-not-center">
            <div className="img-description">
              {locale === 'en' ? (
                <img
                  className="img-responsive"
                  src={noVoyeurEN}
                  alt="Voyeurs"
                />
              ) : (
                <img
                  className="img-responsive"
                  src={noVoyeurDE}
                  alt="Voyeure"
                />
              )}
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_8_1" />
            </div>
            <div className="img-description">
              {locale === 'en' ? (
                <img
                  className="img-responsive"
                  src={hasVoyeurEN}
                  alt="Voyeurs"
                />
              ) : (
                <img
                  className="img-responsive"
                  src={hasVoyeurDE}
                  alt="Voyeure"
                />
              )}
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_8_2" />
            </div>
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">9</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_9" />
          </span>
        </div>
        <div className="wiki-list-content">
          <div className="icon-box">
            <div className="icon-description">
              <span className="icon2 new">
                <FormattedMessage id="new.user" />
              </span>
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_9" />
            </div>
            <div className="icon-description">
              <ReactSVG
                src={bubbleIcon}
                wrapper="span"
                className="icon2 bubble"
              />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_9_1" />
            </div>
            <div className="icon-description">
              <ReactSVG
                src={adminIcon}
                wrapper="span"
                className="icon2 admin"
              />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_9_2" />
            </div>
            <div className="icon-description">
              <ReactSVG src={desktopIcon} wrapper="span" className="icon2" />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_9_3" />
            </div>
            <div className="icon-description">
              <ReactSVG src={mobileIcon} wrapper="span" className="icon2" />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_9_4" />
            </div>
            <div className="icon-description">
              <ReactSVG src={speakerOffIcon} wrapper="span" className="icon2" />
              <FormattedMessage id="LIVECAM_FAQ_ANSWER_9_5" />
            </div>
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">10</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_10" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_10" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">11</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_11" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="LIVECAM_FAQ_ANSWER_11"
              values={{
                link: isLivecamCreator ? (
                  <Link
                    key="livecam-faq-link-livecam"
                    to="/my-account/settings/livecam"
                  >
                    <FormattedMessage id="LINK_TEXT_STREAM_SETTINGS" />
                  </Link>
                ) : (
                  <FormattedMessage id="LINK_TEXT_STREAM_SETTINGS" />
                ),
              }}
            />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">12</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_12" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_12" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive border-light-gray"
                src={kickEN}
                alt="Kick"
              />
            ) : (
              <img
                className="img-responsive border-light-gray"
                src={kickDE}
                alt="Kicken"
              />
            )}
          </div>
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_12_1">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">13</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_13" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_13" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_13_1">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">14</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_14" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_FAQ_ANSWER_14" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">15</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_FAQ_QUESTION_15" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="LIVECAM_FAQ_ANSWER_15"
              values={{
                link: isLivecamCreator ? (
                  <Link
                    key="livecam-faq-link-livecam"
                    to="/my-account/settings/livecam"
                  >
                    <FormattedMessage
                      id="LINK_TEXT_CAM_SETTINGS"
                      values={{ account }}
                    />
                  </Link>
                ) : (
                  <FormattedMessage
                    id="LINK_TEXT_CAM_SETTINGS"
                    values={{ account }}
                  />
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default LivecamFaq;
