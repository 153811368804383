import './Contest.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import BannerImageDE from '../../../../assets/img/xmascontest2024/banner_de.jpg';
import BannerImageEN from '../../../../assets/img/xmascontest2024/banner_en.jpg';
import ContestLogoDE from '../../../../assets/img/xmascontest2024/contest-logo-de.png';
import ContestLogoEN from '../../../../assets/img/xmascontest2024/contest-logo-en.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import ContestComponent from '../../../Contest';
import PageSection from '../../../PageSection/PageSection';

const transformRewards = (rewards, winners) => {
  const keys = Object.keys(rewards)
    .map((k) => parseInt(k, 10))
    .sort((a, b) => a - b);

  const result = [];
  let combinedBonus = rewards[keys[0]];
  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i];
    const nextKey = keys[i + 1];
    const priceAmount = rewards[currentKey];

    if (currentKey >= 3 && combinedBonus <= rewards[keys[0]]) {
      combinedBonus += rewards[currentKey];
    }

    if (nextKey) {
      if (nextKey === currentKey + 1) {
        result.push({
          start: currentKey,
          end: currentKey,
          amount: priceAmount,
        });
      } else {
        result.push({
          start: currentKey,
          end: nextKey - 1,
          amount: priceAmount,
        });
      }
    } else {
      result.push({ start: currentKey, end: winners, amount: priceAmount });
    }
  }
  return { data: result, combinedBonus: combinedBonus };
};

function Contest({ isMobile, locale, contest, showRanking }) {
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');
  const state = contest?.state;
  const winners = contest?.contestData?.options?.winners;
  const rewards = contest?.contestData?.options?.rewards;

  const className = classNames('wiki-container', 'contest-container', {
    mobile: isMobile,
    [`locale-${locale}`]: !!locale,
    [`contest-${state}`]: !!state,
  });

  const rewardsData = transformRewards(rewards, winners);

  return (
    <PageSection
      rightColumn={
        <RightColumn
          locale={locale}
          isContentSectionDisplayed={isContentSectionDisplayed}
          rewardsData={rewardsData}
          winners={winners}
        />
      }
      className={className}
      containsHeader={true}
    >
      <h1 className="headline">
        <FormattedMessage id="CONTEST_WIKI_HEADLINE" />
      </h1>
      <div className="text-container">
        {locale === 'en' ? (
          <img
            className="contest-logo"
            src={ContestLogoEN}
            alt="XMAS Contest Logo"
          />
        ) : (
          <img
            className="contest-logo"
            src={ContestLogoDE}
            alt="XMAS Contest Logo"
          />
        )}
        <div className="intro-text">
          <p>
            <FormattedMessage id="CONTEST_WIKI_INTRO_1" />
          </p>
          <p>
            <FormattedMessage id="CONTEST_WIKI_INTRO_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>
      {showRanking && <ContestComponent />}
      {!isContentSectionDisplayed && (
        <RightColumn
          locale={locale}
          isContentSectionDisplayed={isContentSectionDisplayed}
          rewardsData={rewardsData}
          winners={winners}
        />
      )}
    </PageSection>
  );
}

function RightColumn({
  locale,
  isContentSectionDisplayed,
  rewardsData,
  winners,
}) {
  const intl = useIntl();
  const rankText = intl.formatMessage({ id: 'TABLE_RANK_TEXT' });
  const bonusText = intl.formatMessage({ id: 'TABLE_BONUS_TEXT' });
  const concatText = intl.formatMessage({ id: 'TABLE_RANK_CONCAT' });

  const combinedBonus = rewardsData.combinedBonus.toLocaleString(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div className="right-column">
      {isContentSectionDisplayed &&
        (locale === 'en' ? (
          <img className="banner" src={BannerImageEN} alt="XMAS Contest" />
        ) : (
          <img className="banner" src={BannerImageDE} alt="XMAS Contest" />
        ))}

      <h2 className="headline">
        <FormattedMessage id="CONTEST_WIKI_INFO_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>

      <table className="has-border noHover table300">
        <tbody>
          {rewardsData?.data.map(({ start, end, amount }) => {
            const rankRange =
              start === end
                ? `${rankText} ${start}`
                : `${rankText} ${start} ${concatText} ${end}`;
            return (
              <tr key={start}>
                <td>{rankRange}</td>
                <td className="align-right">
                  {amount.toLocaleString(locale, {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{' '}
                  {bonusText}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_2">
          {(message) => {
            let replacedMessage = message[0];
            replacedMessage = replacedMessage.replace(
              '{{combined_bonus}}',
              combinedBonus
            );
            return (
              <span dangerouslySetInnerHTML={{ __html: replacedMessage }} />
            );
          }}
        </FormattedMessage>
      </p>
      <h3 className="headline">
        <FormattedMessage id="CONTEST_WIKI_INFO_HEADLINE_2" />
      </h3>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_3" />
      </p>
      <p>
        {intl
          .formatMessage({ id: 'CONTEST_WIKI_INFO_TEXT_4' })
          .replace('{{winners}}', winners)}
      </p>
      <h3 className="headline">
        <FormattedMessage id="CONTEST_WIKI_INFO_HEADLINE_3" />
      </h3>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_5" />
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_6">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_7">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p className="bold">
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_8" />
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_9" />
      </p>
    </div>
  );
}

export default Contest;
