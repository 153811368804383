import { uuid } from '../../utils';
import { VOICEMESSAGE_NOT_SUPPORTED_ERROR } from '../actions';

export default ({ type }) => ({
  type: VOICEMESSAGE_NOT_SUPPORTED_ERROR,
  error: true,
  meta: {
    stamp: uuid(),
    type: type ?? 'default',
  },
});
