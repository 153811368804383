import './StyledLink.scss';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import linkSVG from '../../assets/img/svg/chevron-single-right.svg';
import Button from '../Button/Button';

function StyledLink({ translation = true, text, url }) {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <span className="styled-link" onClick={() => navigate(url)}>
      <Button icon={linkSVG} intlTranslate={false} variant="icon-only" />
      <span className="link">
        {translation ? intl.formatMessage({ id: text }) : text}
      </span>
    </span>
  );
}

export default StyledLink;
