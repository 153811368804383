import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

import addIcon from '../../../assets/img/svg/add.svg';
import closeIcon from '../../../assets/img/svg/close.svg';
import openCameraIcon from '../../../assets/img/svg/openCamera.svg';
import Button from '../../Button/Button';
import ImageItem from '../ImageItem/ImageItem';

function DiaryPhotoUploader({
  wrapperClassName,
  labelClassName,
  markAsRequired,
  isMobile,
  openCam,
  imageError,
  getRootProps,
  getInputProps,
  open,
  translatedLabel,
  activeGallery,
  deleteImage,
  diaryImageSource,
  removeDiaryImageSource,
}) {
  const intl = useIntl();
  return (
    <div {...getRootProps({ className: wrapperClassName })}>
      <span className={labelClassName}>
        {translatedLabel} {intl.formatMessage({ id: 'DIARY_FILE_OPTIONAL' })}
        {markAsRequired && <sup>*</sup>}
      </span>
      {imageError !== '' ? (
        <div className="text-error">{imageError}</div>
      ) : null}
      <div className="inner-content">
        <input {...getInputProps()} />

        <div className="buttons">
          <Button
            type="button"
            onClick={() => {
              open();
            }}
            icon={addIcon}
            tabIndex="1"
            id="chooser-picture-upload"
            classNamesOnly="chooser-btn"
            svgClassname="svg-wrapper"
            label="BUTTON_CHOOSE_FILE"
            addEmptySpan={true}
          />
          {!isMobile && (
            <Button
              type="button"
              onClick={openCam}
              icon={openCameraIcon}
              id="cam-picture-upload"
              tabIndex="2"
              classNamesOnly="chooser-btn cam"
              svgClassname="svg-wrapper"
              label="BUTTON_OPEN_CAMERA"
              addEmptySpan={true}
            />
          )}
        </div>
        {!diaryImageSource &&
          activeGallery &&
          (activeGallery?.image ? (
            <div className="uploaded-image">
              <Button
                type="button"
                variant="icon-only"
                icon={closeIcon}
                onClick={deleteImage}
                intlTranslate={false}
              />

              <ImageItem
                key={`online-image-item`}
                src={
                  process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
                  activeGallery?.image
                }
              />
            </div>
          ) : null)}
        {diaryImageSource ? (
          <div className="uploaded-image">
            <Button
              type="button"
              variant="icon-only"
              icon={closeIcon}
              onClick={() => removeDiaryImageSource()}
              intlTranslate={false}
            />

            <ImageItem key={`online-image-item`} src={diaryImageSource} />
          </div>
        ) : null}
      </div>

      <div className="drop-indicator">
        <FormattedMessage id="UPLOAD_DROP_INDICATOR" />
      </div>
    </div>
  );
}

export default DiaryPhotoUploader;
