import './FirstSteps.scss';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import messengerIcon from '../../../../../assets/img/svg/camMessenger.svg';
import contentIcon from '../../../../../assets/img/svg/content.svg';
import friendsIcon from '../../../../../assets/img/svg/friends.svg';
import icebreakerIcon from '../../../../../assets/img/svg/icebreaker.svg';
import legalIcon from '../../../../../assets/img/svg/legal.svg';
import statsIcon from '../../../../../assets/img/svg/stats.svg';
import supportIcon from '../../../../../assets/img/svg/support.svg';
import videoPosterImgDe from '../../../../../assets/img/videoPoster/firststeps_de.jpg';
import videoPosterImgEn from '../../../../../assets/img/videoPoster/firststeps_en.jpg';
import BackButton from '../../../../BackButton';
import VideoWithOverlay from '../../../../VideoWithOverlay';

function FirstSteps({
  locale,
  unconfirmed,
  profileImage,
  onBackButtonClicked,
}) {
  const intl = useIntl();
  const video =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/firststeps_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/firststeps_de.mp4`;
  const videoPosterImg = locale === 'en' ? videoPosterImgEn : videoPosterImgDe;

  let content;
  if (profileImage) {
    content = (
      <div
        style={{
          backgroundImage: `url(${profileImage})`,
        }}
        className="navbar-item-content"
      />
    );
  } else {
    content = <span>{intl.formatMessage({ id: 'MENU_ITEM_YOU' })}</span>;
  }

  return (
    <div className="wiki-content first-steps-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="FIRST_STEPS_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="FIRST_STEPS_CONTENT_1" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="WIKI_HEADLINE_VIDEO" />
      </h2>
      <VideoWithOverlay
        video={video}
        poster={videoPosterImg}
        showOverlay={false}
      />

      <h2 className="headline">
        <FormattedMessage id="FIRST_STEPS_HEADLINE_2" />
      </h2>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_1" />
          </span>
        </div>

        <div className="wiki-list-content">
          <div className="icon-box">
            <div className="icon-description">
              {content}
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_ACCOUNT_HEADLINE" />
                </span>
                <p>
                  <FormattedMessage
                    id="FIRST_STEPS_CONTENT_MENU_1"
                    values={{
                      link: (
                        <Link
                          key="first-steps-link-prfofile"
                          to="/wiki/general#profile"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_1_LINK" />
                        </Link>
                      ),
                      link1: (
                        <Link
                          key="first-steps-link-prfofile-settings"
                          to="/wiki/general#settings"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_1_LINK_1" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG
                src={messengerIcon}
                wrapper="span"
                className="icon messenger"
              />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_CAM_MESSENGER_HEADLINE" />
                </span>
                <p>
                  <FormattedMessage
                    id="FIRST_STEPS_CONTENT_MENU_2"
                    values={{
                      link: (
                        <Link
                          key="first-steps-link-messenger"
                          to="/wiki/general#multiMessenger"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_2_LINK" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG
                src={icebreakerIcon}
                wrapper="span"
                className="icon icebreaker"
              />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_MONEYMAKERS_HEADLINE" />
                </span>
                <p>
                  <FormattedMessage
                    id="FIRST_STEPS_CONTENT_MENU_3"
                    values={{
                      link: (
                        <Link
                          key="first-steps-link-attachements"
                          to="/wiki/moneymakers#attachments"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_3_LINK" />
                        </Link>
                      ),
                      link1: (
                        <Link
                          key="first-steps-link-icebreaker"
                          to="/wiki/moneymakers#icebreaker"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_3_LINK_1" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG
                src={friendsIcon}
                wrapper="span"
                className="icon friends"
              />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_RELATIONSHIPS_HEADLINE" />
                </span>
                <p>
                  <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_4" />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG
                src={contentIcon}
                wrapper="span"
                className="icon content"
              />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_CONTENT_HEADLINE" />
                </span>
                <p>
                  <FormattedMessage
                    id="FIRST_STEPS_CONTENT_MENU_5"
                    values={{
                      link: (
                        <Link
                          key="first-steps-link-galleries"
                          to="/wiki/general#galleries"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_5_LINK" />
                        </Link>
                      ),
                      link1: (
                        <Link
                          key="first-steps-link-diary"
                          to="/wiki/general#diary"
                        >
                          <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_5_LINK_1" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG src={statsIcon} wrapper="span" className="icon stats" />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_STATISTICS" />
                </span>
                <p>
                  <FormattedMessage
                    id="FIRST_STEPS_CONTENT_MENU_6"
                    values={{
                      link: (
                        <Link
                          key="first-steps-link-fourscore"
                          to="/wiki/moneymakers#fourScore"
                        >
                          <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_7_LINK" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG
                src={supportIcon}
                wrapper="span"
                className="icon support"
              />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_SUPPORT_TITLE" />
                </span>
                <p>
                  <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_7" />
                </p>
              </span>
            </div>
            <div className="icon-description">
              <ReactSVG src={legalIcon} wrapper="span" className="icon legal" />
              <span className="text">
                <span className="bold">
                  <FormattedMessage id="ROUTE_LEGAL_TITLE" />
                </span>
                <p>
                  <FormattedMessage id="FIRST_STEPS_CONTENT_MENU_8" />
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_2_CONTENT" />
          </p>
          <div className="wiki-sub-list">
            <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_2_1_CONTENT" />
            <ul>
              <li>
                <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_2_1_LIST" />
              </li>
              <li>
                <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_2_2_LIST" />
              </li>
              <li>
                <FormattedMessage
                  id="FIRST_STEPS_CONTENT_STEP_2_3_LIST"
                  values={{
                    link: (
                      <Link
                        key="first-steps-link-infocard"
                        to="/wiki/general#userInfocard"
                      >
                        <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_2_LINK" />
                      </Link>
                    ),
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="FIRST_STEPS_CONTENT_STEP_3_CONTENT"
              values={{
                link: unconfirmed ? (
                  <FormattedMessage id="ROUTE_CONTENT_HEADLINE" />
                ) : (
                  <Link
                    key="first-steps-link-my-photos"
                    to="/my-content/photos/my-photos"
                  >
                    <FormattedMessage id="ROUTE_CONTENT_HEADLINE" />
                  </Link>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="FIRST_STEPS_CONTENT_STEP_3_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FirstSteps;
