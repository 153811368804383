import useAxios from 'axios-hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { NEWSLETTER_PAGINATION_SIZE } from '../../../constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import api from '../../../utils/api';
import Scrollbar from '../../Scrollbar/Scrollbar';
import Section from '../../Section/Section';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import NewsletterContent from './NewsletterContent';
import NewsletterList from './NewsletterList';

function Newsletter({ nickname }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const hasDeeplink = useRef(null);

  const [newsletters, setNewsletters] = useState([]);
  const [data, setData] = useState();
  const [offset, setOffset] = useState(0);
  const [searchingNewsletter, setSearchingNewsletter] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [totalNewsletter, setTotalNewsletter] = useState(null);
  const [isDeeplinkProcessed, setIsDeeplinkProcessed] = useState(false);
  const [initialNewsletterLoading, setInitialNewsletterLoading] =
    useState(false);
  const [openNewsletterId, setOpenNewsletterId] = useState(null);

  const newsletterOpened = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [{ loading }, newsletterReq] = useAxios(
    {
      url: `/user/newsletter?l=${NEWSLETTER_PAGINATION_SIZE}&o=${offset}`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const [, totalNewsletterReq] = useAxios(
    {
      url: '/user/newsletter/total',
    },
    { manual: true }
  );

  const openNewsletter = useCallback(
    async (id) => {
      try {
        setInitialNewsletterLoading(true);
        const response = await api.get(`/user/newsletter/${id}`);

        if (response?.data?.html) {
          const htmlNickname = response.data.html.replaceAll(
            '{nickname}',
            nickname
          );
          const htmlTags = htmlNickname.replaceAll(
            'href="#',
            'href="about:srcdoc#'
          );
          setData({ ...response.data, html: htmlTags });
        } else {
          setData(response.data);
        }
        setInitialNewsletterLoading(false);
      } catch (error) {
        setData(null);
        setSearchingNewsletter(false);
        setInitialNewsletterLoading(false);
        navigate(location.pathname, { replace: true });
      } finally {
        newsletterOpened.current = true;
      }
    },
    [navigate, location.pathname, nickname]
  );

  // If we dont find the newsletter  of the targetId on the first page, we look at every other page until we find it.
  const searchPage = useCallback(
    async (targetId, currentOffset = 0) => {
      if (loaded) return;
      setSearchingNewsletter(true);
      try {
        const response = await api.get(
          `/user/newsletter?l=${NEWSLETTER_PAGINATION_SIZE}&o=${currentOffset}`
        );
        const newsletters = response.data;

        if (!newsletters.length) return;
        const targetNewsletter = newsletters.find(
          (newsletter) => newsletter.id === targetId
        );

        if (targetNewsletter) {
          setNewsletters(newsletters);
          setOffset(currentOffset);
          setPageNumber(currentOffset / NEWSLETTER_PAGINATION_SIZE + 1);
          setLoaded(true);
          setSearchingNewsletter(false);
          return;
        }

        const nextOffset = currentOffset + NEWSLETTER_PAGINATION_SIZE;
        if (nextOffset < totalNewsletter?.total) {
          await searchPage(targetId, nextOffset);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [totalNewsletter, loaded]
  );

  const getNewsletters = useCallback(async () => {
    try {
      const response = await newsletterReq();
      const filteredNewsletters = response.data.filter(
        (letter) => !letter.test
      );
      setNewsletters(response.data);

      if (
        filteredNewsletters.length &&
        isLargeScreen &&
        !newsletterOpened.current &&
        !hasDeeplink.current
      ) {
        setOpenNewsletterId(filteredNewsletters[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isLargeScreen, newsletterReq]);

  const processDeeplink = useCallback(async () => {
    const urlParams = new URLSearchParams(location.search);
    const paramId = urlParams.get('id');
    if (paramId) {
      const targetId = Number(paramId);
      if (isNaN(targetId)) {
        setIsDeeplinkProcessed(true);
        navigate(location.pathname, { replace: true });
        return;
      }
      hasDeeplink.current = true;
      const allIds = newsletters.map((newsletter) => newsletter.id);
      setOpenNewsletterId(targetId);
      if (!allIds.includes(targetId)) {
        await searchPage(targetId);
      }
      navigate(location.pathname, { replace: true });
    }
    setIsDeeplinkProcessed(true);
  }, [location.search, newsletters, searchPage, navigate, location.pathname]);

  useEffect(() => {
    let isMounted = true;
    if (!totalNewsletter) {
      totalNewsletterReq()
        .then((response) => {
          isMounted && setTotalNewsletter(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (totalNewsletter && !isDeeplinkProcessed) {
      processDeeplink();
    }
    return () => (isMounted = false);
  }, [
    processDeeplink,
    isDeeplinkProcessed,
    totalNewsletterReq,
    totalNewsletter,
  ]);

  useEffect(() => {
    if (isDeeplinkProcessed && totalNewsletter) {
      getNewsletters();
    }
  }, [getNewsletters, isDeeplinkProcessed, totalNewsletter]);

  useEffect(() => {
    if (!hasDeeplink.current) {
    }
  }, []);

  useEffect(() => {
    if (openNewsletterId) {
      openNewsletter(openNewsletterId);
    }
  }, [openNewsletter, openNewsletterId]);

  if ((initialNewsletterLoading || searchingNewsletter) && !isLargeScreen) {
    return <SpinnerComponent />;
  }

  return (
    <div className="page-wrapper">
      <Section
        title={intl.formatMessage({ id: 'HEADER_PAGE_NEWSLETTER' })}
        className="component-section"
      >
        <>
          {isLargeScreen ? (
            <div className="component-wrapper-no-header-menu">
              <Scrollbar visible={!isLargeScreen}>
                <div className="support-container newsletter-container">
                  <Scrollbar visible={isLargeScreen}>
                    <NewsletterList
                      newsletters={newsletters}
                      loading={
                        loading || searchingNewsletter || !totalNewsletter
                      }
                      pageNumber={pageNumber}
                      offset={offset}
                      setPageNumber={setPageNumber}
                      setOffset={setOffset}
                      setOpenNewsletterId={setOpenNewsletterId}
                      data={data}
                      totalNewsletter={totalNewsletter}
                    />
                  </Scrollbar>
                  <Scrollbar visible={isLargeScreen} hasBoxShadow={true}>
                    {initialNewsletterLoading ? (
                      <SpinnerComponent />
                    ) : (
                      <NewsletterContent
                        isLargeScreen={isLargeScreen}
                        data={data}
                        setData={setData}
                      />
                    )}
                  </Scrollbar>
                </div>
              </Scrollbar>
            </div>
          ) : (
            <div className="component-wrapper-no-header-menu">
              <Scrollbar visible={!isLargeScreen}>
                <div className="support-container newsletter-container">
                  {!data ? (
                    <NewsletterList
                      newsletters={newsletters}
                      loading={
                        loading || searchingNewsletter || !totalNewsletter
                      }
                      pageNumber={pageNumber}
                      offset={offset}
                      setPageNumber={setPageNumber}
                      setOffset={setOffset}
                      setOpenNewsletterId={setOpenNewsletterId}
                      data={data}
                      totalNewsletter={totalNewsletter}
                    />
                  ) : (
                    <>
                      <NewsletterContent
                        isLargeScreen={isLargeScreen}
                        data={data}
                        setData={setData}
                        setOpenNewsletterId={setOpenNewsletterId}
                      />
                    </>
                  )}
                </div>
              </Scrollbar>
            </div>
          )}
        </>
      </Section>
    </div>
  );
}

export default Newsletter;
