import './Settings.scss';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Link, Navigate, Route, Routes } from 'react-router-dom';

import videoPosterImgLovenseDesktopDe from '../../../assets/img/videoPoster/lovense_desktop_de.jpg';
import videoPosterImgLovenseDesktopEn from '../../../assets/img/videoPoster/lovense_desktop_en.jpg';
import videoPosterImgLovenseMobileDe from '../../../assets/img/videoPoster/lovense_mobile_de.jpg';
import videoPosterImgLovenseMobileEn from '../../../assets/img/videoPoster/lovense_mobile_en.jpg';
import videoPosterImgDe from '../../../assets/img/videoPoster/webcam_settings_de.jpg';
import videoPosterImgEn from '../../../assets/img/videoPoster/webcam_settings_en.jpg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { isLivecamCreator } from '../../../utils';
import Scrollbar from '../../Scrollbar';
import VideoWithOverlay from '../../VideoWithOverlay';

function Settings({
  items,
  isMobile,
  user,
  toycontrolActive,
  toycontrolEnabled,
  language,
}) {
  const location = useLocation();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isLivecam = isLivecamCreator(user);
  const intl = useIntl();
  const video =
    language === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/webcam_settings_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/webcam_settings_de.mp4`;
  const videoPosterImg =
    language === 'en' ? videoPosterImgEn : videoPosterImgDe;

  const videoMobile =
    language === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/lovense_mobile_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/lovense_mobile_de.mp4`;
  const videoPosterImgMobile =
    language === 'en'
      ? videoPosterImgLovenseMobileEn
      : videoPosterImgLovenseMobileDe;

  const videoDesktop =
    language === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/lovense_desktop_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/lovense_desktop_de.mp4`;
  const videoPosterImgDesktop =
    language === 'en'
      ? videoPosterImgLovenseDesktopEn
      : videoPosterImgLovenseDesktopDe;

  const routes = items
    .map((route, i) => {
      if (!isLivecam && route.path === '/livecam') {
        return null;
      }

      if (!toycontrolEnabled && route.path === '/toycontrol') {
        return null;
      }

      const Component = route.component;

      return (
        <Route
          key={`/my-account/settings${route.path}`}
          path={route.path + '/*'}
          element={<Component />}
        />
      );
    })
    .filter((r) => r !== null);

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-account-wrapper settings-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Routes>
              {routes}
              <Route path={'*'} element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            {location.pathname === '/my-account/settings/options' && (
              <>
                <h2 className="headline">
                  <FormattedMessage id="SETTINGS_OPTIONS_HEADLINE" />
                </h2>
                <p>
                  <FormattedMessage id="SETTINGS_OPTIONS_1">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p>
                  <FormattedMessage id="SETTINGS_OPTIONS_2">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p>
                  <FormattedMessage id="SETTINGS_OPTIONS_3">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <h3 className="headline">
                  <FormattedMessage id="SETTINGS_OPTIONS_HEADLINE_DEFAULT" />
                </h3>
                <p>
                  <FormattedMessage id="SETTINGS_OPTIONS_1_DEFAULT">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p>
                  <FormattedMessage id="SETTINGS_OPTIONS_2_DEFAULT">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                  <Link to="/support">
                    <FormattedMessage id="LINK_SUPPORT" />
                  </Link>
                </p>
                <p>
                  <FormattedMessage id="SETTINGS_OPTIONS_3_DEFAULT">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
              </>
            )}
            {location.pathname === '/my-account/settings/prices' && (
              <>
                <h2 className="headline">
                  <FormattedMessage id="SETTINGS_PRICES_HEADLINE" />
                </h2>
                {
                  <>
                    <p>
                      <FormattedMessage id="SETTINGS_PRICES_1">
                        {(message) => (
                          <span
                            dangerouslySetInnerHTML={{ __html: message[0] }}
                          />
                        )}
                      </FormattedMessage>
                    </p>
                    <p>
                      <FormattedMessage id="SETTINGS_PRICES_2">
                        {(message) => (
                          <span
                            dangerouslySetInnerHTML={{ __html: message[0] }}
                          />
                        )}
                      </FormattedMessage>
                    </p>
                    <p>
                      <FormattedMessage id="SETTINGS_PRICES_3">
                        {(message) => (
                          <span
                            dangerouslySetInnerHTML={{ __html: message[0] }}
                          />
                        )}
                      </FormattedMessage>
                      <Link to="/support">
                        <FormattedMessage id="LINK_SUPPORT" />
                      </Link>
                    </p>
                    <p>
                      <FormattedMessage id="SETTINGS_PRICES_4">
                        {(message) => (
                          <span
                            dangerouslySetInnerHTML={{ __html: message[0] }}
                          />
                        )}
                      </FormattedMessage>
                    </p>
                    <p>
                      <FormattedMessage id="SETTINGS_PRICES_5">
                        {(message) => (
                          <span
                            dangerouslySetInnerHTML={{ __html: message[0] }}
                          />
                        )}
                      </FormattedMessage>
                    </p>
                  </>
                }
                <p>
                  <FormattedMessage id="SETTINGS_PRICES_6">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <h3 className="headline">
                  <FormattedMessage id="SETTINGS_PRICES_HEADLINE_2" />
                </h3>
                <p>
                  <FormattedMessage id="SETTINGS_PRICES_TEXT" />
                </p>
              </>
            )}
            {location.pathname === '/my-account/settings/livecam' && (
              <>
                <h2 className="headline">
                  <FormattedMessage id="info.setting.video.title" />
                </h2>
                <VideoWithOverlay
                  video={video}
                  poster={videoPosterImg}
                  wikiLink="/wiki/general#livecamSetting"
                />
                <h3 className="headline">
                  <FormattedMessage id="info.setting.title" />
                </h3>
                <p>
                  <FormattedMessage id="info.text.streamSettings">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p>
                  <FormattedMessage id="info.text_2.streamSettings" />
                </p>
                <p>
                  <FormattedMessage id="info.text_3.streamSettings" />
                </p>
                <p>
                  <FormattedMessage id="info.text_4.streamSettings" />
                </p>
                <p>
                  <FormattedMessage id="info.text_5.streamSettings" />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id="info.text_6.streamSettings" />
                  </li>
                  <li>
                    <FormattedMessage id="info.text_7.streamSettings" />
                  </li>
                </ul>
                <p className="bold">
                  <FormattedMessage id="info.text_8.streamSettings" />
                </p>
                <p>
                  <FormattedMessage id="info.text_9.streamSettings" />
                </p>
                <p>
                  <FormattedMessage id="info.text.sounds">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>

                {!isMobile && (
                  <>
                    <p>
                      <FormattedMessage id="info.text.fKeys">
                        {(message) => (
                          <span
                            dangerouslySetInnerHTML={{ __html: message[0] }}
                          />
                        )}
                      </FormattedMessage>
                    </p>
                  </>
                )}

                <table className="noBorder noHover">
                  <tbody>
                    <tr className="thead">
                      <td className="bold">
                        <FormattedMessage id="info.placeholder" />
                      </td>
                      <td className="bold">
                        <FormattedMessage id="info.replace" />
                      </td>
                    </tr>
                    <tr>
                      <td>%t%</td>
                      <td>
                        <FormattedMessage id="info.currentTime" />
                      </td>
                    </tr>
                    <tr>
                      <td>%u%</td>
                      <td>
                        <FormattedMessage id="info.customerName" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {location.pathname === '/my-account/settings/toycontrol' &&
              (toycontrolActive && toycontrolEnabled ? (
                <>
                  <h2 className="headline">
                    <FormattedMessage id="TOYCONTROL_VIDEO_HEADLINE_1">
                      {(message) => (
                        <span
                          dangerouslySetInnerHTML={{ __html: message[0] }}
                        />
                      )}
                    </FormattedMessage>
                  </h2>
                  <VideoWithOverlay
                    video={videoMobile}
                    poster={videoPosterImgMobile}
                    wikiLink="/wiki/general#lovenseInstallation"
                  />
                  <h3 className="headline">
                    <FormattedMessage id="TOYCONTROL_VIDEO_HEADLINE_2" />
                  </h3>
                  <VideoWithOverlay
                    video={videoDesktop}
                    poster={videoPosterImgDesktop}
                    wikiLink="/wiki/general#lovenseInstallation"
                  />
                  <h3 className="headline">
                    <FormattedMessage id="TOYCONTROL_PRICE_LIST_HEADLINE" />
                  </h3>
                  <FormattedMessage id="TOYCONTROL_PRICE_LIST">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </>
              ) : (
                <>
                  <h2 className="headline">
                    <FormattedMessage id="toycontrol_marketing_headline" />
                  </h2>
                  <p>
                    <FormattedMessage id="toycontrol_marketing_text1">
                      {(message) => (
                        <span
                          dangerouslySetInnerHTML={{ __html: message[0] }}
                        />
                      )}
                    </FormattedMessage>
                  </p>
                  <p>
                    <FormattedMessage id="toycontrol_marketing_text2">
                      {(message) => (
                        <span
                          dangerouslySetInnerHTML={{ __html: message[0] }}
                        />
                      )}
                    </FormattedMessage>
                  </p>
                  <p>
                    <FormattedMessage id="toycontrol_marketing_text3">
                      {(message) => (
                        <span
                          dangerouslySetInnerHTML={{ __html: message[0] }}
                        />
                      )}
                    </FormattedMessage>
                  </p>
                  <p>
                    <FormattedMessage id="toycontrol_marketing_text4">
                      {(message) => (
                        <span
                          dangerouslySetInnerHTML={{ __html: message[0] }}
                        />
                      )}
                    </FormattedMessage>
                  </p>
                  <p className="bold noMargin">
                    <FormattedMessage id="TOYCONTROL_PRICE_LIST_HEADLINE" />
                  </p>
                  <p>
                    <FormattedMessage id="TOYCONTROL_PRICE_LIST">
                      {(message) => (
                        <span
                          dangerouslySetInnerHTML={{ __html: message[0] }}
                        />
                      )}
                    </FormattedMessage>
                  </p>
                  <p>
                    <FormattedMessage id="toycontrol_marketing_text5" />
                  </p>
                  <h3 className="headline red">
                    {intl.formatMessage({
                      id: 'TOYCONTROL_NOT_ACTIVE',
                    })}
                  </h3>
                </>
              ))}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}
export default Settings;
