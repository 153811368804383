import { connect } from 'react-redux';

import Newsletter from './Newsletter';

const mapStateToProps = (state) => {
  return {
    nickname: state.user.account?.nickname,
  };
};

export default connect(mapStateToProps)(Newsletter);
