import { ReactSVG } from 'react-svg';

import camMessengerIcon from '../../assets/img/svg/camMessenger.svg';
import notActiveIcebreakerIcon from '../../assets/img/svg/cancel.svg';
import activeIcebreakerIcon from '../../assets/img/svg/check.svg';
import contentIcon from '../../assets/img/svg/content.svg';
import relationshipsIcon from '../../assets/img/svg/friends.svg';
import gameIcon from '../../assets/img/svg/games.svg';
import icebreakerIcon from '../../assets/img/svg/icebreaker.svg';
import legalIcon from '../../assets/img/svg/legal.svg';
import signupIcon from '../../assets/img/svg/signup.svg';
import statsIcon from '../../assets/img/svg/stats.svg';
import supportIcon from '../../assets/img/svg/support.svg';
import { user as userUtils } from '../../utils';
import { initializeGtm } from '../../utils/gtmUtils';

const getLegalMenuItems = (intl, onLogoutBtnClick) => ({
  icon: legalIcon,
  path: '/imprint',
  itemClass: 'navbar-item-legal',
  title: intl.formatMessage({ id: 'MENU_ITEM_LEGAL' }),
  children: [
    {
      title: intl.formatMessage({ id: 'MENU_ITEM_IMPRINT' }),
      path: '/imprint',
    },
    {
      title: intl.formatMessage({ id: 'MENU_ITEM_TERMS' }),
      path: '/terms-and-conditions',
    },
    {
      title: intl.formatMessage({ id: 'MENU_ITEM_PRIVACY_POLICY' }),
      path: '/privacy-policy',
    },
    {
      title: intl.formatMessage({ id: 'MENU_ITEM_COOKIES' }),
      path: '#',
      onClick: () => openCookieBar(),
    },
    ...(typeof onLogoutBtnClick === 'function'
      ? getLogout(intl, onLogoutBtnClick)
      : []),
  ],
});
const getSupportItem = (intl, onLogoutBtnClick, user) => {
  const children = [
    {
      title: intl.formatMessage({ id: 'MENU_ITEM_SUPPORT_CONTACT' }),
      path: '/support',
    },
  ];
  if (
    user?.account?.state === 'confirmed' ||
    user?.account?.state === 'unconfirmed'
  ) {
    children.unshift({
      title: intl.formatMessage({ id: 'MENU_ITEM_NEWSLETTER' }),
      path: '/newsletter',
    });
    children.unshift({
      title: intl.formatMessage({ id: 'MENU_ITEM_WIKI' }),
      path: '/wiki',
    });
  }
  children.push(
    ...(typeof onLogoutBtnClick === 'function'
      ? getLogout(intl, onLogoutBtnClick)
      : [])
  );
  return {
    title: intl.formatMessage({ id: 'MENU_ITEM_SUPPORT' }),
    icon: supportIcon,
    itemClass: 'navbar-item-support',
    path: user?.account?.state === 'confirmed' ? '/wiki' : '/support',
    children,
  };
};

function openCookieBar() {
  if (!window?.google_tag_manager) {
    initializeGtm();
  }

  let attempts = 0;
  const maxAttempts = 20;
  const interval = 100;

  const checkServices = setInterval(() => {
    attempts++;

    if (window?.google_tag_manager && window.CookieScript?.instance) {
      window.CookieScript.instance.show();
      clearInterval(checkServices);
      return;
    }

    if (attempts >= maxAttempts) {
      clearInterval(checkServices);
    }
  }, interval);
}

const getLogout = (intl, onLogoutBtnClick) => [
  {
    separator: true,
  },
  {
    title: intl.formatMessage({ id: 'MENU_ITEM_LOGOUT' }),
    path: '#',
    onClick: onLogoutBtnClick,
    itemClass: 'navbar-logout-btn',
  },
];

export function getUnauthMenuItems(intl, pathname) {
  const unauthMenuItems = [
    {
      icon: signupIcon,
      itemClass: 'navbar-item-signup',
      path: '/',
      children: [
        {
          title: intl.formatMessage({ id: 'MENU_HOME' }),
          path: '/',
        },
        {
          title: intl.formatMessage({ id: 'MENU_SIGNUP' }),
          path: '/signup',
        },
        {
          title: intl.formatMessage({ id: 'MENU_LOGIN' }),
          path: '/login',
        },
      ],
    },
    getSupportItem(intl),
    getLegalMenuItems(intl),
  ];

  if (pathname.includes('/forgot-password')) {
    const forgotPasswordItem = {
      title: intl.formatMessage({ id: 'MENU_FORGOT_PASSWORD' }),
      path: '/forgot-password',
    };
    unauthMenuItems[0].children =
      unauthMenuItems[0].children.concat(forgotPasswordItem);
  }

  if (pathname.includes('/reset-password')) {
    const currentURL = window.location.search;
    const forgotPasswordItem = {
      title: intl.formatMessage({ id: 'MENU_RESET_PASSWORD' }),
      path: `/reset-password${currentURL}`,
    };
    unauthMenuItems[0].children =
      unauthMenuItems[0].children.concat(forgotPasswordItem);
  }

  if (pathname.includes('/email-verification')) {
    const currentURL = window.location.search;
    const verifyEmailItem = {
      title: intl.formatMessage({ id: 'MENU_VERIFY_EMAIL' }),
      path: `/email-verification${currentURL}`,
    };
    unauthMenuItems[0].children =
      unauthMenuItems[0].children.concat(verifyEmailItem);
  }

  if (pathname.includes('/unsubscribe-newsletter')) {
    const currentURL = window.location.search;
    const unsubscribeNewsletter = {
      title: intl.formatMessage({ id: 'MENU_UNSUBSCRIBE_NEWSLETTER' }),
      path: `/unsubscribe-newsletter${currentURL}`,
    };
    unauthMenuItems[0].children = unauthMenuItems[0].children.concat(
      unsubscribeNewsletter
    );
  }
  return unauthMenuItems;
}

export function getOnboardingMenuItems(intl, onLogoutBtnClick, pathname) {
  const unauthMenuItems = [
    {
      text: intl.formatMessage({ id: 'MENU_ITEM_YOU' }),
      path: '/onboarding/*',
      default404ActiveItem: true,
      children: [
        {
          title: intl.formatMessage({ id: 'MENU_ITEM_FIRST_STEPS' }),
          path: '/onboarding/*',
        },
        ...getLogout(intl, onLogoutBtnClick),
      ],
    },
    getSupportItem(intl, onLogoutBtnClick),
    getLegalMenuItems(intl, onLogoutBtnClick),
  ];
  if (pathname.includes('/unsubscribe-newsletter')) {
    const currentURL = window.location.search;
    const unsubscribeNewsletter = {
      title: intl.formatMessage({ id: 'MENU_UNSUBSCRIBE_NEWSLETTER' }),
      path: `/unsubscribe-newsletter${currentURL}`,
    };
    unauthMenuItems[0].children = unauthMenuItems[0].children.concat(
      unsubscribeNewsletter
    );
  }
  return unauthMenuItems;
}

export function getDoiMenuItems(intl, onLogoutBtnClick) {
  return [
    {
      text: intl.formatMessage({ id: 'MENU_ITEM_YOU' }),
      path: '/doi',
      default404ActiveItem: true,
      children: [
        {
          title: intl.formatMessage({ id: 'MENU_VERIFY_EMAIL' }),
          path: '/doi',
        },
        ...getLogout(intl, onLogoutBtnClick),
      ],
    },
    getSupportItem(intl, onLogoutBtnClick),
    getLegalMenuItems(intl, onLogoutBtnClick),
  ];
}

export function getReviewMenuItems(intl, onLogoutBtnClick) {
  return [
    {
      text: intl.formatMessage({ id: 'MENU_ITEM_YOU' }),
      path: '/review',
      default404ActiveItem: true,
      children: [
        {
          title: intl.formatMessage({ id: 'MENU_ITEM_FIRST_STEPS' }),
          path: '/review',
        },
        ...getLogout(intl, onLogoutBtnClick),
      ],
    },
    getSupportItem(intl, onLogoutBtnClick),
    getLegalMenuItems(intl, onLogoutBtnClick),
  ];
}

export function getConfirmedMenuItems(
  intl,
  onLogoutBtnClick,
  user,
  friendRequests,
  newMessages,
  gameTurn
) {
  const getLivecamItems = (logout, user) => {
    if (user.account.state !== 'confirmed') {
      return [];
    }
    return [
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_CAM_MESSENGER' }),
        path: '/multi-messenger',
        icon: camMessengerIcon,
        itemClass: 'navbar-item-cam-messenger',

        children: [
          {
            livecamButton: true,
          },
          {
            currentSales: true,
          },
          ...logout,
        ],
        additionalContentClass: newMessages && gameTurn && 'blink',
        additionalContent:
          newMessages || gameTurn ? (
            <>
              {newMessages && (
                <span className="new-messages-count">{newMessages}</span>
              )}
              {gameTurn && (
                <ReactSVG
                  src={gameIcon}
                  wrapper="span"
                  className="icon game-turn-icon"
                />
              )}
            </>
          ) : null,
      },
    ];
  };
  return [
    getMyAccountMenuItems(intl, user, onLogoutBtnClick),
    ...getLivecamItems(getLogout(intl, onLogoutBtnClick), user),
    ...getMoneyMakerItems(intl, getLogout(intl, onLogoutBtnClick), user),
    getRelationshipsMenuItems(intl, onLogoutBtnClick, friendRequests),
    getMyContentMenuItems(intl, user, onLogoutBtnClick),
    getStatisticsMenuItems(intl, onLogoutBtnClick),
    getSupportItem(intl, onLogoutBtnClick, user),
    getLegalMenuItems(intl, onLogoutBtnClick),
  ];
}
const getMoneyMakerItems = (intl, logout, user) => {
  const isIcebreakerAllowed = userUtils.isIcebreakerAllowed(user);
  const children = [
    {
      title: intl.formatMessage({ id: 'MENU_ITEM_MONEYMAKERS_ATTACHMENT' }),
      path: '/moneymakers/attachments',
    },
  ];
  if (isIcebreakerAllowed) {
    children.push({
      title: intl.formatMessage({ id: 'MENU_ITEM_MONEYMAKERS_ICEBREAKER' }),
      path: '/moneymakers/icebreaker',
    });
  }
  children.push(...logout);
  const title = isIcebreakerAllowed
    ? intl.formatMessage({ id: 'MENU_ITEM_MONEYMAKERS_ICEBREAKER' })
    : intl.formatMessage({ id: 'MENU_ITEM_MONEYMAKERS' });
  return [
    {
      path: '/moneymakers/attachments',
      title,
      icon: icebreakerIcon,
      itemClass: 'navbar-item-moneymakers',
      additionalContentClass:
        isIcebreakerAllowed &&
        userUtils.isIcebreakerEnabled(user) &&
        'active-icebreaker',
      additionalContent:
        isIcebreakerAllowed && userUtils.isIcebreakerEnabled(user) ? (
          <ReactSVG
            src={activeIcebreakerIcon}
            wrapper="span"
            className="icon"
          />
        ) : (
          <ReactSVG
            src={notActiveIcebreakerIcon}
            wrapper="span"
            className="icon"
          />
        ),
      children,
    },
  ];
};

const getMyAccountMenuItems = (intl, user, onLogoutBtnClick) => {
  const showCommission =
    user?.account?.slave &&
    !user?.account?.slave?.revenueVisible &&
    !user?.account?.slave?.camstatsVisible
      ? false
      : true;

  const showVirtualGifts = user?.account?.state === 'confirmed';

  const commission = {
    title: intl.formatMessage({ id: 'MENU_ITEM_COMMISSION' }),
    path:
      user?.account?.slave && user?.account?.slave?.camstatsVisible
        ? '/my-account/commission/livecam-overview'
        : '/my-account/commission/billing-period',
    defaultPath: '/my-account/commission',
  };

  const virtualGifts = {
    title: intl.formatMessage({ id: 'ROUTE_VIRTUAL_GIFTS' }),
    path: '/my-account/virtual-gifts/my-virtual-gifts',
    defaultPath: '/my-account/virtual-gifts',
  };

  const items = {
    text: intl.formatMessage({ id: 'MENU_ITEM_YOU' }),
    path: '/my-account/dashboard',
    image: getConfirmedProfileImage(user),
    default404ActiveItem: true,
    children: [
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_DASHBOARD' }),
        path: '/my-account/dashboard',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_PERSONAL_DETAILS' }),
        path: '/my-account/my-personal-details/account-details',
        defaultPath: '/my-account/my-personal-details',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_DOCUMENTS' }),
        path: '/my-account/documents',
      },
      {
        separator: true,
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_MY_PROFILE' }),
        path: '/my-account/my-profile/profile-pictures',
        defaultPath: '/my-account/my-profile',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_SETTINGS' }),
        path: '/my-account/settings/options',
        defaultPath: '/my-account/settings',
      },
      ...getLogout(intl, onLogoutBtnClick),
    ],
  };

  if (showCommission) {
    items.children.splice(3, 0, commission);
  }
  if (showVirtualGifts) {
    items.children.splice(6, 0, virtualGifts);
  }
  return items;
};

export function getActiveItem(items, pathname) {
  if (!items || items.length === 0) {
    return null;
  }

  let activeItem;
  let pageNotFound = false;

  const itemsWithPath = items.filter((i) => !!i.path);
  activeItem = itemsWithPath.find(
    (i) =>
      i.path === pathname ||
      i.children?.findIndex((c) => c.path === pathname) > -1 ||
      (i.path.includes('?') && i.path.split('?')[0] === pathname)
  );
  if (!activeItem) {
    activeItem = itemsWithPath.find((i) => {
      return (
        (typeof i.path === 'string' &&
          (pathname.includes(i.path) || pathname.includes(i.defaultPath))) ||
        i.children?.findIndex(
          (c) =>
            typeof c.path === 'string' &&
            (pathname.includes(c.path) || pathname.includes(c.defaultPath))
        ) > -1
      );
    });
    if (!activeItem) {
      activeItem = itemsWithPath.find((i) => i.default404ActiveItem);
      pageNotFound = true;
    }
  }
  return { activeItem, pageNotFound };
}

export function getConfirmedProfileImage(user) {
  if (user?.account?.image?.file) {
    return (
      process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + user?.account?.image?.file
    );
  }
  return null;
}

const getMyContentMenuItems = (intl, user, onLogoutBtnClick) => {
  const items = {
    title: intl.formatMessage({ id: 'ROUTE_CONTENT_TITLE' }),
    path: '/my-content/photos/my-photos',
    // Icon to be updated
    icon: contentIcon,
    itemClass: 'navbar-item-my-content',
    children: [
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_PHOTOS' }),
        path: '/my-content/photos/my-photos',
        defaultPath: '/my-content/photos',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_VIDEOS' }),
        path: '/my-content/videos/my-videos',
        defaultPath: '/my-content/videos',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_FMOTD' }),
        path: '/my-content/free-movie-of-the-day/my-movies',
        defaultPath: '/my-content/free-movie-of-the-day',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_COMMENTS' }),
        path: '/my-content/comments/my-comments',
        defaultPath: '/my-content/comments',
      },
    ],
  };
  // diary depending on user state
  if (user?.account?.intimate_diary === 'enabled') {
    items.children.push({
      title: intl.formatMessage({ id: 'MENU_ITEM_DIARY' }),
      path: '/my-content/diary/my-diary',
      defaultPath: '/my-content/diary',
    });
  }

  // need to do this workaround to keep logout stuff at the end
  items.children = [...items.children, ...getLogout(intl, onLogoutBtnClick)];
  return items;
};

const getRelationshipsMenuItems = (intl, onLogoutBtnClick, friendRequests) => {
  const items = {
    title: intl.formatMessage({ id: 'ROUTE_RELATIONSHIPS_TITLE' }),
    path: `${
      friendRequests
        ? '/my-relationships/friends/friend-requests'
        : '/my-relationships/friends/active-friends'
    }`,
    icon: relationshipsIcon,
    additionalContent: friendRequests,
    itemClass: 'navbar-item-my-relationships',
    children: [
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_FRIENDS' }),
        path: '/my-relationships/friends/active-friends',
        defaultPath: '/my-relationships/friends',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_FAVORITES' }),
        path: '/my-relationships/favorites',
        defaultPath: '/my-relationhips/favorites',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_IGNORED' }),
        path: '/my-relationships/ignored',
        defaultPath: '/my-relationships/ignored',
      },
      ...getLogout(intl, onLogoutBtnClick),
    ],
  };

  return items;
};

const getStatisticsMenuItems = (intl, onLogoutBtnClick) => {
  const items = {
    title: intl.formatMessage({ id: 'ROUTE_MY_TOP_CUSTOMERS' }),
    path: '/statistics',
    icon: statsIcon,
    itemClass: 'navbar-item-stats',
    children: [
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_SALES' }),
        path: '/statistics/sales',
        defaultPath: 'statistics',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_CUSTOMERS' }),
        path: '/statistics/top-customers',
        defaultPath: 'statistics',
      },
      {
        title: intl.formatMessage({ id: 'MENU_ITEM_GAME4SCORE' }),
        path: '/statistics/game4score',
        defaultPath: 'statistics/game4score',
      },
      ...getLogout(intl, onLogoutBtnClick),
    ],
  };

  return items;
};

export function getUnconfirmedMenuItems(intl, onLogoutBtnClick, user) {
  return [
    getMyAccountMenuItems(intl, user, onLogoutBtnClick),
    getSupportItem(intl, onLogoutBtnClick, user),
    getLegalMenuItems(intl, onLogoutBtnClick),
  ];
}
