import './MyPersonalDetails.scss';

import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import img2FA from '../../../assets/img/2fa/2fa.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar';

function MyPersonalDetails({ initialValues = {}, items }) {
  const location = useLocation();
  const security =
    location.pathname === '/my-account/my-personal-details/account-security';
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const routes = items.map((route, i) => {
    const Component = route.component;
    return (
      <Route
        key={`/my-account/my-personal-details${route.path}`}
        path={route.path + '/*'}
        element={<Component />}
      />
    );
  });
  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-account-wrapper my-personal-details-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Formik initialValues={initialValues}>
              <Routes>
                {routes}
                <Route path={'*'} element={<Navigate to="/" replace />} />
              </Routes>
            </Formik>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <h1 className="headline">
              <FormattedMessage
                id={
                  security
                    ? 'TWO_FACTOR_INFO_HEADLINE'
                    : 'PERSONAL_DATA_INFO_HEADLINE'
                }
              />
            </h1>
            {security && <img className="img-2fa" src={img2FA} alt="2FA" />}
            <p>
              <FormattedMessage
                id={
                  security
                    ? 'TWO_FACTOR_INFO_CONTENT_1'
                    : 'PERSONAL_DATA_INFO_CONTENT_1'
                }
              />
            </p>
            <p>
              <FormattedMessage
                id={
                  security
                    ? 'TWO_FACTOR_INFO_CONTENT_2'
                    : 'PERSONAL_DATA_INFO_CONTENT_2'
                }
              />
            </p>
            <p>
              <FormattedMessage
                id={
                  security
                    ? 'TWO_FACTOR_INFO_CONTENT_3'
                    : 'PERSONAL_DATA_INFO_CONTENT_3'
                }
              />
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default MyPersonalDetails;
