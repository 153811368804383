import '../Newsletter.scss';

import Button from '../../../Button/Button';
import NewsletterIframe from '../../NewsletterIframe/NewsletterIframe';

function NewsletterContent({
  isLargeScreen,
  data,
  setData,
  setOpenNewsletterId,
}) {
  const handleBack = () => {
    setData(null);
    setOpenNewsletterId(null);
  };

  return (
    <div className="data-wrapper">
      <div className="iframe-wrapper">
        <NewsletterIframe data={data} />
        {!isLargeScreen && (
          <div className="button-wrapper">
            <Button label="BUTTON_BACK" onClick={() => handleBack()} />
          </div>
        )}
      </div>
    </div>
  );
}

export default NewsletterContent;
