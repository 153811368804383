import './VoiceRecorderControl.scss';
import '../Control.scss';

import classNames from 'classnames';
import fixWebmDuration from 'fix-webm-duration';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';
import WaveSurfer from 'wavesurfer.js';

import deleteIcon from '../../../../../assets/img/svg/delete.svg';
import mic from '../../../../../assets/img/svg/mic.svg';
import pauseIcon from '../../../../../assets/img/svg/pause.svg';
import playIcon from '../../../../../assets/img/svg/play.svg';
import { MAX_VOICE_RECORDING_TIME_SEC } from '../../../../../constants';
import api from '../../../../../utils/sonusapi';
import Button from '../../../../Button/Button';

function VoiceRecorderControl({
  recordingClicked,
  clickRecordingHandler,
  sendVoiceClickedState,
  sendVoiceClicked,
  sendChatMessage,
  setRecording,
  sonusError,
  clearRecordingState,
  playVoicemessage,
  stopVoicemessage,
  voicemessagePlaying,
  loadingVoicemessage,
  sendername,
  errorDisabledMicrophone,
  setRecordingstarted,
  microphoneTest = false,
  selectedMicrophone = null,
  setShowRecordButton,
  notSupportedErrorVoicemessage,
}) {
  const intl = useIntl();

  const [audioBlob, setAudioBlob] = useState(null);
  const [blobObject, setBlobObject] = useState(null);
  const [waveSurfer, setWaveSurfer] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStopped, setRecordingStopped] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const chunks = useRef([]);
  const wsPlay = useRef();
  const wsPause = useRef();
  const wsFinish = useRef();
  const messageAudio = useRef();
  const messageWave = useRef();
  const reactiveWaveRef = useRef();
  const intervalId = useRef();
  const mediaRecorder = useRef();

  const VID = -1;

  const drawCanvasWaveform = useCallback(
    (analyser, dataArray, canvasContext) => {
      const draw = () => {
        analyser.getByteTimeDomainData(dataArray);
        canvasContext.clearRect(
          0,
          0,
          canvasContext.canvas.width,
          canvasContext.canvas.height
        );

        canvasContext.strokeStyle = '#6b7075';
        canvasContext.lineWidth = 3;
        canvasContext.setLineDash([3, 2]);
        canvasContext.beginPath();

        const bufferLength = dataArray.length;
        const amplitudeThreshold = 0.02;
        const sliceWidth = canvasContext.canvas.width / bufferLength;

        let x = 0;
        for (let i = 0; i < dataArray.length; i++) {
          let v = dataArray[i] / 128.0 - 1.0;

          if (Math.abs(v) < amplitudeThreshold) {
            v = 0; //ignore small fluctuations like background noise.
          }

          const y = (1 - v) * (canvasContext.canvas.height / 2);

          if (i === 0) {
            canvasContext.moveTo(x, y);
          } else {
            canvasContext.lineTo(x, y);
          }
          x += sliceWidth;
        }
        canvasContext.lineTo(
          canvasContext.canvas.width,
          canvasContext.canvas.height / 2
        );
        canvasContext.stroke();

        requestAnimationFrame(draw);
      };
      draw();
    },
    []
  );

  useEffect(() => {
    const sendVoice = async () => {
      try {
        loadingVoicemessage(true);
        let file = new File([blobObject], `${sendername}_${Date.now()}.webm`, {
          type: 'audio/webm; codecs=opus',
        });

        const data = new FormData();
        data.append('file', file, file.name);

        const sonusApiResult = await api.post('/voicemessages', data);
        sendChatMessage('', false, null, sonusApiResult?.data);

        setIsUploading(false);
        setBlobObject(null);
        setAudioBlob(null);
        clickRecordingHandler(false);
      } catch (sonusApiError) {
        loadingVoicemessage(false);
        setIsUploading(false);
        const matches = sonusApiError.message.match(/(\d{3})/g);
        const errorNumber = matches?.length
          ? matches[0]
          : sonusApiError?.message || '';
        sonusError(errorNumber);
      } finally {
        clearRecordingState();
      }
    };

    if (sendVoiceClickedState && !isUploading && !microphoneTest) {
      setIsUploading(true);
      sendVoice();
    }

    if (!microphoneTest) {
      sendVoiceClicked(false);
    }
  }, [
    blobObject,
    clearRecordingState,
    clickRecordingHandler,
    isUploading,
    loadingVoicemessage,
    sendChatMessage,
    sendVoiceClicked,
    sendVoiceClickedState,
    sendername,
    sonusError,
    microphoneTest,
  ]);

  const startRecording = useCallback(
    async (onlyCanvas = false) => {
      try {
        // Connect to MediaRecorder
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: microphoneTest
            ? { deviceId: { exact: selectedMicrophone } }
            : true,
        });
        mediaRecorder.current = new MediaRecorder(stream);

        // Create audiocontext to draw realtime waveform
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();

        analyser.fftSize = 2048;
        source.connect(analyser);
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        // Create canvas to draw waveform
        const canvas = reactiveWaveRef.current;
        canvas.width = canvas.offsetWidth;
        const canvasContext = canvas.getContext('2d');
        drawCanvasWaveform(analyser, dataArray, canvasContext);

        if (onlyCanvas) {
          return;
        }

        // save date in chunks
        mediaRecorder.current.ondataavailable = (event) => {
          chunks.current.push(event.data);
        };

        mediaRecorder.current.onstop = () => {
          // fix the false metadata of the blob recorded with MediaRecorder
          const noDurationBlob = new Blob(chunks.current);
          const noDurationURL = URL.createObjectURL(noDurationBlob);

          fetch(noDurationURL)
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
            .then((decodedData) => {
              const duration = decodedData.duration * 1000;
              fixWebmDuration(noDurationBlob, duration, { logger: false })
                .then((blob) => {
                  // save the blob with duration.
                  const audioURL = URL.createObjectURL(blob);
                  setAudioBlob(audioURL);
                  setBlobObject(blob);
                  chunks.current = [];
                })
                .catch((error) => console.error(error));
            });
        };

        mediaRecorder.current.start();
        setIsRecording(true);
        setRecording(true);
      } catch (error) {
        clickRecordingHandler(false);
        setIsRecording(false);
        setRecording(false);
        setRecordingStopped(true);
        setRecordingstarted(false);
        clearInterval(intervalId.current);

        if (error.name === 'NotAllowedError') {
          errorDisabledMicrophone();
        } else if (error.name === 'NotSupportedError') {
          console.error('NotSupportedError:', error);
          notSupportedErrorVoicemessage();
        } else {
          console.error('Error in startRecording:', error);
          sonusError(2);
        }
      }
    },
    [
      clickRecordingHandler,
      drawCanvasWaveform,
      errorDisabledMicrophone,
      setRecording,
      setRecordingstarted,
      sonusError,
      microphoneTest,
      selectedMicrophone,
      notSupportedErrorVoicemessage,
    ]
  );

  useEffect(() => {
    if (microphoneTest) {
      if (audioBlob === null && !isRecording && !recordingStopped) {
        startRecording(true);
      } else if (!isRecording && audioBlob) {
        setShowRecordButton(false);
      } else {
        setShowRecordButton(true);
      }
    }
  }, [
    microphoneTest,
    audioBlob,
    isRecording,
    recordingStopped,
    startRecording,
    setShowRecordButton,
  ]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setRecording(false);
      setRecordingStopped(true);
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [setRecording]);

  const deleteRecording = () => {
    setSeconds(0);
    setAudioBlob(null);
    setBlobObject(null);
    clickRecordingHandler(false);
    clearRecordingState();
    setIsPlaying(false);
    stopVoicemessage(VID);

    if (microphoneTest) {
      startRecording(true);
    }
  };

  useEffect(() => {
    if (!isRecording || !intervalId.current) {
      if (isRecording && !intervalId.current) {
        intervalId.current = setInterval(() => {
          setSeconds((prevSeconds) => (prevSeconds + 1) % 60);
        }, 1000);
      } else {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
      return () => clearInterval(intervalId.current);
    }
  }, [isRecording, intervalId]);

  useEffect(() => {
    if (
      (recordingClicked &&
        !isRecording &&
        reactiveWaveRef &&
        !recordingStopped) ||
      (recordingClicked && !isRecording && microphoneTest)
    ) {
      if (microphoneTest) {
        setSeconds(0);
        setAudioBlob(null);
      }
      startRecording();
    } else if (!recordingClicked && isRecording) {
      stopRecording();
    }
  }, [
    recordingClicked,
    isRecording,
    startRecording,
    stopRecording,
    recordingStopped,
    microphoneTest,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (
      audioBlob &&
      messageAudio.current &&
      messageAudio.current.src !== waveSurfer?.media?.currentSrc
    ) {
      messageAudio.current.src = audioBlob;

      const ws = WaveSurfer.create({
        container: messageWave?.current,
        waveColor: '#95999d',
        progressColor: '#0084ff',
        media: messageAudio.current,
        barWidth: 4,
        barRadius: 4,
        height: 40,
        responsive: true,
        cursorWidth: 0,
      });

      wsPlay.current = () => {
        setIsPlaying(true);
      };

      ws.on('play', wsPlay.current);

      wsPause.current = () => {
        if (isMounted) {
          setIsPlaying(false);
          stopVoicemessage(VID);
        }
      };

      ws.on('pause', wsPause.current);

      wsFinish.current = () => {
        setIsPlaying(false);
        stopVoicemessage(VID);
      };

      ws.on('finish', wsFinish.current);

      setWaveSurfer(ws);
      if (messageWave?.current) {
        if (messageWave.current.childNodes.length === 3) {
          if (messageWave.current.childNodes.item(2))
            messageWave.current.childNodes.item(2).remove();
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [audioBlob, isPlaying, VID, stopVoicemessage, waveSurfer]);

  useEffect(() => {
    if (waveSurfer) {
      const isPlaying = waveSurfer.isPlaying();

      if (isPlaying && voicemessagePlaying !== VID) {
        setIsPlaying(false);
        waveSurfer.pause();
      }

      if (!isPlaying && voicemessagePlaying === VID) {
        setIsPlaying(true);
        waveSurfer.play().catch((err) => {
          console.error(err);
          setIsPlaying(false);
          if (err.name === 'NotSupportedError') {
            notSupportedErrorVoicemessage({ type: 'play' });
          }
        });
      }
    }
  }, [waveSurfer, voicemessagePlaying, notSupportedErrorVoicemessage, VID]);

  useEffect(() => {
    if (seconds >= MAX_VOICE_RECORDING_TIME_SEC) {
      stopRecording();
      clickRecordingHandler(false);
    }
  }, [seconds, stopRecording, clickRecordingHandler]);

  const onPlayClick = useCallback(() => {
    if (waveSurfer) {
      if (waveSurfer.isPlaying()) {
        stopVoicemessage(VID);
      } else {
        playVoicemessage(VID);
      }
    }
  }, [waveSurfer, playVoicemessage, stopVoicemessage, VID]);

  const playButtonClasses = classNames('btn-play', {
    active: isPlaying,
  });

  return (
    <>
      <div className="voice-message">
        {!isRecording && audioBlob && !microphoneTest ? (
          <div className="button-wrapper play">
            <Button
              className={playButtonClasses}
              title={intl.formatMessage({ id: 'AUDIO_BUTTON_PLAY' })}
              onClick={onPlayClick}
              intlTranslate={false}
              disabled={!audioBlob}
              icon={isPlaying ? pauseIcon : playIcon}
              variant="icon-only"
            />
          </div>
        ) : null}

        {isRecording ? (
          <ReactSVG src={mic} wrapper="span" className="recording" />
        ) : null}

        {(audioBlob || isRecording) && (
          <span className="duration">
            0:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        )}
        {!audioBlob && <canvas ref={reactiveWaveRef}></canvas>}

        {audioBlob && !isRecording && (
          <div ref={messageWave} className="wavesurfer">
            <audio ref={messageAudio} controls={false} />
          </div>
        )}
      </div>
      {!isRecording && audioBlob && microphoneTest ? (
        <div className="button-wrapper play">
          <Button
            className={playButtonClasses}
            title={intl.formatMessage({ id: 'AUDIO_BUTTON_PLAY' })}
            onClick={onPlayClick}
            intlTranslate={false}
            disabled={!audioBlob}
            icon={isPlaying ? pauseIcon : playIcon}
            variant="icon-only"
          />
        </div>
      ) : null}

      {!isRecording && audioBlob && (
        <div className="button-wrapper delete">
          <Button
            className="btn-delete"
            title={intl.formatMessage({ id: 'AUDIO_BUTTON_DELETE' })}
            onClick={deleteRecording}
            disabled={!audioBlob}
            id="deleteRecording"
            intlTranslate={false}
            icon={deleteIcon}
            variant="icon-only"
          />
        </div>
      )}
    </>
  );
}

export default VoiceRecorderControl;

