import './Doi.scss';

import useAxios from 'axios-hooks';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import mailIcon from '../../../assets/img/svg/activation.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Button/Button';
import Input from '../../Forms/Input/Input';
import InputWithLabel from '../../Forms/InputWithLabel/InputWithLabel';
import StandaloneFieldWithErrors from '../../Forms/StandaloneFieldWithErrors/StandaloneFieldWithErrors';
import PageSection from '../../PageSection/PageSection';

function Doi({ update = () => {}, user = {} }) {
  const [email, setEmail] = useState(user.account.email);
  const [emailError, setEmailError] = useState(null);
  const [changingEmail, setChangingEmail] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [verificationSuccess, setVerificationSucess] = useState(null);
  const [emailChangeSuccess, setEmailChangeSuccess] = useState(false);
  const intl = useIntl();
  const isMediumOrSmallScreen = useMediaQuery('(max-width: 1600px)');
  const [{ loading: isLoadingUpdateEmail }, resendEmailVerification] = useAxios(
    {
      url: '/user/account',
      method: 'PUT',
      data: { email },
    },
    {
      manual: true,
    }
  );

  const [, resendVerifyLink] = useAxios(
    {
      url: '/user/email/verify',
      method: 'PUT',
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        email: email,
      },
    },
    {
      manual: true,
    }
  );

  const handleEmailVerify = useCallback(() => {
    if (isLoadingUpdateEmail) {
      return;
    }
    resendEmailVerification({ data: { email } })
      .then((result) => {
        if (result?.data?.email === email) {
          console.log('email account update success');
          update({
            account: {
              ...user.account,
              email,
            },
          });
          setEmailChangeSuccess(true);
          setEmailError(null);
        } else {
          setEmailError('EMAIL_UPDATE_ERROR');
        }
      })
      .catch((error) => {
        console.log(
          `email account update error with message ${error?.message}`
        );
        setEmailError('EMAIL_UPDATE_ERROR');
        setEmailChangeSuccess(false);
      });
  }, [email, resendEmailVerification, isLoadingUpdateEmail, update, user]);

  function resendVerification() {
    resendVerifyLink()
      .then(() => {
        setVerificationError(null);
        setVerificationSucess(true);
      })
      .catch((error) => {
        if (error && error.response) {
          const tryAgainLaterError = error.response.data.errors.findIndex(
            (element) => {
              return element.includes('verify_email_wait');
            }
          );
          if (tryAgainLaterError !== -1) {
            setVerificationError(
              error.response.data.errors[tryAgainLaterError].split('|')
            );
          } else if (error.response.data.errors) {
            setVerificationError(error.response.data.errors);
          } else {
            setVerificationError('verify_email_error');
          }
        }
        setVerificationSucess(null);
      });
  }

  const rightColumnTitle = <span />;

  const rightColumn = (
    <div className="info-header">
      <h2 className="headline">
        {intl.formatMessage({ id: 'DOI_DESCRIPTION_TITLE' })}
      </h2>
      <p className="bold">
        {intl.formatMessage({ id: 'DOI_DESCRIPTION_CONTENT_1' })}
      </p>
      <ReactSVG src={mailIcon} className="activation" wrapper="span" />
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: 'DOI_DESCRIPTION_CONTENT_2' }),
          }}
        />
      </p>
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: 'DOI_DESCRIPTION_CONTENT_3' }),
          }}
        />
      </p>
    </div>
  );

  return (
    <PageSection
      key="test-key"
      title={intl.formatMessage({ id: 'DOI_HEADLINE' })}
      rightColumnTitle={rightColumnTitle}
      rightColumn={rightColumn}
      className="doi-container"
    >
      {isMediumOrSmallScreen ? (
        <div className="info-header">
          <h2 className="headline">
            {intl.formatMessage({ id: 'DOI_DESCRIPTION_TITLE' })}
          </h2>
          <p className="bold">
            {intl.formatMessage({ id: 'DOI_DESCRIPTION_CONTENT_1' })}
          </p>
          <ReactSVG src={mailIcon} className="activation" wrapper="span" />
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'DOI_DESCRIPTION_CONTENT_2' }),
              }}
            />
          </p>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'DOI_DESCRIPTION_CONTENT_3' }),
              }}
            />
          </p>
        </div>
      ) : null}
      <h1 className="headline">{intl.formatMessage({ id: 'DOI_TITLE' })}</h1>
      <div className="doi-form-wrapper">
        <>
          {changingEmail ? (
            <>
              <div className="doi-form">
                <StandaloneFieldWithErrors
                  type="email"
                  name="email"
                  label="NEW_EMAIL"
                  className="email-change-input"
                  error={
                    emailError &&
                    emailError !== 'EMAIL_DOI_ERROR' &&
                    intl.formatMessage({ id: emailError })
                  }
                >
                  <Input
                    name="email"
                    className="email-change-input"
                    maxLength="64"
                    value={email}
                    onChange={(e) => {
                      const newEmail = e.target.value;
                      setEmail(newEmail);
                      if (
                        newEmail !== user.account.email ||
                        !user.account.doi
                      ) {
                        setEmailError(emailError);
                      }
                    }}
                  />
                </StandaloneFieldWithErrors>
                <div className="form-group">
                  <span className="column" />
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleEmailVerify}
                    label="BUTTON_CHANGE"
                    className="btn-form"
                  />
                </div>
                {emailChangeSuccess ? (
                  <div className="form-group">
                    <span className="column" />
                    <div className="column2 success-wrapper">
                      {intl.formatMessage({ id: 'CHANGE_EMAIL_SUCCESS' })}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="doi-form">
                <div className="email-change">
                  <p>
                    {intl.formatMessage({
                      id: 'EMAIL_CHANGE_NOTIFICATION_1',
                    })}
                  </p>
                  <p>
                    {intl.formatMessage(
                      { id: 'EMAIL_CHANGE_NOTIFICATION_2' },
                      {
                        link: (
                          <Link
                            key="doi-link"
                            to="#"
                            onClick={() => window.location.reload()}
                          >
                            {intl.formatMessage({
                              id: 'EMAIL_RELOAD_HERE',
                            })}
                          </Link>
                        ),
                      }
                    )}
                  </p>
                </div>
              </div>
            </>
          ) : null}
          {/* There have to be 2 ternaries, because otherwise the browser translation throws an error on change email */}
          {!changingEmail ? (
            <>
              <div className="doi-form">
                <InputWithLabel
                  type="email"
                  name="email"
                  label="EMAIL_VERIFICATION_LABEL"
                  className="email-display-input"
                  disabled={true}
                  maxLength="64"
                  value={email}
                />
                <div className="form-group">
                  <span className="column error" />
                  <div className="error-wrapper">
                    <div className="error-title">
                      {' ' + intl.formatMessage({ id: 'EMAIL_NOT_CONFIRMED' })}
                    </div>
                    <hr />
                    <div className="error-content">
                      {intl.formatMessage({
                        id: 'EMAIL_VERIFY_INSTRUCTION',
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="doi-form">
                <div className="not-verified">
                  <p>
                    {intl.formatMessage({
                      id: 'EMAIL_RESEND_1',
                    })}
                  </p>
                  <p>
                    {intl.formatMessage(
                      {
                        id: 'EMAIL_RESEND_2',
                      },
                      {
                        link: (
                          <Link
                            key="doi-link"
                            to="#"
                            onClick={() => resendVerification()}
                          >
                            {intl.formatMessage({
                              id: 'CLICK_HERE',
                            })}
                          </Link>
                        ),
                      }
                    )}
                  </p>
                  {verificationError ? (
                    <div className="error-wrapper">
                      {intl.formatMessage(
                        {
                          id: verificationError[0],
                        },
                        verificationError[1] && {
                          minutes: verificationError[1],
                        }
                      )}
                    </div>
                  ) : null}
                  {verificationSuccess ? (
                    <div className="success-wrapper">
                      {intl.formatMessage({
                        id: 'SEND_EMAIL_SUCCESS',
                      })}
                    </div>
                  ) : null}
                  <p>
                    {intl.formatMessage(
                      {
                        id: 'EMAIL_CHANGE_REQUEST',
                      },
                      {
                        link: (
                          <Link
                            key="doi-link"
                            to="#"
                            onClick={() => setChangingEmail(true)}
                          >
                            {intl.formatMessage({
                              id: 'EMAIL_CHANGE',
                            })}
                          </Link>
                        ),
                      }
                    )}
                  </p>
                </div>
              </div>
            </>
          ) : null}
        </>
      </div>
    </PageSection>
  );
}

export default Doi;
