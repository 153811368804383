import './Signup.scss';

import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import api from '../../../utils/api';
import PageSection from '../../PageSection/PageSection';
import Form from './Form';
import SignUpText from './SignUpText';

function Signup({ refreshAuthToken, spinner }) {
  const navigate = useNavigate();
  const isLoginLinkDisplayed = useMediaQuery('(min-width: 1600px)');
  const isMidSizeScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );
  const intl = useIntl();

  const search = useLocation().search;
  const studio = new URLSearchParams(search).get('studio');
  const aid = new URLSearchParams(search).get('AID');
  const refresh_token = new URLSearchParams(search).get('refresh_token');

  useEffect(() => {
    let isMounted = true;

    const redirectToLogin = () => {
      navigate('/login');
    };

    // Signup route for Landing pages @see AP-1021
    if (refresh_token) {
      spinner(true);
      api
        .post(
          '/oauth/token',
          {
            refresh_token,
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
          },
          { skipAttachingAuthToken: true }
        )
        .then((res) => {
          if (isMounted) {
            const { status, data } = res;
            if (status === 200) {
              refreshAuthToken(data);
            } else {
              redirectToLogin();
            }
          }
        })
        .catch((err) => {
          isMounted && redirectToLogin();
        })
        .finally(() => {
          isMounted && spinner(false);
        });
    }
    return () => (isMounted = false);
  }, [refresh_token, refreshAuthToken, spinner, navigate]);

  const rightColumnTitle = (
    <span>
      <FormattedMessage id="SECTION_SIGNUP_LOGIN_HINT" />{' '}
      <Link to="/login">
        <FormattedMessage id="SECTION_SIGNUP_LOGIN" />
      </Link>
    </span>
  );

  const rightColumn = (
    <>
      <SignUpText id="signup-text-login" />
      <div className="girl" />
    </>
  );

  return (
    <PageSection
      title={intl.formatMessage({ id: 'SECTION_SIGNUP_TITLE' })}
      rightColumnTitle={rightColumnTitle}
      rightColumn={rightColumn}
      className="signup-container"
    >
      <h1 className="headline">
        <FormattedMessage id="SECTION_SIGNUP_HEADLINE" />
      </h1>
      <Form studioLink={studio} aidLink={aid} />
      {!isLoginLinkDisplayed && <SignUpText id="signup-text-no-login" />}
      {isMidSizeScreen && <div className="girl" />}
    </PageSection>
  );
}

export default Signup;
