import '../MyContent/MyContent.scss';

import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar/Scrollbar';
import { getValidationScheme } from './Diary.functions';
import DiaryInfo from './DiaryInfo';
import EditDiary from './EditDiary';

function Diary({ items, initialValues }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const location = useLocation();
  const routes = items.map((route, i) => {
    return (
      <Route
        key={`/my-content/diary${route.path}`}
        path={route.path + '/*'}
        element={<route.component />}
      />
    );
  });

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-content-wrapper diary-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          {location.pathname.includes('/diary/edit') && !isLargeScreen ? (
            <Routes>
              {routes}
              <Route path={'*'} element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <div className="data-wrapper">
              <Routes>
                {routes}
                <Route path={'*'} element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          )}
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            {location.pathname.includes('my-diary') && <DiaryInfo />}
            {location.pathname.includes('edit') && (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={getValidationScheme(intl)}
              >
                <EditDiary />
              </Formik>
            )}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Diary;
