import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import Scrollbar from '../../../Scrollbar';

function Friends({ items }) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const routes = items.map((route, i) => {
    const Component = route.component;
    return (
      <Route
        key={`/my-relationships/friends${route.path}`}
        path={route.path}
        element={<Component />}
      />
    );
  });
  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="relationships-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Routes>
              {routes}
              <Route path={'*'} element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="RELATIONSHIPS_INFO_HEADLINE" />
            </h2>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_1">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_2">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_2_1" />
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_3">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_4">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Friends;
