export default {
  PERSONAL_MESSAGES_HEADLINE: `Increase your revenue through individual and personalized messages!`,
  PERSONAL_MESSAGES_INTRO: `Today we give you helpful tips on the topic: The art of writing. Arousing curiosity and sharing shared perspectives and fantasies is a crucial element that not only grabs your customers' attention, but also encourages them to want to learn more about you.`,

  PERSONAL_MESSAGES_HEADLINE_1: `Here are some tips and tricks that can help you:`,
  PERSONAL_MESSAGES_CONTENT_STEP_1: `Personalization`,
  PERSONAL_MESSAGES_CONTENT_STEP_1_CONTENT: `The more personal your message, the more likely it is that the customer will feel addressed and respond. Take notes on each of your chats and address the customer by name if they have given it to you. Use the {link} from his profile to show him that you are paying attention with a direct question.`,
  PERSONAL_MESSAGES_CONTENT_STEP_1_LINK: `user information`,
  PERSONAL_MESSAGES_CONTENT_STEP_2: `Ask interesting questions`,
  PERSONAL_MESSAGES_CONTENT_STEP_2_CONTENT: `Ask open-ended questions that arouse the customer's curiosity. Questions such as "What is your greatest fantasy?" or "What was your most exciting adventure?" invite you to talk about personal experiences and preferences.`,
  PERSONAL_MESSAGES_CONTENT_STEP_3: `Humorous and playful writing style`,
  PERSONAL_MESSAGES_CONTENT_STEP_3_CONTENT: `A casual and fun writing style can break the ice and make communication more enjoyable. This can be a little joke or an anecdote from your day. If you smile when you think about it, you will do the same to your customer.`,
  PERSONAL_MESSAGES_CONTENT_STEP_4: `Show creativity`,
  PERSONAL_MESSAGES_CONTENT_STEP_4_CONTENT: `Use your creativity to tell interesting stories or describe unique situations. This allows you to take your customers into your world and stimulate their imagination.`,
  PERSONAL_MESSAGES_CONTENT_STEP_5: `Pay attention`,
  PERSONAL_MESSAGES_CONTENT_STEP_5_CONTENT: `Respond to your customers' messages in detail and ask specific questions. This will show interest and create a connection. If only "yes or no" questions come from both sides, you feel like you're in a questionnaire that you're working through and lose interest.`,
  PERSONAL_MESSAGES_CONTENT_STEP_6: `Build trust`,
  PERSONAL_MESSAGES_CONTENT_STEP_6_CONTENT: `Create a trusting basis from the beginning by communicating openly and honestly. {link} and honesty are key to long-term entertainment.`,
  PERSONAL_MESSAGES_CONTENT_STEP_6_LINK: `Authenticity`,
  PERSONAL_MESSAGES_CONTENT_STEP_7: `Use call-to-action`,
  PERSONAL_MESSAGES_CONTENT_STEP_7_CONTENT: `Use a clear call-to-action to get the customer to take action. For example, you can ask them to take the next step, such as video chatting or buying additional photos. Don't push your customer, but give him the feeling that he himself came up with the idea and takes the step.`,

  PERSONAL_MESSAGES_OUTRO: `These tips will help you further increase your revenue and build a long-term and loyal customer base.`,
};
