export default {
  CONTEST_LIVECAM_SHOWS: 'Your coins from livecam shows',
  CONTEST_MESSAGES: 'Your coins from messages',
  CONTEST_ITEMS_SOLD: 'Your coins from sold content',
  CONTEST_INFO_LINK_TEXT: 'All info here!',
  CONTEST_UPDATED_TEXT: 'Your ranking is updated every 10 minutes.',
  CONTEST_RANK: 'Rank',
  CONTEST_COINS: 'Coins',
  CONTEST_NO_RANK: 'You`re not in the Top {{display_rank}} yet',
  CONTEST_END_NO_RANK: 'You didn`t reach the Top {{display_rank}}',

  CONTEST_WIKI_HEADLINE: 'X-Mas Contest on LiveCreator: Get your present!',
  CONTEST_WIKI_INTRO_1:
    'This year Santa Claus has come up with a very special surprise for you!',
  CONTEST_WIKI_INTRO_2:
    'From <span class="bold">December 1<sup>st</sup> to 24<sup>th</sup>, 2024</span> you can give yourself a rich present as part of a very special <span class="bold">X-Mas Contest</span>. Take part and get your present!',

  CONTEST_WIKI_INFO_HEADLINE_1: `What's in it for you?`,
  CONTEST_WIKI_INFO_TEXT_1:
    'If you are one of the best creators, the following rewards await you:',
  TABLE_RANK_TEXT: 'Rank',
  TABLE_RANK_CONCAT: 'to',
  TABLE_BONUS_TEXT: 'bonus',
  CONTEST_WIKI_INFO_TEXT_2: `And the best thing is: <span class='bold'>you can excel in both categories</span>! If, for example, you become the best livecam creator (1st place) and the third best chatter (3rd place), you will receive a total bonus of {{combined_bonus}}!`,
  CONTEST_WIKI_INFO_HEADLINE_2: `How does it work?`,
  CONTEST_WIKI_INFO_TEXT_3: `We will closely monitor and compare the sales of all creators in the livecam and chat categories throughout the entire campaign period. If you generate coin sales in one of the two categories, these will be automatically recorded and you will be placed in the ranking list for the corresponding category.`,
  CONTEST_WIKI_INFO_TEXT_4: `You will be informed of your current ranking on your dashboard. If you are among the {{winners}} best creators in a category, this will be shown to you separately.`,
  CONTEST_WIKI_INFO_HEADLINE_3: `What you have to do to take part:`,
  CONTEST_WIKI_INFO_TEXT_5: `Be online! 😊 All creators who log into LiveCreator during the campaign period automatically take part in the competition. And whoever makes the most coins wins!`,
  CONTEST_WIKI_INFO_TEXT_6: `The following sales are included in the categories:
   <p>
    <ul class='checklist'>
      <li><span class='bold'>Livecams:</span> all coins from the minutes of the livecam, exclusive and voyeur shows</li>
      <li><span class='bold'>Messages:</span> all coins from sales of paid messages and sex icons. Gifts and premium file attachments are excluded</li>
    </ul>
   </p>`,
  CONTEST_WIKI_INFO_TEXT_7:
    'The campaign begins on December 1<sup>st</sup>, 2024 at 00:00 and ends on December 24<sup>th</sup>, 2024 at 24:00. As soon as the campaign is over, you will be shown the provisional final score. You will then receive your bonus with the payout on January 1<sup>st</sup>, 2025.',
  CONTEST_WIKI_INFO_TEXT_8: `Be there and get your gift!`,
  CONTEST_WIKI_INFO_TEXT_9: `We wish you the best of luck!`,
};
