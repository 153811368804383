import './Icebreaker.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import AddDE from '../../../../../assets/img/wiki/wiki_icebreaker_add_de.jpg';
import AddEN from '../../../../../assets/img/wiki/wiki_icebreaker_add_en.jpg';
import BannerDE from '../../../../../assets/img/wiki/wiki_icebreaker_banner_de.png';
import BannerEN from '../../../../../assets/img/wiki/wiki_icebreaker_banner_en.png';
import ChatIceDE from '../../../../../assets/img/wiki/wiki_icebreaker_chat_de.png';
import ChatIceEN from '../../../../../assets/img/wiki/wiki_icebreaker_chat_en.png';
import InactiveDE from '../../../../../assets/img/wiki/wiki_icebreaker_inactive_de.jpg';
import InactiveEN from '../../../../../assets/img/wiki/wiki_icebreaker_inactive_en.jpg';
import Sexicon from '../../../../../assets/img/wiki/wiki_icebreaker_sexicons.jpg';
import UploadDE from '../../../../../assets/img/wiki/wiki_icebreaker_upload_de.jpg';
import UploadEN from '../../../../../assets/img/wiki/wiki_icebreaker_upload_en.jpg';
import BackButton from '../../../../BackButton';

function Icebreaker({ locale, onBackButtonClicked }) {
  return (
    <div className="wiki-content icebreaker-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="ICEBREAKER_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="ICEBREAKER_INTRO" />
      </p>
      {locale === 'en' ? (
        <img
          className="img-responsive no-margin"
          src={BannerEN}
          alt="Icebreaker"
        />
      ) : (
        <img
          className="img-responsive no-margin"
          src={BannerDE}
          alt="Icebreaker"
        />
      )}
      <h2 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_1" />
      </p>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_2" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_2" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_2_1" />
      </p>
      <p className="bold">
        <FormattedMessage id="ICEBREAKER_CONTENT_2_2" />
      </p>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_3" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_3" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_3_1" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_3_2" />
      </p>
      <p className="no-margin">
        <FormattedMessage id="ICEBREAKER_CONTENT_3_3" />
      </p>
      <p>
        <FormattedMessage
          id="ICEBREAKER_CONTENT_3_4"
          values={{
            link: (
              <Link key="icebreakers-link-rankings" to="/wiki/general#rankings">
                <FormattedMessage id="ICEBREAKER_CONTENT_3_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_3_5">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_4" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_4" />
      </p>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={ChatIceEN}
            alt="Icebreaker in chat"
          />
        ) : (
          <img
            className="img-responsive"
            src={ChatIceDE}
            alt="Icebreaker im Chat"
          />
        )}
      </div>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_4_1" />
      </p>
      <div className="image-wrapper">
        <img className="img-responsive" src={Sexicon} alt="Sexicons" />
      </div>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_5" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_5" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_5_1" />
      </p>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img className="img-responsive" src={InactiveEN} alt="Inactive" />
        ) : (
          <img className="img-responsive" src={InactiveDE} alt="Inaktiv" />
        )}
      </div>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_5_2" />
      </p>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_6" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_6" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_6_1" />
      </p>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="ICEBREAKER_CONTENT_6_1_HOW_TO_HEADLINE_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_1"
              values={{
                link: (
                  <Link
                    key="icebreaker-link-attachements"
                    to="/wiki/moneymakers#attachments"
                  >
                    <FormattedMessage id="ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive"
                src={UploadEN}
                alt="Upload attachment"
              />
            ) : (
              <img
                className="img-responsive"
                src={UploadDE}
                alt="Upload Dateianhang"
              />
            )}
          </div>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="ICEBREAKER_CONTENT_6_1_HOW_TO_HEADLINE_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_2" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive"
                src={AddEN}
                alt="Add attachment"
              />
            ) : (
              <img
                className="img-responsive"
                src={AddDE}
                alt="Dateianhang hinzufügen"
              />
            )}
          </div>
        </div>
      </div>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_7" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_7" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_PICTURE" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_PICTURE" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_FIRST_LOGIN" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_FIRST_LOGIN" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_RECURRENT_LOGIN" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_RECURRENT_LOGIN" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_KISS_SENT" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_KISS_SENT" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_PROFILE_VISIT" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_PROFILE_VISIT" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_FOLLOWUP" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_FOLLOWUP" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_USER_PICTURE_WANTED" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PICTURE_WANTED" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_NEW_PROFILEIMAGE" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_NEW_PROFILEIMAGE" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_USER_PREFERENCES_WANTED" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_WANTED" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_USER_PREFERENCES_MATCH" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_MATCH" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_HON_MATCH" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_HON_MATCH" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_HON_PICTURE_WANTED" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_HON_PICTURE_WANTED" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_PROFILE_VISIT_LIVECAM" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_PROFILE_VISIT_LIVECAM" />
      </p>
      <p className="type">
        <FormattedMessage id="ICEBREAKER_HEADLINE_MEDIA_PURCHASE" />
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_PARAGRAPH_EXPLANATION_MEDIA_PURCHASE" />
      </p>
      <h3 className="headline">
        <FormattedMessage id="ICEBREAKER_HEADLINE_8" />
      </h3>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_8">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_8_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_8_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="ICEBREAKER_CONTENT_8_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}

export default Icebreaker;
