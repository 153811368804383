import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import isFavorite from '../../../../../assets/img/wiki/wiki_relationships_is_favorite.png';
import isFriend from '../../../../../assets/img/wiki/wiki_relationships_is_friend.png';
import noRelationship from '../../../../../assets/img/wiki/wiki_relationships_no_relationship.png';
import BackButton from '../../../../BackButton';

function Relationships({ unconfirmed, onBackButtonClicked }) {
  return (
    <div className="wiki-content relationship-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="RELATIONSHIPS_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage
          id="RELATIONSHIPS_CONTENT"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="RELATIONSHIPS_CONTENT_LINK" />
            ) : (
              <Link
                key="relationships-link-active-friends"
                to="/my-relationships/friends/active-friends"
              >
                <FormattedMessage id="RELATIONSHIPS_CONTENT_LINK" />
              </Link>
            ),
          }}
        />
      </p>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RELATIONSHIPS_CONTENT_1_1" />
          </p>
          <p>
            <FormattedMessage id="RELATIONSHIPS_CONTENT_1_2" />
          </p>
          <p>
            <FormattedMessage id="RELATIONSHIPS_CONTENT_1_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>

          <p className="list-headline">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_2" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="RELATIONSHIPS_CONTENT_2_1" />
            </li>
          </ul>
          <div className="image-wrapper-not-center">
            <div className="img-description">
              <img
                className="img-responsive"
                src={noRelationship}
                alt="Friend"
              />
              <FormattedMessage id="RELATIONSHIPS_CONTENT_2_NO_FRIEND" />
            </div>
            <div className="img-description">
              <img className="img-responsive" src={isFriend} alt="Friend" />
              <FormattedMessage id="RELATIONSHIPS_CONTENT_2_IS_FRIEND" />
            </div>
          </div>
          <p className="list-headline">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_2_2" />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_2_3"
                values={{
                  link: (
                    <Link
                      key="relationships-link-messenger"
                      to="/wiki/general#multiMessenger"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_MESSENGER_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
          </ul>
          <p className="list-headline">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_2_4" />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_2_5"
                values={{
                  link: unconfirmed ? (
                    <FormattedMessage id="RELATIONSHIPS_CONTENT_2_LINK" />
                  ) : (
                    <Link
                      key="relationships-link-friends-request"
                      to="/my-relationships/friends/friend-requests"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_2_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
          </ul>

          <p className="list-headline">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_3" />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_3_1"
                values={{
                  link: (
                    <Link
                      key="relationships-link-messenger"
                      to="/wiki/general#multiMessenger"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_MESSENGER_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_3_2"
                values={{
                  link: (
                    <Link
                      key="relationships-link-messenger"
                      to="/wiki/general#multiMessenger"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_MESSENGER_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_3_3"
                values={{
                  link: unconfirmed ? (
                    <FormattedMessage id="RELATIONSHIPS_CONTENT_3_LINK" />
                  ) : (
                    <Link
                      key="relationships-link-active-friends"
                      to="/my-relationships/friends/active-friends"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_3_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RELATIONSHIPS_CONTENT_4_1" />
          </p>
          <p>
            <FormattedMessage id="RELATIONSHIPS_CONTENT_4_2" />
          </p>
          <p>
            <FormattedMessage id="RELATIONSHIPS_CONTENT_4_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p className="list-headline">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_4_4" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="RELATIONSHIPS_CONTENT_4_5" />
            </li>
          </ul>
          <div className="image-wrapper-not-center">
            <div className="img-description">
              <img
                className="img-responsive"
                src={noRelationship}
                alt="Favorit"
              />
              <FormattedMessage id="RELATIONSHIPS_CONTENT_4_NO_FAVORITE" />
            </div>
            <div className="img-description">
              <img className="img-responsive" src={isFavorite} alt="Favorit" />
              <FormattedMessage id="RELATIONSHIPS_CONTENT_4_IS_FAVORITE" />
            </div>
          </div>

          <p className="list-headline">
            <FormattedMessage id="RELATIONSHIPS_CONTENT_4_6" />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_4_7"
                values={{
                  link: (
                    <Link
                      key="relationships-link-messenger"
                      to="/wiki/general#multiMessenger"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_MESSENGER_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="RELATIONSHIPS_CONTENT_4_8"
                values={{
                  link: unconfirmed ? (
                    <FormattedMessage id="RELATIONSHIPS_CONTENT_4_LINK" />
                  ) : (
                    <Link
                      key="relationships-link-messenger"
                      to="/my-relationships/favorites"
                    >
                      <FormattedMessage id="RELATIONSHIPS_CONTENT_4_LINK" />
                    </Link>
                  ),
                }}
              />
            </li>
          </ul>
        </div>
      </div>
      <h3 className="headline">
        <FormattedMessage id="RELATIONSHIPS_CONTENT_5" />
      </h3>
      <p>
        <FormattedMessage id="RELATIONSHIPS_CONTENT_5_1" />
      </p>
      <h3 className="headline">
        <FormattedMessage id="RELATIONSHIPS_CONTENT_6" />
      </h3>
      <p>
        <FormattedMessage id="RELATIONSHIPS_CONTENT_6_1" />
      </p>
    </div>
  );
}

export default Relationships;
