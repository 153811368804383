import packageJson from '../../../package.json';
import { VERSION_CHECK_TIMEOUT } from '../../constants';
import { checkVersion } from '..';

const { version } = packageJson;
let timer = null;

const stop = () => {
  console.log('Version check timer stop called');

  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
};

const start = () => (dispatch, getState) => {
  console.log('Version check timer start called');

  stop();

  const check = () => {
    dispatch(checkVersion(version));
    timer = setTimeout(check, VERSION_CHECK_TIMEOUT);
  };

  timer = setTimeout(check, VERSION_CHECK_TIMEOUT);
};

export const versionTimer = { start, stop };
