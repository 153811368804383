import './StreamingOptions.scss';

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import menuIcon from '../../../assets/img/svg/menu-dots.svg';
import mic from '../../../assets/img/svg/mic.svg';
import stopIcon from '../../../assets/img/svg/stop.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Button from '../../Button/Button';
import Select from '../../Forms/Select/Select';
import VoiceRecorderControl from '../Chat/Control/VoiceControl/index';

const StreamingOptions = ({
  resolutions,
  cams,
  microphones,
  selectedCam,
  selectedResolution,
  selectedMicrophone,
  disabled,
  reset,
  handleChange,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const intl = useIntl();
  const [micTest, setMicTest] = useState();
  const [showRecordButton, setShowRecordButton] = useState(true);
  const [recordingState, setRecordingState] = useState({
    recording: false,
    recordingClicked: false,
    recordingStarted: false,
    charLimitReached: false,
  });
  const { recordingClicked, recordingStarted } = recordingState;

  const clearRecordingState = () => {
    setRecordingState({
      recording: false,
      recordingClicked: false,
      microphoneTest: true,
      recordingStarted: false,
      charLimitReached: false,
    });
  };

  useEffect(() => {
    setMicTest(null);
    setShowRecordButton(true);
    clearRecordingState();
  }, [selectedMicrophone, setMicTest]);

  const setRecordingStarted = (recordingStarted) => {
    setRecordingState((prevState) => ({
      ...prevState,
      recordingStarted,
    }));
  };

  const setRecording = (recording) => {
    setRecordingState((prevState) => ({
      ...prevState,
      recording,
    }));
  };

  const clickRecordingHandler = (recordingClicked) => {
    setRecordingState((prevState) => {
      if (prevState.recordingClicked === recordingClicked) {
        return prevState; // No update needed
      }
      return { ...prevState, recordingClicked };
    });
  };

  const menuIconClass = classNames('menu-icon', {
    active: menuVisible,
  });

  return (
    <div className="stream-settings group-container">
      <div className="headline-with-menu form-group">
        <h2 className="headline column">
          <FormattedMessage id="streamingOptions.title" />
        </h2>
        <div className="settings-menu-wrapper">
          <Button
            variant="icon-only"
            onClick={() => {
              if (!menuVisible) {
                setMenuVisible(true);
              }
            }}
            intlTranslate={false}
            icon={menuIcon}
            disabled={
              disabled ||
              (selectedCam.value === '0' &&
                !selectedResolution.value &&
                !selectedMicrophone.value)
            }
            className={menuIconClass}
          />
          {menuVisible && (
            <div className="toggle-menu settings-menu" ref={menuRef}>
              <div
                className="menu-item"
                onClick={() => {
                  reset();
                  setMenuVisible(false);
                }}
              >
                <FormattedMessage id="reset" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="streamingOptions.cam" />
        </label>
        <Select
          options={cams}
          intlTranslate={false}
          disabled={
            disabled ||
            cams.length === 1 ||
            (cams.length === 2 && selectedCam.value !== '0')
          }
          value={selectedCam.value}
          noIsSaved={true}
          onChange={handleChange('checkBandwidth')}
        />
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="streamingOptions.resolution" />
        </label>
        <Select
          options={resolutions}
          intlTranslate={false}
          disabled={disabled || !resolutions.length}
          value={selectedResolution.value}
          onChange={handleChange('changeCamResolution')}
        />
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="streamingOptions.mic" />
        </label>
        <Select
          options={microphones}
          intlTranslate={false}
          disabled={disabled || microphones.length === 1}
          value={selectedMicrophone.value}
          onChange={handleChange('changeMicrophone')}
        />
      </div>
      <div className="form-group">
        <span className="column"></span>
        {micTest && selectedMicrophone.value !== false ? (
          <div className="mic-test">
            <VoiceRecorderControl
              recordingClicked={recordingClicked}
              clickRecordingHandler={clickRecordingHandler}
              setRecording={setRecording}
              clearRecordingState={clearRecordingState}
              setRecordingstarted={setRecordingStarted}
              microphoneTest={true}
              selectedMicrophone={selectedMicrophone.value}
              setShowRecordButton={setShowRecordButton}
            />
            {showRecordButton && (
              <Button
                className="btn-voice"
                variant="icon-only"
                title={intl.formatMessage({
                  id: recordingClicked
                    ? 'AUDIO_BUTTON_STOP'
                    : 'AUDIO_BUTTON_RECORD',
                })}
                onClick={() => {
                  !recordingStarted && setRecordingStarted(true);
                  clickRecordingHandler(!recordingClicked);
                }}
                intlTranslate={false}
                icon={recordingClicked ? stopIcon : mic}
              />
            )}
          </div>
        ) : (
          <Button
            className="btn-form"
            label="streamingOptions.testMicrophone"
            onClick={() => {
              setMicTest(!micTest);
            }}
            disabled={selectedMicrophone.value === false}
          />
        )}
      </div>
    </div>
  );
};

export default StreamingOptions;
