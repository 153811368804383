import './AddressChanger.scss';

import useAxios from 'axios-hooks';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../Button/Button';
import CountriesSelectWithoutDefault from '../../../Forms/CountriesSelect/CountriesSelectWithoutDefault';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../Forms/Input/Input';
import Select from '../../../Forms/Select/Select';

function AddressChanger({ stamp, closeOverlay, user, update, spinner }) {
  const intl = useIntl();
  const pending = user.private?.new_address?.status === 'pending';
  const [disableButton, setDisableButton] = useState(true);
  const [changes, setChanges] = useState([]);

  const { values, errors, setFieldTouched, setFieldValue, touched } =
    useFormikContext();

  const [{ data, error: changeAddressError }, changeAddress] = useAxios(
    {
      url: `/user/private/`,
      data: {
        new_address: {
          address1: values?.address1,
          address2: values?.address2,
          zip: values?.zip,
          city: values?.city,
          country_code: values?.country_code,
        },
      },

      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  function onChange(event) {
    setFieldTouched(event.target.name, true);
    setFieldValue(event.target.name, event.target.value);
    setChanges((prevState) => ({
      ...prevState,
      [event.target.name]:
        user.private[event.target.name] !== event.target.value ? true : false,
    }));
  }

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      Object.values(changes).some((value) => value === true)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [touched, errors, changes]);

  async function submit() {
    spinner(true);
    try {
      await changeAddress();
    } catch (err) {
      console.log('Unable to Change Address');
    } finally {
      spinner(false);
    }
  }

  useEffect(() => {
    if (data && data !== user.private && !changeAddressError) {
      update((user.private = data));
      closeOverlay(stamp);
    }
  }, [data, update, user, changeAddressError, closeOverlay, stamp]);

  return (
    <>
      <div className="overlay-wrapper small-overlay address-changer">
        <div className="overlay-header">
          <h1 className="headline">
            {intl.formatMessage({
              id:
                user.private?.new_address?.status === 'pending'
                  ? 'ADDRESS_PENDING_TITLE'
                  : 'CHANGE_ADDRESS',
            })}
          </h1>
        </div>

        {!user.private?.new_address && !changeAddressError && (
          <div className={'message-address'}>
            <p>{intl.formatMessage({ id: 'CHANGE_ADDRESS_TEXT' })}</p>
          </div>
        )}

        {changeAddressError && (
          <div className={'error message-address'}>
            {intl.formatMessage({ id: 'ADDRESS_ERROR' })}
          </div>
        )}

        <div className="overlay-content">
          <FieldWithErrors
            disabled={pending}
            as={Input}
            name="address1"
            label="FIELD_STREET_ADDRESS"
            onChange={(event) => {
              onChange(event);
            }}
          />
          <FieldWithErrors
            disabled={pending}
            as={Input}
            name="address2"
            label="FIELD_STREET_ADDRESS2"
            onChange={(event) => {
              onChange(event);
            }}
          />
          <FieldWithErrors
            disabled={pending}
            as={Input}
            name="zip"
            label="CHANGE_ADDRESS_ZIP"
            onChange={(event) => {
              onChange(event);
            }}
          />
          <FieldWithErrors
            disabled={pending}
            as={Input}
            name="city"
            label="CHANGE_ADDRESS_CITY"
            onChange={(event) => {
              onChange(event);
            }}
          />
          <FieldWithErrors
            disabled={pending}
            id="country_code"
            name="country_code"
            label="FIELD_LIVINGCOUNTRY"
            tabIndex="2"
            as={CountriesSelectWithoutDefault}
            asSelect={Select}
            intlTranslate={false}
            breakLineIndex={8}
            onChange={(event) => {
              onChange(event);
            }}
          />

          <div className="buttons">
            <Button
              type="button"
              label={'CHANGE_ADDRESS_BACK'}
              className={'btn-change'}
              onClick={() => closeOverlay(stamp)}
            />
            {!pending ? (
              <Button
                type="button"
                disabled={disableButton}
                label="CHANGE_ADDRESS"
                className={'btn-change'}
                onClick={() => {
                  submit();
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="background" />
    </>
  );
}

export default AddressChanger;

