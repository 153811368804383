export default {
  PERSONAL_MESSAGES_HEADLINE: `Steigere Deine Einnahmen durch individuelle und personalisierte Nachrichten!`,
  PERSONAL_MESSAGES_INTRO: `Heute geben wir Dir hilfreiche Tipps zum Thema: Die Kunst des Schreibens. Das Wecken von Neugier  und Aufzeigen von gemeinsamen Perspektiven und Fantasien, ist ein entscheidendes Element, das nicht nur die Aufmerksamkeit Deiner Kunden erregt, sondern sie auch dazu anregt, mehr über Dich erfahren zu wollen.`,

  PERSONAL_MESSAGES_HEADLINE_1: `Hier sind einige Tipps und Tricks, die Dir helfen können:`,
  PERSONAL_MESSAGES_CONTENT_STEP_1: `Personalisierung`,
  PERSONAL_MESSAGES_CONTENT_STEP_1_CONTENT: `Je persönlicher Deine Nachricht, desto wahrscheinlicher ist es, dass der Kunde sich angesprochen fühlt und antwortet. Mache Dir zu jedem Deiner Chats Notizen und sprich den Kunden mit Namen an, wenn er Dir diesen genannt hat. Nutze die {link} aus seinem Profil, um ihm mit einer direkten Frage zu zeigen das Du aufmerksam bist.`,
  PERSONAL_MESSAGES_CONTENT_STEP_1_LINK: `User Infos`,
  PERSONAL_MESSAGES_CONTENT_STEP_2: `Interessante Fragen stellen`,
  PERSONAL_MESSAGES_CONTENT_STEP_2_CONTENT: `Stelle offene Fragen, die die Neugier des Kunden wecken. Fragen wie "Was ist Deine größte Fantasie?" oder "Was war Dein aufregendstes Abenteuer?", laden dazu ein über persönliche Erfahrungen und Vorlieben zu sprechen.`,
  PERSONAL_MESSAGES_CONTENT_STEP_3: `Humorvoller und spielerischer Schreibstil`,
  PERSONAL_MESSAGES_CONTENT_STEP_3_CONTENT: `Ein lockerer und lustiger Schreibstil kann das Eis brechen und die Kommunikation angenehmer machen. Das kann ein kleiner Witz oder eine Anekdote aus Deinem Tag sein. Wenn Du beim Nachdenken darüber schmunzelst, wirst Du dies auch bei Deinem Kunden bewirken.`,
  PERSONAL_MESSAGES_CONTENT_STEP_4: `Kreativität zeigen`,
  PERSONAL_MESSAGES_CONTENT_STEP_4_CONTENT: `Nutze Deine Kreativität, um interessante Geschichten zu erzählen oder einzigartige Situationen zu beschreiben. Dadurch kannst Du Deine Kunden in Deine Welt mitnehmen und ihre Fantasie anregen.`,
  PERSONAL_MESSAGES_CONTENT_STEP_5: `Aufmerksamkeit schenken`,
  PERSONAL_MESSAGES_CONTENT_STEP_5_CONTENT: `Gehe detailliert auf die Nachrichten Deiner Kunden ein und stelle spezifische Fragen. Dadurch zeigst Du Interesse und schaffst eine Verbindung. Wenn von beiden Seiten nur „Ja oder Nein“ Fragen kommen, fühlt man sich wie in einem Fragenkatalog den man abarbeitet und verliert das Interesse.`,
  PERSONAL_MESSAGES_CONTENT_STEP_6: `Vertrauen aufbauen`,
  PERSONAL_MESSAGES_CONTENT_STEP_6_CONTENT: `Schaffe von Beginn an eine vertrauensvolle Basis, indem Du offen und ehrlich kommunizierst. {link} und Ehrlichkeit sind der Schlüssel für eine langfristige Unterhaltung.`,
  PERSONAL_MESSAGES_CONTENT_STEP_6_LINK: `Authentizität`,
  PERSONAL_MESSAGES_CONTENT_STEP_7: `Call-to-Action verwenden`,
  PERSONAL_MESSAGES_CONTENT_STEP_7_CONTENT: `Nutze eine klare Handlungsaufforderung, um den Kunden zum Handeln zu bewegen. Du kannst zum Beispiel dazu auffordern, den nächsten Schritt zu gehen, wie einen Videochat oder den Kauf zusätzlicher Fotos. Dränge Deinen Kunden nicht, sondern vermittle ihm das Gefühl, das er selbst auf die Idee kam und den Schritt macht.`,

  PERSONAL_MESSAGES_OUTRO: `Diese Tipps werden Dir dabei helfen Deine Einnahmen weiter zu steigern und eine langfristige und loyale Kundenbasis aufzubauen.`,
};
