import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { lStorage } from '../../../services/storage';
import LandingPage from '../../Content/LandingPages/LandingPage';

const stateRedirectMap = {
  onboarding: '/onboarding',
  review_needed: '/review',
  pending: '/onboarding',
  confirmed: '/my-account/dashboard',
  unconfirmed: '/my-account/dashboard',
  double_optin_needed: '/doi',
};

function HomeRoute({ user = {} }) {
  const location = useLocation();

  let route = {
    pathname: '/',
    search: location?.search,
  };

  const isModelHomepage = !!lStorage.getItem('mhp');
  const modelRoute = '/model';

  if (!user.isLoggedIn && !isModelHomepage) {
    return <LandingPage />;
  } else if (!user.isLoggedIn && isModelHomepage) {
    route.pathname = modelRoute;
  } else {
    route.pathname = stateRedirectMap[user.account.state];
  }

  return <Navigate to={route} replace />;
}

export default HomeRoute;
