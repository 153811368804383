import './LandingPage.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import footerLogo from '../../../../assets/img/logo-whiteBG.svg';
import creatorIcon from '../../../../assets/img/svg/creator.svg';
import facebookLogo from '../../../../assets/img/svg/facebook.svg';
import followerIcon from '../../../../assets/img/svg/follower.svg';
import instagramLogo from '../../../../assets/img/svg/instagram.svg';
import makeMoneyIcon from '../../../../assets/img/svg/make-money.svg';
import play from '../../../../assets/img/svg/play-video.svg';
import youtubeLogo from '../../../../assets/img/svg/youtube.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { lStorage } from '../../../../services/storage';
import { uuid } from '../../../../utils';
import Button from '../../../Button/Button';
import LanguageSelect from '../../../Forms/LanguageSelect/';
import Scrollbar from '../../../Scrollbar';
import {
  lowerLeftPanels,
  lowerRightPanels,
  upperLeftPanels,
  upperRightPanels,
} from './LandingPage.functions';
import LandingPageHeadline from './LandingPageHeadline/LandingPageHeadline';
import LandingPagePanel from './LandingPagePanel/LandingPagePanel';
import PanelContentWithImage from './PanelsContent/PanelContentWithImage';
import PanelContentWithoutImage from './PanelsContent/PanelContentWithoutImage';
import PanelSubtitle from './PanelsContent/PanelSubtitle';

function LandingPage({ isSubmenuShown, isMobile, openOverlay, language }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isMediumScreen = useMediaQuery(
    '(min-width: 768px) and (max-width: 1023px)'
  );
  const isMidScreen = useMediaQuery('(min-width: 1024px)');
  const [isExpanded, setIsExpanded] = useState(new Array(18).fill(false));

  const toggleExpand = (index) => {
    const newState = new Array(18).fill(false);
    const visibility = isExpanded[index];
    newState[index] = !visibility;
    setIsExpanded(newState);
  };

  const goToSignup = () => {
    navigate('/signup');
  };

  const scrollIntoElement = (index, id) => {
    if (!isExpanded[index]) {
      toggleExpand(index);
    }
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const openVideo = async () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Document',
      props: {
        openDocumentStamp: stamp,
        fileSource:
          document.documentElement.lang &&
          document.documentElement.lang === 'de'
            ? `${process.env.REACT_APP_VIDEO_URL}/livecreator_marketing_de.mp4`
            : `${process.env.REACT_APP_VIDEO_URL}/livecreator_marketing_en.mp4`,
        video: true,
      },
    });
  };

  const carouselItemContentClass = classNames('carousel-item-content', {
    'with-sidebar': isSubmenuShown,
  });

  const carouselItem1 = classNames('carousel-item-div carousel-item-1', {
    'carousel-photo-1-mobile': isMobileScreen,
    'carousel-photo-1-desktop': !isMobileScreen,
    'with-sidebar': isSubmenuShown,
  });

  const carouselItem2 = classNames('carousel-item-div carousel-item-2', {
    'carousel-photo-2-mobile': isMobileScreen,
    'carousel-photo-2-desktop': !isMobileScreen,
    'with-sidebar': isSubmenuShown,
  });

  const carouselItem3 = classNames('carousel-item-div carousel-item-3', {
    'carousel-photo-3-mobile': isMobileScreen,
    'carousel-photo-3-desktop': !isMobileScreen,
    'with-sidebar': isSubmenuShown,
  });

  const carouselItem4 = classNames('carousel-item-div carousel-item-4', {
    'carousel-photo-4-mobile': isMobileScreen,
    'carousel-photo-4-desktop': !isMobileScreen,
    'with-sidebar': isSubmenuShown,
  });

  const carouselItem5 = classNames('carousel-item-div carousel-item-5', {
    'carousel-photo-5-mobile': isMobileScreen,
    'carousel-photo-5-desktop': !isMobileScreen,
    'with-sidebar': isSubmenuShown,
  });

  const className = classNames('landing-page-model-wrapper', {
    mobile: isMobile,
  });
  useEffect(() => {
    lStorage.setItem('mhp', '1');
  }, []);
  return (
    <Scrollbar visible={true}>
      <div className={className}>
        <LandingPageHeadline>
          <h1 className="landing-page-headline landing-page-headline-1">
            <FormattedMessage id="LANDING_PAGE_HEADLINE_1" />
          </h1>
          {isMidScreen && <LanguageSelect />}
        </LandingPageHeadline>
        <div className="carousel-wrapper">
          <Carousel>
            <Carousel.Item>
              <div className={carouselItem1}>
                <div className={carouselItemContentClass}>
                  <FormattedMessage id="CAROUSEL_ITEM_1">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </div>
                <div className="button-play" onClick={() => openVideo()}>
                  <ReactSVG src={play} wrapper="span" className="play-button" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={carouselItem2}>
                <div className={carouselItemContentClass}>
                  <FormattedMessage id="CAROUSEL_ITEM_2">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                  <Button
                    type="button"
                    variant="primary"
                    label="CAROUSEL_BUTTON_LABEL"
                    className="carousel-item-button"
                    onClick={() => scrollIntoElement(1, 'landing-page-panel-1')}
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={carouselItem3}>
                <div className={carouselItemContentClass}>
                  <FormattedMessage id="CAROUSEL_ITEM_3">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                  <Button
                    type="button"
                    variant="primary"
                    label="CAROUSEL_BUTTON_LABEL"
                    className="carousel-item-button"
                    onClick={() => scrollIntoElement(1, 'landing-page-panel-1')}
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={carouselItem4}>
                <div className={carouselItemContentClass + ' smaller-content'}>
                  <FormattedMessage id="CAROUSEL_ITEM_4">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                  <Button
                    type="button"
                    variant="primary"
                    label="CAROUSEL_BUTTON_LABEL"
                    className="carousel-item-button"
                    onClick={() => scrollIntoElement(2, 'landing-page-panel-2')}
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={carouselItem5}>
                <div className={carouselItemContentClass}>
                  <FormattedMessage id="CAROUSEL_ITEM_5">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                  <Button
                    type="button"
                    variant="primary"
                    label="CAROUSEL_BUTTON_LABEL"
                    className="carousel-item-button"
                    onClick={() => scrollIntoElement(3, 'landing-page-panel-3')}
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <LandingPageHeadline multipleItems={true}>
          <div className="headline-inner-item">
            <div className="inner-icon">
              {' '}
              <ReactSVG src={makeMoneyIcon} wrapper="span" />
            </div>
            <div className="inner-text">
              <div className="headline-text">
                <FormattedMessage id="LANDING_PAGE_HEADLINE_2_1" />
              </div>
              <div className="headline-subtext">
                <FormattedMessage id="LANDING_PAGE_HEADLINE_2_2" />
              </div>
            </div>
          </div>
          <div className="headline-inner-item">
            <div className="inner-icon">
              {' '}
              <ReactSVG src={creatorIcon} wrapper="span" />
            </div>
            <div className="inner-text">
              <div className="headline-text">
                <FormattedMessage id="LANDING_PAGE_HEADLINE_2_3" />
              </div>
              <div className="headline-subtext">
                <FormattedMessage id="LANDING_PAGE_HEADLINE_2_4" />
              </div>
            </div>
          </div>
          <div className="headline-inner-item">
            <div className="inner-icon">
              {' '}
              <ReactSVG src={followerIcon} wrapper="span" />
            </div>
            <div className="inner-text">
              <div className="headline-text">
                <FormattedMessage id="LANDING_PAGE_HEADLINE_2_5" />
              </div>
              <div className="headline-subtext">
                <FormattedMessage id="LANDING_PAGE_HEADLINE_2_6" />
              </div>
            </div>
          </div>
        </LandingPageHeadline>

        <div className="landing-page-content">
          <div className="panels-container">
            <div className="panels-column">
              {upperLeftPanels.map((panel) => {
                let panelItem;
                panelItem = isNaN(panel.id) ? (
                  isMediumScreen ? (
                    <div
                      className="registration-button-wrapper"
                      key={`landing-page-panel${panel.id}`}
                    >
                      <Button
                        type="button"
                        variant="primary"
                        label="LANDING_REGISTRATION_BUTTON"
                        className="carousel-item-button"
                        onClick={() => goToSignup()}
                      />
                    </div>
                  ) : null
                ) : (
                  <LandingPagePanel
                    id={`landing-page-panel-${panel.id}`}
                    key={`landing-page-panel-${panel.id}`}
                    title={`PANEL_TITLE_${panel.id}`}
                    subtitle={
                      <PanelSubtitle messageId={`PANEL_SUBTITLE_${panel.id}`} />
                    }
                    content={
                      <PanelContentWithImage
                        messageId={`PANEL_CONTENT_${panel.id}`}
                        image={
                          <img
                            src={panel.image}
                            className={
                              isMediumScreen
                                ? panel.mobileImageClass
                                : panel.imageClass
                            }
                            alt={`Panel ${panel.id}`}
                          />
                        }
                        link={
                          panel.id === 2 && (
                            <span
                              className="link"
                              onClick={() => toggleExpand(4)}
                            >
                              {intl.formatMessage({ id: 'LABEL_HERE' })}
                            </span>
                          )
                        }
                      />
                    }
                    toggleExpand={toggleExpand}
                    isExpanded={isExpanded}
                    index={panel.id}
                  />
                );
                return panelItem;
              })}
            </div>
            <div className="panels-column">
              {upperRightPanels.map((panel) => {
                let panelItem;
                panelItem = isNaN(panel.id) ? (
                  isMediumScreen ? (
                    <div
                      className="registration-button-wrapper"
                      key={`landing-page-panel${panel.id}`}
                    >
                      <Button
                        type="button"
                        variant="primary"
                        label="LANDING_REGISTRATION_BUTTON"
                        className="carousel-item-button"
                        onClick={() => goToSignup()}
                      />
                    </div>
                  ) : null
                ) : (
                  <LandingPagePanel
                    id={`landing-page-panel-${panel.id}`}
                    key={`landing-page-panel-${panel.id}`}
                    title={`PANEL_TITLE_${panel.id}`}
                    subtitle={
                      <PanelSubtitle messageId={`PANEL_SUBTITLE_${panel.id}`} />
                    }
                    content={
                      <PanelContentWithImage
                        messageId={`PANEL_CONTENT_${panel.id}`}
                        image={
                          <img
                            src={panel.image}
                            className={panel.imageClass}
                            alt={`Panel ${panel.id}`}
                          />
                        }
                      />
                    }
                    toggleExpand={toggleExpand}
                    isExpanded={isExpanded}
                    index={panel.id}
                  />
                );
                return panelItem;
              })}
            </div>
          </div>
          <div className="registration-button-wrapper">
            <Button
              type="button"
              variant="primary"
              label="LANDING_REGISTRATION_BUTTON"
              className="carousel-item-button"
              onClick={() => goToSignup()}
            />
          </div>
        </div>

        <LandingPageHeadline>
          <span className="landing-page-headline landing-page-headline-3">
            <FormattedMessage id="LANDING_PAGE_HEADLINE_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>{' '}
          </span>
        </LandingPageHeadline>

        <div className="landing-page-content">
          <div className="panels-container">
            <div className="panels-column">
              {lowerLeftPanels.map((id) => {
                let panelItem;
                panelItem = isNaN(id) ? (
                  isMediumScreen ? (
                    <div
                      className="registration-button-wrapper"
                      key={`landing-page-panel${id}`}
                    >
                      <Button
                        type="button"
                        variant="primary"
                        label="LANDING_REGISTRATION_BUTTON"
                        className="carousel-item-button"
                        onClick={() => goToSignup()}
                      />
                    </div>
                  ) : null
                ) : (
                  <LandingPagePanel
                    id={`landing-page-panel-${id}`}
                    key={`landing-page-panel-${id}`}
                    title={`PANEL_TITLE_${id}`}
                    subtitle={
                      <PanelSubtitle messageId={`PANEL_SUBTITLE_${id}`} />
                    }
                    content={
                      <PanelContentWithoutImage
                        messageId={`PANEL_CONTENT_${id}`}
                      />
                    }
                    toggleExpand={toggleExpand}
                    isExpanded={isExpanded}
                    index={id}
                    faq={true}
                  />
                );
                return panelItem;
              })}
            </div>
            <div className="panels-column">
              {lowerRightPanels.map((id) => {
                let panelItem;
                panelItem = isNaN(id) ? (
                  isMediumScreen ? (
                    <div
                      className="registration-button-wrapper"
                      key={`landing-page-panel${id}`}
                    >
                      <Button
                        type="button"
                        variant="primary"
                        label="LANDING_REGISTRATION_BUTTON"
                        className="carousel-item-button"
                        onClick={() => goToSignup()}
                      />
                    </div>
                  ) : null
                ) : (
                  <LandingPagePanel
                    id={`landing-page-panel-${id}`}
                    key={`landing-page-panel-${id}`}
                    title={`PANEL_TITLE_${id}`}
                    subtitle={
                      <PanelSubtitle messageId={`PANEL_SUBTITLE_${id}`} />
                    }
                    content={
                      <PanelContentWithoutImage
                        messageId={`PANEL_CONTENT_${id}`}
                      />
                    }
                    toggleExpand={toggleExpand}
                    isExpanded={isExpanded}
                    index={id}
                    faq={true}
                  />
                );
                return panelItem;
              })}
            </div>
          </div>
          <div className="registration-button-wrapper">
            <Button
              type="button"
              variant="primary"
              label="LANDING_REGISTRATION_BUTTON"
              className="carousel-item-button"
              onClick={() => goToSignup()}
            />
          </div>
        </div>

        <div className="footer">
          <img src={footerLogo} className="footer-logo" alt="LiveCreator" />
        </div>

        <div className="social">
          <h4 className="headline">
            <FormattedMessage id="SOCIAL_VISIT" />
          </h4>
          <div className="social-logos">
            <Button
              intlTranslate={false}
              icon={facebookLogo}
              variant="icon-only"
              onClick={() =>
                window.open(
                  'https://www.facebook.com/profile.php?id=100092839024421'
                )
              }
            />
            <Button
              intlTranslate={false}
              icon={instagramLogo}
              variant="icon-only"
              onClick={() =>
                window.open('https://www.instagram.com/livecreatorgermany/')
              }
            />
            <Button
              intlTranslate={false}
              icon={youtubeLogo}
              variant="icon-only"
              onClick={() =>
                window.open('https://www.youtube.com/@livecreatorcom')
              }
            />
          </div>
        </div>
      </div>
    </Scrollbar>
  );
}

export default LandingPage;
