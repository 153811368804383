import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { redirectedSettings } from '../../../actions';
import Redirect from './Redirect';

function withRouter(Component) {
  return function Wrapper(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  };
}

export default connect(
  (state) => ({
    redirectSettings: state.sender.askInitCamAlertConfirmed,
  }),
  (dispatch) => ({
    redirectDone: () => dispatch(redirectedSettings()),
  })
)(withRouter(Redirect));
