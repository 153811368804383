import { connect } from 'react-redux';

import { openOverlay, spinner } from '../../../actions';
import PhotoItem from './PhotoItem';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
  user: state.user,
});

const mapDispatchToProps = {
  openOverlay,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoItem);
