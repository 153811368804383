import './ChatMenu.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import attachmentIcon from '../../../../assets/img/svg/attachment.svg';
import camIcon from '../../../../assets/img/svg/cam.svg';
import closeIcon from '../../../../assets/img/svg/close.svg';
import exclusiveIcon from '../../../../assets/img/svg/exclusive.svg';
import chooseFileIcon from '../../../../assets/img/svg/menu-dots.svg';
import payttachmentIcon from '../../../../assets/img/svg/payttachment.svg';
import sendIcon from '../../../../assets/img/svg/send.svg';
import MobileStreaming from '../../../../components/Content/Livecam/Streaming/MobileStreaming';
import { SUPPORTED_ATTACHMENT_TYPES } from '../../../../constants';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { getUpload } from '../../../../utils/uploadUtils';
import Button from '../../../Button/Button';
import NumberSelect from '../../../Forms/NumberSelect/NumberSelect';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import Spinner from '../../../Spinner/Spinner';
import AttachmentItem from '../../../ThumbnailItem/AttachmentItem/AttachmentItem';
import PayttachmentItem from '../../../ThumbnailItem/PayttachmentItem/PayttachmentItem';
import { generatePriceList } from './ChatMenu.functions';

function ChatMenu({
  chatMenuOpen,
  username,
  askExclusiveSender,
  toggleChatMenu,
  isExclusive,
  isMessageUser,
  cam,
  isMobile,
  hasCam2Cam,
  attachmentsCount,
  attachments,
  selectAttachment,
  selectedAttachmentName,
  selectedAttachment,
  exclusiveAllowed,
  hasExclusive,
  payttachments,
  sendChatMessage,
  uploadFile,
  uploads = [],
  payttachmentAdd,
  payttachmentsMenuOpen,
  togglePayttachmentsMenu,
  payttachmentsEnabled,
  userId,
  isAdmin,
  allowAttachments,
  errorChatAttachment,
  smileyOverlay,
}) {
  const intl = useIntl();
  const menuRef = useRef();
  const [showMyCam, setShowMyCam] = useState(true);
  const [showAttachmentSelect, setShowAttachmentSelect] = useState(false);
  const [selectedPayttachment, setSelectedPayttachment] = useState(null);
  const [price, setPrice] = useState(20);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useOutsideClick(menuRef, () => {
    if (chatMenuOpen && !selectedAttachment && !smileyOverlay) {
      toggleChatMenu();
    }
  });

  const indicator = 'uploadpayttachments';

  const { uploadIndicator, uploadError, uploadResult } = getUpload(
    uploads,
    indicator
  );

  const attachmentIdicator = 'attachments';

  useEffect(() => {
    if (isMobile && !showMyCam && !hasCam2Cam) {
      setShowMyCam(true);
    }
  }, [hasCam2Cam, showMyCam, isMobile]);

  useEffect(() => {
    if (!chatMenuOpen) {
      setShowAttachmentSelect(false);
      togglePayttachmentsMenu(false);
      setSelectedPayttachment(null);
      setSelectedFile(null);
    }
  }, [chatMenuOpen, togglePayttachmentsMenu]);

  const selectAttachmentHandler = (value, name) => {
    const toggleSelectAttachmentName =
      selectedAttachmentName === name ? '' : name;
    const newValue = selectedAttachment === value ? '' : value;
    selectAttachment({ number: newValue, name: toggleSelectAttachmentName });
    toggleChatMenu();
  };
  const dropzoneProps = { multiple: false };

  const [{ data: sendPayttachments, loading }, payttachmentReq] = useAxios(
    {
      url: `user/payttachments/${userId}?limit=24`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (payttachmentsMenuOpen) {
      payttachmentReq();
    }
  }, [payttachmentsMenuOpen, payttachmentReq]);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const upload = () => {
    if (!selectedFile?.name) {
      return;
    }
    const data = new FormData();
    data.append('file', selectedFile, selectedFile.name);
    data.append('price', price);
    uploadFile('/user/payttachment', data, indicator);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: SUPPORTED_ATTACHMENT_TYPES,
  });

  useEffect(() => {
    if (uploadIndicator === indicator) {
      if (uploadError) {
        uploadFile('', false, indicator);
      } else if (uploadResult && uploadResult?.data) {
        payttachmentAdd(uploadResult.data);
        sendChatMessage('', false, uploadResult.data[0].id);
        uploadFile('', false, indicator);
      }
    }
  }, [
    payttachmentAdd,
    sendChatMessage,
    uploadError,
    uploadFile,
    uploadIndicator,
    uploadResult,
  ]);

  useEffect(() => {
    if (uploadError) {
      const translatedErrors = [
        'attachments_reached_limit',
        'attachments_file_is_missing',
        'unable_to_save',
      ];
      if (attachmentIdicator === uploadIndicator && uploadError) {
        let error = 'ERROR_UPLOAD_DOCUMENT_2';
        if (Array.isArray(uploadError?.response?.data?.errors)) {
          error = 'ERROR_UPLOAD_DOCUMENT_1';
          const firstError = uploadError.response.data.errors.pop();
          if (translatedErrors.includes(firstError)) {
            error = firstError;
          }
        }
        errorChatAttachment(error);
      }
    }
  }, [indicator, uploadError, uploadIndicator, intl, errorChatAttachment]);

  if (isMobile && cam) {
    const camLabel = showMyCam
      ? 'btn.title.showCam2cam'
      : 'btn.title.showMyCam';

    const classes = classNames('toggle-menu', 'chat-menu', 'cam');

    return (
      <div className={classes} ref={menuRef}>
        {hasCam2Cam && (
          <Button
            icon={camIcon}
            onClick={() => setShowMyCam(!showMyCam)}
            variant="default"
            className="btn-cam"
            labelValues={{ username }}
            label={camLabel}
          />
        )}
        <MobileStreaming myCam={showMyCam} />
      </div>
    );
  }

  const label = isMobile
    ? 'btn.title.askExclusiveBlank'
    : 'btn.title.askExclusive';

  const chatMenuClasses = classNames('toggle-menu', 'chat-menu', {
    attachments: showAttachmentSelect || payttachmentsMenuOpen,
    'payttachments-menu': payttachmentsMenuOpen,
  });

  const uploadAndSelectAttachment = async () => {
    if (!selectedFile?.name) {
      return;
    }
    const data = new FormData();
    data.append('file', selectedFile, selectedFile.name);
    toggleChatMenu();
    const attachmentReq = await uploadFile(
      '/user/attachment',
      data,
      attachmentIdicator
    );
    if (attachmentReq && attachmentReq?.status === 200) {
      const attachmentUploaded =
        attachmentReq?.data[attachmentReq?.data.length - 1];
      selectAttachment({
        number: attachmentUploaded?.id,
        name: attachmentUploaded?.name,
      });
    }
  };
  const uploadWrapperClasses = classNames('upload-file-wrapper', {
    disabled: selectedPayttachment,
  });
  // if we have more then one option, move the check for isMessageUser to the distinct button
  return chatMenuOpen && username && (hasExclusive || isMessageUser) ? (
    <div className={chatMenuClasses} ref={menuRef}>
      {!isMessageUser && hasExclusive && (
        <Button
          icon={exclusiveIcon}
          disabled={isExclusive || !exclusiveAllowed}
          onClick={() => {
            askExclusiveSender();
            toggleChatMenu();
          }}
          variant="default"
          className="btn-exclusive"
          labelValues={{ username }}
          label={label}
        />
      )}
      {allowAttachments && (
        <div className="attachment-wrapper">
          {!showAttachmentSelect && !payttachmentsMenuOpen && (
            <>
              <Button
                icon={attachmentIcon}
                onClick={(e) => {
                  if (attachmentsCount === 0) {
                    navigate('/moneymakers/attachments');
                  } else {
                    e.stopPropagation();
                    setShowAttachmentSelect(!showAttachmentSelect);
                  }
                }}
                variant="default"
                className="btn-attachment"
                label="ATTACHMENT_LABEL"
              />
              {payttachmentsEnabled && (
                <Button
                  icon={payttachmentIcon}
                  disabled={isAdmin}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePayttachmentsMenu(true);
                  }}
                  variant="default"
                  className="btn-payttachment"
                  label="PREMIUM_ATTACHMENT"
                />
              )}
            </>
          )}
          {showAttachmentSelect && (
            <>
              {attachments.length >= 25 ? null : (
                <div className="attachment-upload-container">
                  <div className="attachment-headline-wrapper">
                    <h3 className="headline">
                      {intl.formatMessage({ id: 'UPLOAD_LABEL' })}
                    </h3>
                    <Button
                      variant="icon-only"
                      type="button"
                      icon={closeIcon}
                      onClick={() => toggleChatMenu()}
                      intlTranslate={false}
                    />
                  </div>
                  <div className="attachment-upload">
                    <div {...getRootProps({ className: 'form-group' })}>
                      <input {...getInputProps()} />
                      <label>{intl.formatMessage({ id: 'UPLOAD' })}</label>
                      <div
                        className={uploadWrapperClasses}
                        onClick={() => open()}
                      >
                        <input
                          className="upload-input"
                          value={
                            selectedFile
                              ? selectedFile.name
                              : intl.formatMessage({
                                  id: 'CHOOSE_A_FILE',
                                })
                          }
                          readOnly
                        />
                        <ReactSVG
                          src={chooseFileIcon}
                          className="choose-file-icon"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <span className="column" />
                      <Button
                        onClick={() => uploadAndSelectAttachment()}
                        variant="primary"
                        icon={sendIcon}
                        label="UPLOAD_BTN"
                        className="btn-form btn-icon btn-submit"
                        disabled={!selectedFile}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="attachment-headline-wrapper">
                <h3 className="headline">
                  {intl.formatMessage({ id: 'ADD_ATTACHMENT_LABEL' })}
                </h3>
                {attachments.length >= 25 ? (
                  <Button
                    variant="icon-only"
                    type="button"
                    icon={closeIcon}
                    onClick={() => toggleChatMenu()}
                    intlTranslate={false}
                  />
                ) : null}
              </div>
              <Scrollbar visible={true}>
                <div className="attachments-preview-container">
                  {attachments.map((attachment, index) => {
                    return (
                      <AttachmentItem
                        key={`${attachment.value}${index}`}
                        selectAttachment={selectAttachmentHandler}
                        value={attachment.value}
                        label={attachment.label}
                        file={attachment.file}
                        selectedAttachment={selectedAttachment}
                        type={attachment.type}
                      />
                    );
                  })}
                </div>
              </Scrollbar>
            </>
          )}
          {payttachmentsMenuOpen && (
            <>
              <div className="payttachment-upload-container">
                <div className="attachment-headline-wrapper">
                  <h3 className="headline">
                    {intl.formatMessage({ id: 'UPLOAD_LABEL' })}
                  </h3>
                  <Button
                    variant="icon-only"
                    type="button"
                    icon={closeIcon}
                    onClick={() => toggleChatMenu()}
                    intlTranslate={false}
                  />
                </div>
                <div className="payttachment-upload">
                  <div {...getRootProps({ className: 'form-group' })}>
                    <input {...getInputProps()} />
                    <label>{intl.formatMessage({ id: 'UPLOAD' })}</label>
                    <div
                      className={uploadWrapperClasses}
                      onClick={() => open()}
                    >
                      <input
                        className="upload-input"
                        value={
                          selectedFile
                            ? selectedFile.name
                            : intl.formatMessage({
                                id: 'CHOOSE_A_FILE',
                              })
                        }
                        readOnly
                      />
                      <ReactSVG
                        src={chooseFileIcon}
                        className="choose-file-icon"
                      />
                    </div>
                  </div>
                  <NumberSelect
                    options={generatePriceList(5, 500, 5)}
                    label="SET_PRICE"
                    value={price}
                    small={true}
                    onChange={(event) => setPrice(event.target.value)}
                    subtextValue={intl.formatMessage({ id: 'COINS' })}
                    disabled={!!selectedPayttachment}
                  />
                  <div className="form-group">
                    <span className="column" />
                    <Button
                      onClick={() => upload()}
                      variant="primary"
                      icon={sendIcon}
                      label="UPLOAD_AND_SEND"
                      className="btn-form btn-icon btn-submit"
                      disabled={!!selectedPayttachment}
                    />
                  </div>
                </div>
              </div>

              {!!payttachments.length && (
                <div className="payttachment-content">
                  <div className="attachment-headline-wrapper">
                    <div className="headline">
                      <span>
                        {intl.formatMessage({ id: 'SEND_FILE_AGAIN' })}
                      </span>
                    </div>
                  </div>
                  <Scrollbar visible={true}>
                    <div className="payttachments-preview-container">
                      {loading ? (
                        <Spinner />
                      ) : (
                        payttachments.map((payttachment, index) => {
                          return (
                            <PayttachmentItem
                              key={`${payttachment.id}${index}`}
                              payttachmentAlreadySent={sendPayttachments?.includes(
                                payttachment.id
                              )}
                              payttachment={payttachment}
                              selected={
                                selectedPayttachment === payttachment.id
                              }
                              setSelectedPayttachment={setSelectedPayttachment}
                              selectedFile={selectedFile}
                            />
                          );
                        })
                      )}
                    </div>
                  </Scrollbar>
                  <div className="send-button-wrapper">
                    <Button
                      onClick={() =>
                        sendChatMessage('', false, selectedPayttachment)
                      }
                      variant="primary"
                      label="SEND"
                      className="btn-submit"
                      disabled={!selectedPayttachment}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  ) : null;
}

export default ChatMenu;
