import './Wiki.scss';

import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { getWikiRoutes } from '../../../routes';
import HeaderMenu from '../../HeaderMenu/HeaderMenu';
import Section from '../../Section/Section';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import WikiContext from './WikiContext';

function Wiki({ hasContest }) {
  const intl = useIntl();
  const location = useLocation();
  const [items, setItems] = useState(null);

  const [routes, setRoutes] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = useMemo(() => {
    return { setIsLoading };
  }, []);

  useEffect(() => {
    if (location.pathname === '/wiki') {
      if (hasContest) {
        navigate('/wiki/contest');
      } else {
        navigate('/wiki/start');
      }
    }
  }, [navigate, hasContest, location.pathname]);

  useEffect(() => {
    let items = getWikiRoutes(hasContest).filter((item) => !item.notInMenu);

    const headerItems = [...items];

    const routes = items.map((route, i) => {
      if (location.pathname.includes(route.path)) {
        setHeaderTitle(intl.formatMessage({ id: route.headerTitle }));
      }
      const Component = route.component;
      return (
        <Route
          key={`/wiki${route.path}`}
          path={route.path}
          element={<Component />}
        />
      );
    });

    setItems(headerItems);
    setRoutes(routes);
  }, [location.pathname, hasContest, intl]);

  return (
    <div className="page-wrapper">
      <Section title={headerTitle} className="component-section">
        <>
          {' '}
          {items ? (
            <HeaderMenu
              className="header-menu-wrapper"
              defaultPath={`/wiki`}
              items={items}
            />
          ) : null}
          <div className={'component-wrapper'}>
            {isLoading && <SpinnerComponent />}
            <WikiContext.Provider value={contextValue}>
              <Routes>
                {routes}
                {hasContest &&
                  routes(
                    <Route
                      path="*"
                      element={
                        <Navigate
                          key="redirect-to/contest"
                          to="/wiki/contest"
                          replace
                        />
                      }
                    />
                  )}
              </Routes>
            </WikiContext.Provider>
          </div>
        </>
      </Section>
    </div>
  );
}

export default Wiki;
