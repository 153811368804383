export default {
  ONBOARDING_TITLE: `Onboarding Tour`,
  FIRST_STEPS_TITLE: `First steps`,
  ONBOARDING_HEADLINE_1: `The following information is required to activate your account. You can skip any of these steps and complete them later.`,
  ONBOARDING_HEADLINE_2: `<span class="bold">Please note:</span> Your account details will only be checked by our support as soon as you have completed the onboarding tour!`,
  ONBOARDING_REVIEW_HEADLINE_1: `Our support has identiefied a problem`,
  ONBOARDING_REVIEW_HEADLINE_2: `Our support has identiefied several problems`,
  ONBOARDING_HEADLINE_PENDING_1: `Congratulations! You finished the Onboarding Tour.`,
  ONBOARDING_HEADLINE_PENDING_2: `Your registration will now be <span class="bold">reviewed</span> by our support team.`,
  FINISHED_HEADLINE: `Here's what happens now`,
  FINISHED_TEXT_1: `Our support will notify you via email after your registration has been checked.`,
  FINISHED_TEXT_2: `Please make sure that you add the following email address to your contacts: <a class="bold" href="mailto:support@livecreator.com">support@livecreator.com</a><br />In this way you avoid our emails and your access data end up in your spam folder.`,
  HINT_NEED_HELP: `Do you need help?`,
  HINT_CONTACT: `Feel free to contact our`,
  HINT_CREATOR_SUPPORT: `Creator support`,
  ONBOARDING_ITEM_NICKNAME: `Nickname`,
  ONBOARDING_ITEM_PROFILE_PICTURE: `Profile picture`,
  ONBOARDING_ITEM_PROFILE_DETAILS: `Profile details`,
  ONBOARDING_ITEM_PERSONAL_DATA: `Private data`,
  ONBOARDING_ITEM_UPLOAD_DOCUMENTS: `Document upload`,
  ONBOARDING_ITEM_DOI: `Verify email`,

  BUTTON_BACK: `back`,
  BUTTON_NEXT: `next`,
  BUTTON_FINALISE: `finalize`,

  TOOLTIP_SUPPORT_HEADLINE: 'Hello!',
  TOOLTIP_SUPPORT: `<p>I'm Kathrin, your coach on LiveCreator.<br />
  If you have any questions about the onboarding process, most important steps, settings or anything related to your profile, I am available live in <span class="bold">Zoom</span></p>
  <ul class="bold">
    <li>on Tuesdays from 10 am to 11 am</li>
    <li>on Wednesday from 4 pm to 5 pm</li>
    <li>on Thursdays from 2 pm to 3 pm</li>
  </ul>
  <p>Just click on <a class="blue" href="https://us02web.zoom.us/j/89900156510?pwd=am11ME5VVnE4S3BlaWlFZHJCYjFJUT09" target="_blank">this link</a>.</p>
  Please note that the Zoom link only works during the time periods mentioned above.</p>
  <p>Best regards and good luck<br />
  Kathrin</p>`,

  TOOLTIP_NAVIGATION_FINALIZE_1: `Use the <span class="bold">"back"</span> and <span class="bold">"next"</span> buttons to save your entries and check each step again.`,
  TOOLTIP_NAVIGATION_FINALIZE_2: `Click <span class="bold">"finalize"</span> to submit your registration. Your data will be reviewed by our support team.`,
  TOOLTIP_NAVIGATION_FINALIZE_NEED_HELP: `Need help?`,
  TOOLTIP_NOW_COMPLETE: `Your entries are now complete`,

  CHOOSE_NICKNAME: `Choose your nickname`,
  CHOOSE_NICKNAME_SUB: `Your profile appears in all communities with your nickname.`,
  MY_NICKNAME: `My nickname:`,
  ONBOARDING_NICKNAME_CONTENT_1: `Tips for choosing your nickname:`,
  ONBOARDING_NICKNAME_CONTENT_2: `The nickname is your display name in all Community products. Your real name will never be displayed. The nickname should be memorable and not overly complicated. In terms of content, it can of course also reflect certain topics and inclinations of yours or simply be a fantasy name. Protected names, brands and products as well as illegal topics are not permitted.`,

  UPLOAD_PROFILE_PICTURE: `Profile picture upload`,
  PROFILE_PICTURE_CONTENT_1: `Upload an appealing picture of you for your LiveCreator profile. This is visible to all users!`,
  PROFILE_PICTURE_CONTENT_2: `Your profile picture must not be an erotic motif. You have to be fully clothed and recognizable as a person (no pure body parts and close ups)!<br />
<span class="bold">Important:</span> Images from the Internet are forbidden! No strangers on the profile pictures!`,
  PROFILE_PICTURE_CONTENT_3: `Profile images are allowed in jpg, jpeg or png format and require a minimum resolution of <span class="bold">at least 1080 x 1080 pixels</span>.`,
  PROFILE_PICTURE_CONTENT_4: `<span class="bold">Note:</span> The profile picture required for registration will not remain the only possible picture on your profile. You can later upload more, even more revealing and partial body photos, if you want.`,

  PROFILE_PICTURE_UPLOAD_ERROR: `Network error during update, please reload.`,

  PROFILE_DETAILS: `Important profile details`,
  PROFILE_DETAILS_SUB: `This information is visible to all users in your profile.`,
  SELECT_GENDER_DEFAULT: `Select a gender`,

  PERSONAL_DATA_SUB: `Please provide us with your real personal data here. These do <span class="bold">not</span> appear publicly!`,
  PERSONAL_DATA_DESCRIPTION_CONTENT_1: `<span class="bold">Please note:</span> All data you provide is of course subject to privacy policy.`,
  PERSONAL_DATA_DESCRIPTION_CONTENT_2: `So that we can ensure that you are of legal age and that you have actually registered as a creator, we compare your details during the account check with the authentication documents you uploaded. Of course, we also need this data later for the payment of your commissions.`,

  UPLOAD_DOCUMENTS_SUB: `So that we can ensure that you are of legal age and that you have actually registered as a creator please upload a copy of your identity card or passport including registration certificate here.`,
  UPLOAD_DOCUMENTS_CONTENT_2: `Please make sure that all documents are up-to-date and not expired! A driver's license is not a valid identification document! You can mark your identification documents as copies. However, all data and the ID photo must always be clearly visible!`,
  UPLOAD_DOCUMENTS_CONTENT_3: `Please upload both sides of your document!`,

  DOI_HEADLINE: `Almost there`,
  DOI_TITLE: `Verification of your email address`,
  DOI_DESCRIPTION_TITLE: `Welcome to LiveCreator`,
  DOI_DESCRIPTION_CONTENT_1: `Activate your account`,
  DOI_DESCRIPTION_CONTENT_2: `Please check your emails and click on the account verification link to activate your account.
  <br />If you don´t see an email in your Inbox, check your Junk Mail folder.`,
  DOI_DESCRIPTION_CONTENT_3: `<span class="bold">Please note:</span> Your email address is of course subject to privacy policy.`,
  EMAIL_CHANGE_REQUEST: `Have you made a mistake and want to {link}?`,
  EMAIL_CHANGE: `change the email address`,
  EMAIL_CHANGE_NOTIFICATION_1: `We will send a confirmation email to your new email address.`,
  EMAIL_CHANGE_NOTIFICATION_2: `Verify by clicking the link in this mail and {link} after confirmation.`,
  EMAIL_RESEND_1: `Didn't receive your confirmation email?`,
  EMAIL_RESEND_2: `{link} and we send it again.`,
  EMAIL_RELOAD_HERE: `reload here`,
  EMAIL_VERIFICATION_LABEL: `Your specified email:`,
  NEW_EMAIL: `New email:`,
  BUTTON_CHANGE: `Change`,
};

