import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import CreatorTagsDE from '../../../../../assets/img/wiki/wiki_creator_tags_de.jpg';
import CreatorTagsEN from '../../../../../assets/img/wiki/wiki_creator_tags_en.jpg';
import BackButton from '../../../../BackButton';

function CreatorTags({ locale, unconfirmed, nickname, onBackButtonClicked }) {
  const intl = useIntl();
  const account = nickname
    ? nickname
    : intl.formatMessage({ id: 'LINK_TEXT_ACCOUNT' });

  return (
    <div className="wiki-content creator-tags-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="CREATOR_TAGS_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="CREATOR_TAGS_CONTENT_1" />
      </p>
      <p>
        <FormattedMessage
          id="CREATOR_TAGS_CONTENT_1_1"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="LINK_TEXT_SETTINGS" values={{ account }} />
            ) : (
              <Link
                key="creator-tags-link-options"
                to="/my-account/settings/options"
              >
                <FormattedMessage
                  id="LINK_TEXT_SETTINGS"
                  values={{ account }}
                />
              </Link>
            ),
          }}
        />
      </p>

      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive border-light-gray"
            src={CreatorTagsEN}
            alt="Creator tags"
          />
        ) : (
          <img
            className="img-responsive border-light-gray"
            src={CreatorTagsDE}
            alt="Creator Tags"
          />
        )}
      </div>

      <p>
        <FormattedMessage id="CREATOR_TAGS_CONTENT_1_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}

export default CreatorTags;
