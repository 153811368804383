import _ from 'lodash';
import { Navigate, Route } from 'react-router-dom';

export function filterRouteProps(props) {
  return _.omit(props, [
    'title',
    'subtitle',
    'headline',
    'states',
    'componentNotAllowed',
  ]);
}

export function renderRoutes(routes, user, browser) {
  return routes
    .filter((r) => {
      if (user?.account && r.livecam) {
        return user?.account?.state === 'confirmed';
      }
      if (r.icebreaker) {
        return user?.account?.icebreaker !== 'blocked';
      }
      return true;
    })
    .map((r, index) => {
      //former StateRoute logic now included here
      const { component, componentNotAllowed, states, ...otherProbs } = r;

      const Component = component;
      const ComponentNotAllowed = componentNotAllowed;

      if (!user.isLoggedIn) {
        if (states.includes('unauthenticated')) {
          const routeProps = filterRouteProps(otherProbs);
          return <Route key={index} element={<Component />} {...routeProps} />;
        }
        return (
          <Route
            key={index}
            path="*"
            element={<Navigate to="/login" replace />}
          />
        );
      }
      if (states.includes(user.account.state)) {
        const FinalComponent =
          componentNotAllowed && !browser.isAllowed
            ? ComponentNotAllowed
            : Component;
        const routeProps = filterRouteProps(otherProbs);
        return (
          <Route key={index} element={<FinalComponent />} {...routeProps} />
        );
      } else {
        return (
          <Route key={index} path="*" element={<Navigate to="/" replace />} />
        );
      }
    });
}
