import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import BackButton from '../../../../BackButton';

function Profile({ unconfirmed, onBackButtonClicked }) {
  return (
    <div className="wiki-content profile-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="PROFILE_HEADLINE" />
      </h1>
      <p className="has-margin-bottom">
        <FormattedMessage
          id="PROFILE_INTRO"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="PROFILE_INTRO_LINK" />
            ) : (
              <Link
                key="profile-link-profile-pictures"
                to="/my-account/my-profile/profile-pictures"
              >
                <FormattedMessage id="PROFILE_INTRO_LINK" />
              </Link>
            ),
          }}
        />
      </p>

      <h3 className="headline">
        <FormattedMessage id="PROFILE_HEADLINE_2" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="PROFILE_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="PROFILE_CONTENT_STEP_1_CONTENT"
              values={{
                link: (
                  <Link
                    key="profile-link-profile-image"
                    to="/wiki/start#profileImage"
                  >
                    <FormattedMessage id="PROFILE_CONTENT_STEP_1_CONTENT_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="PROFILE_CONTENT_STEP_1_1_CONTENT" />
          </p>
          <p className="has-margin-bottom">
            <FormattedMessage id="PROFILE_CONTENT_STEP_1_2_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
        <div className="wiki-list-item">
          <div className="wiki-list-headline">
            <span className="number">2</span>
            <span className="bold">
              <FormattedMessage id="PROFILE_CONTENT_STEP_2" />
            </span>
          </div>
          <div className="wiki-list-content">
            <p>
              <FormattedMessage id="PROFILE_CONTENT_STEP_2_CONTENT" />
            </p>
            <p className="list-headline">
              <FormattedMessage id="PROFILE_CONTENT_STEP_2_1_CONTENT" />
            </p>
            <p>
              <FormattedMessage id="PROFILE_CONTENT_STEP_2_2_CONTENT">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </div>
        </div>
        <div className="wiki-list-item">
          <div className="wiki-list-headline">
            <span className="number">3</span>
            <span className="bold">
              <FormattedMessage id="PROFILE_CONTENT_STEP_3" />
            </span>
          </div>
          <div className="wiki-list-content">
            <p className="has-margin-bottom">
              <FormattedMessage id="PROFILE_CONTENT_STEP_3_CONTENT" />
            </p>
          </div>
        </div>
        <div className="wiki-list-item">
          <div className="wiki-list-headline">
            <span className="number">4</span>
            <span className="bold">
              <FormattedMessage id="PROFILE_CONTENT_STEP_4" />
            </span>
          </div>
          <div className="wiki-list-content">
            <p>
              <FormattedMessage id="PROFILE_CONTENT_STEP_4_CONTENT" />
            </p>
            <p className="has-margin-bottom">
              <FormattedMessage id="PROFILE_CONTENT_STEP_4_1_CONTENT" />
            </p>
          </div>
        </div>
        <div className="wiki-list-item">
          <div className="wiki-list-headline">
            <span className="number">5</span>
            <span className="bold">
              <FormattedMessage id="PROFILE_CONTENT_STEP_5" />
            </span>
          </div>
          <div className="wiki-list-content">
            <p>
              <FormattedMessage id="PROFILE_CONTENT_STEP_5_CONTENT" />
            </p>
            <p>
              <FormattedMessage id="PROFILE_CONTENT_STEP_5_1_CONTENT" />
            </p>
            <p>
              <FormattedMessage id="PROFILE_CONTENT_STEP_5_2_CONTENT" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
