import 'video.js/dist/video-js.css';

import './VideoPlayerItem.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import videojs from 'video.js';

import useMediaQuery from '../../hooks/useMediaQuery';

function VideoPlayerItem({
  data,
  autoplay,
  videoOverlayRef,
  poster,
  aspectRatio,
  fillOption,
}) {
  const standinRef = useRef(null);
  const videoRef = videoOverlayRef || standinRef;
  const playerRef = useRef(null);

  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const [isVideoReady, setIsVideoReady] = useState(false);

  const videoJsOptions = useMemo(() => {
    let sources = [];
    if (typeof data === 'object') {
      if (data?.hls?.url) {
        sources.push({
          type: data.hls.header,
          src: data.hls.url,
          engine: 'hlsjs',
        });
      }
      if (data?.dash?.url) {
        sources.push({
          type: data.dash.header,
          src: data.dash.url,
          engine: 'dash',
        });
      }
      if (data?.download) {
        sources.push({
          type: 'video/mp4',
          src: data.download,
        });
      }
    } else {
      sources = [{ src: data }];
    }
    return {
      autoplay: autoplay,
      controls: true,
      responsive: true,
      width: isLargeScreen ? 160 : 120,
      height: isLargeScreen ? 120 : 90,
      controlBar: {
        pictureInPictureToggle: false,
        volumePanel: {
          inline: false,
        },
      },
      sources,
    };
  }, [isLargeScreen, data, autoplay]);

  useEffect(() => {
    if (videoRef.current) {
      setIsVideoReady(true);
    }
  }, [videoRef]);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current && isVideoReady) {
      const videoElement = videoRef.current;
      if (videoElement) {
        playerRef.current = videojs(videoElement, videoJsOptions);
      }
    } else {
      // you can update player here [update player through props]
    }
  }, [isLargeScreen, videoJsOptions, videoRef, isVideoReady]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  if (!data) return null;

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className={`video-js vjs-big-play-centered	${
          aspectRatio ? aspectRatio : ''
        } ${fillOption ? fillOption : ''}`}
        poster={poster ? poster : null}
      />
    </div>
  );
}

export default VideoPlayerItem;
