import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SpinnerPage from '../../Spinner/SpinnerPage';

function TokenLogin({ userTokenLogin, grantType }) {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  useEffect(() => {
    const pathItems = pathname.split('/');
    if (pathItems[2]?.length && pathItems[3]?.length) {
      userTokenLogin(pathItems[2], pathItems[3], grantType, navigate);
    } else {
      navigate('/');
    }
  }, [userTokenLogin, pathname, grantType, navigate]);

  return <SpinnerPage />;
}

export default TokenLogin;
