import './Customers.scss';

import useAxios from 'axios-hooks';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import statusIcon from '../../../../assets/img/svg/on-offline.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { getPortal } from '../../../../utils/portals';
import Button from '../../../Button/Button';
import Select from '../../../Forms/Select/Select';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import DateItem from '../../../Tables/TableItems/DateItem';
import {
  fullCustomersTableTitles,
  getMonthOptions,
  getYearOptions,
  mediumCustomersTableTitles,
  mobileCustomersTableTitles,
} from './Customers.functions';

function Customers({ openDialog, online }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isMediumScreen = useMediaQuery('(min-width: 1024px)');
  const currentYear = new Date().getFullYear().toString();
  const currentMonth = new Date().getMonth() + 1;

  const [dateValue, setDateValue] = useState(
    `${currentYear}-${
      currentMonth > 9 ? currentMonth.toString() : `0${currentMonth}`
    }-01`
  );
  const [portalIds, setPortalIds] = useState([]);
  const [portalNames, setPortalNames] = useState();
  const [monthFilter, setMonthFilter] = useState(
    (new Date().getMonth() + 1).toString()
  );
  const [yearFilter, setYearFilter] = useState(
    new Date().getFullYear().toString()
  );

  const months = getMonthOptions();

  const [monthShown, setMonthShown] = useState(
    `${intl.formatMessage({ id: months[monthFilter].label })} ${
      yearFilter === '0' ? '' : yearFilter
    }`
  );

  const [topCustomers, setTopCustomers] = useState(null);
  const [{ loading }, topCustomersReq] = useAxios(
    {
      url: `/user/customers/top${dateValue ? `?date=${dateValue}` : ''}`,
    },
    { manual: true }
  );

  const changeYearFilter = (year) => {
    setYearFilter(year);
    const monthOptions = getMonthOptions(year);
    if (monthOptions[monthFilter].disabled) {
      setMonthFilter((new Date().getMonth() + 1).toString());
    }
  };

  useEffect(() => {
    let isMounted = true;

    topCustomersReq()
      .then((response) => {
        if (isMounted) {
          setPortalIds(
            response?.data?.data.map((item) => item.user.split('-')[0])
          );
          setTopCustomers(response?.data?.data);
        }
      })
      .catch((error) => {
        isMounted && console.log(error);
      });
    return () => (isMounted = false);
  }, [topCustomersReq]);

  const translatedTitles = isLargeScreen
    ? fullCustomersTableTitles
    : isMediumScreen
    ? mediumCustomersTableTitles
    : mobileCustomersTableTitles;

  const applyDateFilter = () => {
    setDateValue(
      `${yearFilter}-${monthFilter.length > 1 ? '' : '0'}${monthFilter}-01`
    );
    setMonthShown(
      `${intl.formatMessage({ id: months[monthFilter].label })} ${
        yearFilter === '0' ? '' : yearFilter
      }`
    );
  };

  const clearDataFilter = () => {
    setDateValue(null);
    setMonthFilter('0');
    setYearFilter('0');
    setMonthShown(intl.formatMessage({ id: 'CUSTOMERS_TOTAL_PERIOD' }));
  };

  useEffect(() => {
    let isMounted = true;

    async function portals() {
      let array1 = [];
      for (let i = 0; i < portalIds.length; i++) {
        const response = await getPortal(portalIds[i]);
        isMounted && array1.push(response);
      }
      isMounted && setPortalNames(array1);
    }

    portals();
    return () => (isMounted = false);
  }, [portalIds]);

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="stats-wrapper customers-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <div className="filter-wrapper">
              <div className="select-wrapper">
                <Formik>
                  <Field
                    as={Select}
                    name="month"
                    className="month"
                    intlTranslate={true}
                    value={monthFilter}
                    options={getMonthOptions(yearFilter)}
                    onChange={(e) => setMonthFilter(e.target.value)}
                  />
                </Formik>
                <Formik>
                  <Field
                    as={Select}
                    name="year"
                    className="year"
                    value={yearFilter}
                    intlTranslate={false}
                    options={getYearOptions(intl)}
                    onChange={(e) => changeYearFilter(e.target.value)}
                  />
                </Formik>
              </div>
              <Button
                onClick={applyDateFilter}
                variant="primary"
                label="LABEL_SHOW"
                disabled={monthFilter === '0' || yearFilter === '0'}
              />
              <Button
                onClick={clearDataFilter}
                variant="dark"
                label="btn.title.allTime"
                disabled={monthFilter === '0' || yearFilter === '0'}
              />
            </div>

            <div className="table-box">
              <h1 className="headline">
                <FormattedMessage id="HEADLINE_MY_BEST_CUSTOMERS" />
                {monthFilter === '0'
                  ? intl.formatMessage({ id: 'CUSTOMERS_TOTAL_PERIOD' })
                  : monthShown}{' '}
              </h1>

              {loading ? (
                <SpinnerComponent />
              ) : (
                <table className="my-stats noHover">
                  <thead>
                    <tr>
                      {translatedTitles.map((title, index) => (
                        <th
                          style={{
                            width: title.width,
                            textAlign: title.position,
                          }}
                          key={`${title}.${index}`}
                        >
                          <FormattedMessage id={title.title} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {topCustomers?.map((item, index) => {
                      let isOnline = online?.includes(
                        item?.user?.replace('-', '@')
                      )
                        ? 'online'
                        : 'offline';
                      return (
                        <tr key={`${item.key}.${index}`}>
                          {isLargeScreen && <td>{item.number}</td>}

                          <td className={`user ${isOnline}`}>
                            <ReactSVG src={statusIcon} wrapper="span" />
                            <Link
                              onClick={() =>
                                openDialog(item.user, item.nickname)
                              }
                              to="/multi-messenger"
                            >
                              {item.nickname}
                            </Link>
                          </td>
                          {isLargeScreen && (
                            <td>{portalNames[index]?.whitelabelShort}</td>
                          )}
                          {isMediumScreen && (
                            <>
                              <td>
                                {item?.country === null ||
                                item?.country === '-' ||
                                item?.country === ''
                                  ? ''
                                  : intl.formatMessage({
                                      id: `${item.country}`,
                                    })}
                              </td>
                              <td>
                                <DateItem data={item?.last_buy} />
                              </td>
                            </>
                          )}

                          <td className="text-right">
                            {item.coins} {intl.formatMessage({ id: 'COINS' })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          hasBoxShadow={true}
          containsHeader={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="STATS_INFO_HEADER" />
            </h2>
            <p>{intl.formatMessage({ id: 'STATS_TOP_CUSTOMERS' })}</p>
            <p>{intl.formatMessage({ id: 'STATS_PERFORMANCE' })}</p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Customers;
