import './BillingPeriod.scss';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { spinnerRequest } from '../../../../shared/spinnerRequest';
import NumberSelect from '../../../Forms/NumberSelect/NumberSelect';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import ArticlesCoinsTable from '../../../Tables/ArticlesCoinsTable';
import ArticlesExtrasTable from '../../../Tables/ArticlesExtrasTable';
import ModelsTableWrapper from '../../../Tables/ModelsTable';
import { euPrices, extPrices, getModelsItems } from './BillingPeriod.functions';

function BillingPeriod({
  user,
  minimum_payout,
  bank_location,
  language,
  spinner = () => {},
  update,
}) {
  const intl = useIntl();

  const [{ data: billingPeriodData, loading: billingPeriodLoading }] = useAxios(
    {
      url: '/user/current-billing',
    }
  );

  const [updatingMinimumPayout, setUpdatingMinimumPayout] = useState(false);

  const [, minimumPayoutRequest] = useAxios(
    {
      url: '/user/minimum-payout',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const updateMinimumPayout = (value) => {
    const payload = {
      minimum_payout: value,
      type: bank_location,
    };

    return spinnerRequest({
      request: minimumPayoutRequest,
      spinner,
      payload: { data: payload },
    })
      .then(() => {
        update({
          private: { ...user.private, minimum_payout: parseInt(value) },
        });
        setUpdatingMinimumPayout(false);
      })
      .catch(() => {});
  };

  return (
    <div className="billing-period-wrapper">
      <div className="table-box">
        {billingPeriodLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <h1 className="headline">
              {dayjs(billingPeriodData?.period_start).format(
                language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY'
              )}{' '}
              <FormattedMessage id="UNTIL" />{' '}
              {dayjs(billingPeriodData?.period_end).format(
                language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY'
              )}
            </h1>

            {billingPeriodData?.articles ? (
              <div className="article-coins-table">
                <ArticlesCoinsTable
                  items={Object.values(billingPeriodData.articles.coin)}
                  dateFormat={'DD/MM/YYYY'}
                  results={[
                    {
                      data: intl.formatMessage({ id: 'TOTAL_TURNOVER' }),
                      width: '50%',
                    },
                    { data: billingPeriodData?.coin_total, width: '50%' },
                  ]}
                  location={'billing-period'}
                  linksAvailable={true}
                />
              </div>
            ) : null}
          </>
        )}
      </div>

      {billingPeriodData?.provision_model ? (
        <div className="models-table">
          <div className="table-box">
            <ModelsTableWrapper
              items={getModelsItems(billingPeriodData.provision_model)}
              precision={2}
              currency={billingPeriodData?.currency}
            />
          </div>
        </div>
      ) : null}

      {billingPeriodData?.articles ? (
        <div className="table-box">
          <div className="article-extras-table">
            <h2 className="headline">
              {intl.formatMessage({ id: 'BILLING_PERIOD_CURRENT_CREDITS' })}
            </h2>
            <ArticlesExtrasTable
              items={Object.values(billingPeriodData.articles.extra)}
              dateFormat={'DD/MM/YYYY'}
              results={[
                { data: intl.formatMessage({ id: 'CREDITS' }), width: '50%' },
                { data: billingPeriodData?.revenue_total, width: '50%' },
              ]}
              additionalInfo={{
                turnover: billingPeriodData?.coin_total,
                share: billingPeriodData?.affected_rate,
                premium: billingPeriodData?.affected_revenue,
              }}
              precision={2}
              currency={billingPeriodData?.currency}
              location={'billing-period'}
            />
          </div>
        </div>
      ) : null}
      {bank_location !== 'none' ? (
        <div className="payout-info">
          {intl.formatMessage(
            {
              id: 'BILLING_PERIOD_PAYOUT_INFO',
            },
            {
              end: (
                <span className="bold" key="unique-payout-info">
                  {dayjs(billingPeriodData?.period_end)
                    .add(1, 'day')
                    .format(language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY')}
                </span>
              ),
            }
          )}
          {updatingMinimumPayout ? (
            <NumberSelect
              options={bank_location === 'eu' ? euPrices : extPrices}
              value={minimum_payout || ''}
              name="minimum_payout"
              onChange={(event) => updateMinimumPayout(event.target.value)}
              inline={true}
            />
          ) : (
            <span className="bold">{minimum_payout} &euro;</span>
          )}
          <span>
            {intl.formatMessage({ id: 'BILLING_PERIOD_PAYOUT_INFO_2' })}&nbsp;
          </span>
          {!updatingMinimumPayout ? (
            <span
              onClick={() => setUpdatingMinimumPayout(true)}
              className="change-payout-link"
            >
              {/* Do not remove the () otherwise google translate does not work anymore*/}
              ({intl.formatMessage({ id: 'LABEL_CHANGE' })})
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default BillingPeriod;
