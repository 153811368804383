export function generateDropdownOptions(data, context) {
  const options = data.map((d) => {
    const label = context ? `${context}_${d}` : d;
    return { label, value: d };
  });

  options.unshift({ label: 'not_available', value: '' });
  return options;
}

export const lookingForLabels = [
  { label: 'woman', value: 'woman' },
  { label: 'man', value: 'man' },
  { label: 'couple', value: 'couple' },
  { label: 'a_transvestite', value: 'transvestite' },
  { label: 'a_transsexual', value: 'transsexual' },
  { label: 'bisexual', value: 'bisexual' },
];

export const experienceLabels = [
  { label: 'escapade', value: 'escapade' },
  { label: 'relationship', value: 'relationship' },
  { label: 'flirt', value: 'flirt' },
  { label: 'emailsex', value: 'emailsex' },
  { label: 'affair', value: 'affair' },
  { label: 'ons', value: 'ons' },
  { label: 'frivolous_friendship', value: 'frivolous_friendship' },
  { label: 'fetish_erotic', value: 'fetish' },
  { label: 'bdsm', value: 'bdsm' },
  { label: 'groupsex', value: 'groupsex' },
];

export const preferenceLabels = [
  { label: 'flowerpower', value: 'flowerpower' },
  { label: 'analsex', value: 'analsex' },
  { label: 'oralsex', value: 'oralsex' },
  { label: 'submissive', value: 'submissive' },
  { label: 'dominant', value: 'dominant' },
  { label: 'toys', value: 'toys' },
  { label: 'swinging', value: 'swinging' },
  { label: 'outdoorsex', value: 'outdoorsex' },
  { label: 'publicsex', value: 'publicsex' },
  { label: 'dessous', value: 'dessous' },
  { label: 'rpg', value: 'rpg' },
  { label: 'voyeurism', value: 'voyeurism' },
  { label: 'bdsm', value: 'bdsm' },
];
