import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import BackButton from '../../../../BackButton';

function PersonalMessages({ onBackButtonClicked }) {
  return (
    <div className="wiki-content personal-messages-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="PERSONAL_MESSAGES_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="PERSONAL_MESSAGES_INTRO" />
      </p>

      <h3 className="headline">
        <FormattedMessage id="PERSONAL_MESSAGES_HEADLINE_1" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="PERSONAL_MESSAGES_CONTENT_STEP_1_CONTENT"
              values={{
                link: (
                  <Link
                    key="personal-link-user-infocard"
                    to="/wiki/general#userInfocard"
                  >
                    <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_1_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_2_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_4_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">5</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_5" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_5_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">6</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_6" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="PERSONAL_MESSAGES_CONTENT_STEP_6_CONTENT"
              values={{
                link: (
                  <Link
                    key="personal-link-authenticity"
                    to="/wiki/general#authenticity"
                  >
                    <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_6_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">7</span>
          <span className="bold">
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_7" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PERSONAL_MESSAGES_CONTENT_STEP_7_CONTENT" />
          </p>
        </div>
      </div>
      <p>
        <FormattedMessage id="VISIBILITY_OUTRO" />
      </p>
    </div>
  );
}

export default PersonalMessages;
