import './GalleryInfo.scss';

import useAxios from 'axios-hooks';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { COMMENTS_PAGINATION_SIZE } from '../../../constants';
import useButtonChange from '../../../hooks/useButtonChange';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import ButtonFieldWithErrors from './../../Forms/ButtonFieldWithErrors';
import FieldWithErrors from './../../Forms/FieldWithErrors/FieldWithErrors';
import Checkbox from '../../Forms/Checkbox/Checkbox';
import ChosenDocumentField from '../../Forms/ChosenDocumentField/ChosenDocumentField';
import Input from '../../Forms/Input/Input';
import RequestSelect from '../../Forms/RequestSelect';
import ToggleButton from '../../Forms/ToggleButton/ToggleButton';
import GalleryComment from '../../GalleryComment/GalleryComment';
import Pagination from '../../Pagination/Pagination';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import {
  generateCategoriesOptions,
  generateDocumentsOptions,
} from './GalleryInfo.functions';

function GalleryInfo({
  activeGallery,
  language,
  updateActiveGallery,
  spinner,
}) {
  const intl = useIntl();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const isMediumScreen = useMediaQuery('(max-width: 1599px)');

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const queryParameterId = queryParameters.get('id');

  const isVideoGallery = location.pathname.includes('videos');

  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const initialItems = useRef(null);
  const [items, setItems] = useState([]);

  const [isGalleryFree, setIsGalleryFree] = useState(null);
  const [standardDocumentSelected, setStandardDocumentSelected] =
    useState(false);
  const [otherDocumentsSelected, setOtherDocumentsSelected] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const settingStandardDocument = useRef(false);
  const settingOtherDocument = useRef(false);

  const { values } = useFormikContext();

  const [{ data: categoriesData }] = useAxios({
    url: '/data/categories',
  });

  const [{ data: documentsData }] = useAxios({
    url: `/galleries/${id}/document`,
  });

  const [, updateGalleryRequest] = useAxios(
    {
      url: `/galleries/${id}`,
      method: 'PUT',
    },
    { manual: true }
  );

  const [, updateDocumentsRequest] = useAxios(
    {
      url: `/galleries/${id}/document`,
      method: 'PUT',
    },
    { manual: true }
  );

  const [, deleteDocumentRequest] = useAxios(
    {
      url: `galleries/${id}/document`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const baseChangeOpts = {
    noUpdateField: true,
    requestUrl: `/galleries/${id}`,
    update: updateActiveGallery,
    spinner,
  };
  const updateOptions = {
    title: useButtonChange({
      ...baseChangeOpts,
      name: 'title',
      method: 'PUT',
    }),
    description: useButtonChange({
      ...baseChangeOpts,
      name: 'description',
      method: 'PUT',
    }),
    free: useButtonChange({
      ...baseChangeOpts,
      name: 'free',
      method: 'PUT',
    }),
    price: useButtonChange({
      ...baseChangeOpts,
      name: 'price',
      method: 'PUT',
    }),
    category: useButtonChange({
      ...baseChangeOpts,
      name: 'categories',
      method: 'PUT',
    }),
  };

  useEffect(() => {
    if (activeGallery) {
      setIsGalleryFree(!activeGallery.price);
    }
  }, [activeGallery]);

  useEffect(() => {
    if (activeGallery?.comments?.length) {
      let items = activeGallery?.comments;
      initialItems.current = items;
      if (initialItems.current.length > COMMENTS_PAGINATION_SIZE) {
        items = items.slice(0, COMMENTS_PAGINATION_SIZE);
      }
      setItems([...items]);
    }
  }, [activeGallery?.comments]);

  const scrollBottom = () => {
    setTimeout(() => {
      if (isMediumScreen) {
        const parentElement =
          document.getElementsByClassName('component-wrapper')[0];
        const element = parentElement
          .querySelector('.ons-scroll')
          .querySelector('.box');
        element.scrollTop = element.scrollHeight;
      } else {
        const parentElement = document.getElementsByClassName(
          isVideoGallery ? 'videos-wrapper' : 'photos-wrapper'
        )[0];
        const element = parentElement
          .querySelectorAll('.ons-scroll')[1]
          .querySelector('.box');
        element.scrollTop = element.scrollHeight;
      }
    }, 0);
  };

  const lastCategories = useSelector(
    (state) => state.galleries?.lastActive?.categories
  );

  const newCategories = useSelector(
    (state) => state.galleries?.active?.categories
  );

  const getNewCategoryIndex = () => {
    if (!newCategories || !lastCategories) {
      return;
    }

    if (newCategories?.length < lastCategories?.length) {
      return;
    }

    if (newCategories?.length > lastCategories?.length) {
      return newCategories.length - 1;
    }

    for (let i = 0; i < newCategories.length; i++) {
      if (newCategories[i] !== lastCategories[i]) {
        return i;
      }
    }
  };

  useEffect(() => {
    if (activeGallery) {
      if (activeGallery.document_state === 0) {
        !settingOtherDocument.current && setOtherDocumentsSelected(false);
        !settingStandardDocument.current && setStandardDocumentSelected(false);
      }
      if (
        (activeGallery.document_state === 1 ||
          activeGallery.document_state === 3) &&
        !settingStandardDocument.current
      ) {
        setStandardDocumentSelected(true);
      }

      if (
        (activeGallery.document_state === 2 ||
          activeGallery.document_state === 3) &&
        !settingOtherDocument.current
      ) {
        setOtherDocumentsSelected(true);
      }
    }
  }, [activeGallery, activeGallery?.document_state]);

  const filteredFsk = activeGallery?.pictures
    ? activeGallery?.pictures?.filter((picture) => picture.fsk === 'fsk18')
    : activeGallery?.movies
    ? activeGallery?.movies?.filter((movie) => movie?.fsk === 'fsk18')
    : [];
  const thumbnailFsk = activeGallery?.thumbnail?.fsk === 'fsk18';

  useEffect(() => {
    if ((filteredFsk?.length || thumbnailFsk) && !(activeGallery?.price > 0)) {
      const price = 1;
      const payload = {
        price,
      };
      spinnerRequest({
        request: updateGalleryRequest,
        spinner,
        payload: {
          data: payload,
        },
      }).then((response) => {
        setIsGalleryFree(!response.data.price);
        updateActiveGallery(response.data);
      });
    }
  }, [
    filteredFsk?.length,
    spinner,
    updateActiveGallery,
    updateGalleryRequest,
    thumbnailFsk,
    activeGallery?.price,
  ]);

  const togglePriceHandler = () => {
    const price = isGalleryFree ? 1 : 0;
    const payload = {
      price,
    };
    spinnerRequest({
      request: updateGalleryRequest,
      spinner,
      payload: {
        data: payload,
      },
    }).then((response) => {
      setIsGalleryFree(!response.data.price);
      updateActiveGallery(response.data);
    });
  };

  const updateDocumentsHandler = (value) => {
    const payload = {
      documents: [value],
    };
    return spinnerRequest({
      request: updateDocumentsRequest,
      spinner,
      payload: {
        data: payload,
      },
    }).then(() => {
      const newDocuments = [...activeGallery.documents];

      const newDocument =
        value === 'main'
          ? documentsData.find((element) => element.mode === 'main')
          : documentsData.find((element) => element.id === value);

      newDocuments.push({
        id: newDocument.id,
        description: newDocument.description,
        mode: newDocument.mode,
      });

      updateActiveGallery({
        ...activeGallery,
        documents: newDocuments,
      });
    });
  };

  const addingAdditionalDocument = activeGallery?.documents?.filter(
    (document) => {
      const currentDocument = documentsData?.find((d) => d.id === document.id);
      return currentDocument?.mode !== 'main';
    }
  );
  const deleteDocument = (value) => {
    const payload = {
      documents: [value],
    };
    spinnerRequest({
      request: deleteDocumentRequest,
      spinner,
      payload: {
        data: payload,
      },
    }).then(() => {
      const newDocuments = activeGallery.documents.filter((document) => {
        return value === 'main'
          ? document.mode !== 'main'
          : value === 'actor'
          ? document.mode === 'main'
          : document.id !== value;
      });
      updateActiveGallery({
        ...activeGallery,
        documents: newDocuments || [],
      });
    });
  };

  const standardDocumentHandler = () => {
    settingStandardDocument.current = true;
    if (standardDocumentSelected) {
      deleteDocument('main');
      setStandardDocumentSelected(false);
    } else {
      updateDocumentsHandler('main');
      setStandardDocumentSelected(true);
    }
  };

  const otherDocumentsHandler = () => {
    settingOtherDocument.current = true;
    if (otherDocumentsSelected) {
      deleteDocument('actor');
      setOtherDocumentsSelected(false);
    } else {
      setOtherDocumentsSelected(true);
      setTimeout(() => {
        const element = document.getElementById('data-actors-document');
        element.scrollIntoView(false);
      }, 200);
    }
  };
  const throwError =
    otherDocumentsSelected && addingAdditionalDocument.length === 0;
  const [isThrowErrorUpdated, setIsThrowErrorUpdated] = useState(false);

  useEffect(() => {
    if (isThrowErrorUpdated) {
      updateActiveGallery({
        ...activeGallery,
        throwError: throwError,
      });
      setIsThrowErrorUpdated(false);
    }
  }, [throwError, activeGallery, updateActiveGallery, isThrowErrorUpdated]);

  useEffect(() => {
    setIsThrowErrorUpdated(true);
  }, [throwError, otherDocumentsSelected]);

  useEffect(() => {
    setIsLoaded(queryParameterId === activeGallery?.id?.toString());
  }, [queryParameterId, activeGallery?.id]);
  return !isLoaded ? (
    <SpinnerComponent />
  ) : (
    <div className="gallery-info-wrapper data-columns">
      <>
        {' '}
        <div className="data-text data-left">
          <h2 className="headline">
            <FormattedMessage id="GALLERY_TITLE_DESCRIPTION" />
          </h2>
          <ButtonFieldWithErrors
            as={Input}
            name="title"
            label="TITLE_LABEL"
            maxLength="64"
            updateOptions={updateOptions['title']}
          />
          <div className="descriptions-wrapper">
            <ButtonFieldWithErrors
              as="textarea"
              textarea={true}
              name="description"
              label="DESCRIPTION_LABEL"
              updateOptions={updateOptions['description']}
              maxLength="1000"
              placeholder={intl.formatMessage({ id: 'MIN_32_CHARS' })}
            />
            <div className="form-group">
              <span className="column" />
              <p className="textarea-length-indicator">
                <FormattedMessage id="REMAINING_CHARS" />:{' '}
                {1000 - values.description.length}
              </p>
            </div>
          </div>
        </div>
        <div className="data-price data-right">
          <h3 className="headline">
            <FormattedMessage id="GALLERY_PRICE" />
          </h3>
          <div className="price-toggle-button">
            <ToggleButton
              data={isGalleryFree}
              click={() => togglePriceHandler()}
              label="FREE_CONTENT"
              name="free"
              disabled={filteredFsk?.length !== 0 || thumbnailFsk}
            />
          </div>
          <div className="coins-per-item-wrapper">
            <ButtonFieldWithErrors
              as={Input}
              name="price"
              label={isVideoGallery ? 'COINS_PER_VIDEO' : 'COINS_PER_PHOTO'}
              updateOptions={updateOptions['price']}
              small={true}
              width={80}
              additionalLabel={intl.formatMessage({
                id: isVideoGallery
                  ? 'VIDEO_PRICE_ADDITIONAL_LABEL_1'
                  : 'PHOTO_PRICE_ADDITIONAL_LABEL_1',
              })}
              subtextLabel={intl.formatMessage({
                id: isVideoGallery
                  ? 'VIDEO_PRICE_ADDITIONAL_LABEL_2'
                  : 'PHOTO_PRICE_ADDITIONAL_LABEL_2',
              })}
              disabled={!activeGallery?.price || isGalleryFree}
              outsideAdditionalLabels={isMediumScreen}
              invalidDataLabel="INVALID"
            />

            <span className="outside-additional-labels">
              <span className="additional-label">
                {intl.formatMessage({
                  id: isVideoGallery
                    ? 'VIDEO_PRICE_ADDITIONAL_LABEL_1'
                    : 'PHOTO_PRICE_ADDITIONAL_LABEL_1',
                })}
              </span>
              <span className="subtext-label">
                {intl.formatMessage({
                  id: isVideoGallery
                    ? 'VIDEO_PRICE_ADDITIONAL_LABEL_2'
                    : 'PHOTO_PRICE_ADDITIONAL_LABEL_2',
                })}
              </span>
            </span>
          </div>
        </div>
        <div className="data-category data-left">
          <h3 className="headline">
            <FormattedMessage id="GALLERY_ASSIGNED_CATEGORIES" />
          </h3>
          <p className="subtext">
            <FormattedMessage id="GALLERY_CATEGORY_WARNING" />
          </p>
          <div className="categories-list">
            {categoriesData && activeGallery && activeGallery?.categories && (
              <>
                {activeGallery.categories.map((category, index) => {
                  let options = generateCategoriesOptions(
                    intl,
                    categoriesData,
                    activeGallery.categories.filter((cat) => cat !== category)
                  );

                  if (index === 0) {
                    options.shift();
                  }

                  return (
                    <FieldWithErrors
                      key={`category-${index}`}
                      as={RequestSelect}
                      name="categories"
                      value={category}
                      options={options}
                      label={`GALLERY_CATEGORY_${index + 1}`}
                      updateOptions={updateOptions['category']}
                      existingData={activeGallery.categories}
                      index={index}
                      initIsSaved={index === getNewCategoryIndex()}
                      galleryInfo={true}
                      intlTranslate={false}
                      trimData={true}
                      deleteButton={activeGallery.categories.length > 1}
                    />
                  );
                })}
                {activeGallery.categories.length < 5 && (
                  <FieldWithErrors
                    key={`category-${activeGallery.categories.length}`}
                    as={RequestSelect}
                    name="categories"
                    value={''}
                    options={generateCategoriesOptions(
                      intl,
                      categoriesData,
                      activeGallery.categories
                    )}
                    label={`GALLERY_CATEGORY_${
                      activeGallery.categories.length + 1
                    }`}
                    updateOptions={updateOptions['category']}
                    galleryInfo={true}
                    existingData={activeGallery.categories}
                    index={activeGallery.categories.length}
                    intlTranslate={false}
                    trimData={true}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="data-actors-document data-right"
          id="data-actors-document"
        >
          <h3 className="headline">
            <FormattedMessage id="GALLERY_ACTORS_DOCUMENTS" />
          </h3>
          <p className="subtext">
            <FormattedMessage id="GALLERY_ACTORS_DOCUMENTS_TEXT" />
            <br />
            <Link to="/my-account/documents">
              <FormattedMessage id="GALLERY_ACTORS_DOCUMENTS_LINK" />
            </Link>
          </p>
          <Field
            as={Checkbox}
            id={'no-documents-checkbox'}
            htmlFor={'no-documents-checkbox'}
            label={intl.formatMessage({
              id: 'GALLERY_ACTORS_DOCUMENTS_CHECKBOX_1',
            })}
            checked={standardDocumentSelected}
            name="gallery_documents"
            intlTranslate={false}
            onChange={() => standardDocumentHandler()}
          />
          <Field
            as={Checkbox}
            id={'documents-checkbox'}
            htmlFor={'documents-checkbox'}
            label={intl.formatMessage({
              id: 'GALLERY_ACTORS_DOCUMENTS_CHECKBOX_2',
            })}
            checked={otherDocumentsSelected}
            name="gallery_documents"
            intlTranslate={false}
            onChange={() => otherDocumentsHandler()}
          />

          {otherDocumentsSelected && documentsData && (
            <div className="documents-list" id="documents-list">
              {activeGallery && (
                <>
                  {addingAdditionalDocument.map((document, index) => (
                    <ChosenDocumentField
                      id={document.id}
                      galleryId={activeGallery.id}
                      key={`document-${index}`}
                      name="documents"
                      label={'GALLERY_ACTOR_DOCUMENT'}
                      value={document.description}
                      spinner={spinner}
                      updateActiveGallery={updateActiveGallery}
                      activeGallery={activeGallery}
                      oneDocumentNeeded={activeGallery.documents?.length > 1}
                    />
                  ))}
                  {documentsData && (
                    <FieldWithErrors
                      key={`document-${activeGallery.categories.length}`}
                      as={RequestSelect}
                      name="documents"
                      value={''}
                      options={generateDocumentsOptions(
                        intl,
                        documentsData,
                        activeGallery.documents
                      )}
                      label={'GALLERY_ASSIGN_DOCUMENT'}
                      updateOptions={{ change: null, isDataLoading: null }}
                      outsideChangeHandler={updateDocumentsHandler}
                      existingData={activeGallery.documents}
                      index={activeGallery.documents.length}
                      intlTranslate={false}
                      noIsSaved={true}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {!!activeGallery?.comments?.length && (
          <>
            <div className="data-comments">
              <h3 className="headline">
                <FormattedMessage id="COMMENTS" />
              </h3>
              <div className="comments-wrapper">
                {items.map((comment, index) => (
                  <GalleryComment
                    key={`gallery-comment-${index}`}
                    comment={comment}
                    language={language}
                    galleryId={activeGallery?.id}
                    spinner={spinner}
                    activeGallery={activeGallery}
                    updateActiveGallery={updateActiveGallery}
                  />
                ))}
                <Pagination
                  offset={offset}
                  totalItems={activeGallery?.comments?.length}
                  pageNumber={pageNumber}
                  setOffset={setOffset}
                  setPageNumber={setPageNumber}
                  initialItems={initialItems.current}
                  setItems={setItems}
                  paginationSize={COMMENTS_PAGINATION_SIZE}
                  onPageChange={scrollBottom}
                />
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default GalleryInfo;
