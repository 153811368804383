import './Sales.scss';

import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import useAxios from 'axios-hooks';
import { Field, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import camImg from '../../../../assets/img/stats/camshows.png';
import coinImg from '../../../../assets/img/stats/coins.png';
import customerImg from '../../../../assets/img/stats/customer.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Button from '../../../Button/Button';
import Select from '../../../Forms/Select/Select';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import {
  getDaysOptions,
  getMonthOptions,
  getYearOptions,
  monthFilterToIntlId,
  shortMonthNames,
} from './Sales.functions';

function Sales({ language }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const currentYear = new Date().getFullYear().toString();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate().toString();

  const [monthFilter, setMonthFilter] = useState(
    (new Date().getMonth() + 1).toString()
  );
  const [yearFilter, setYearFilter] = useState(
    new Date().getFullYear().toString()
  );
  const [days, setDays] = useState([]);
  const [totalCoins, setTotalCoins] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);
  const [totalCamShows, setTotalCamShows] = useState(null);
  const [billingPeriodClicked, setBillingPeriodClicked] = useState(false);
  const [billingPeriodDays, setBillingPeriodDays] = useState([]);
  const [statsSales, setStatsSales] = useState(null);
  const [statsSalesTotal, setStatsSalesTotal] = useState(null);
  const [dayFilter, setDayFilter] = useState('false');
  const [activeBillingPeriodButton, setActiveBillingPeriodButton] =
    useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const isDay = yearFilter && monthFilter && dayFilter !== 'false';
  const isMonth =
    yearFilter && monthFilter !== 'false' && dayFilter === 'false';
  const isYear = yearFilter && monthFilter === 'false' && dayFilter === 'false';

  const statsURL = isDay
    ? `/user/stats/sales/${yearFilter}/${monthFilter}/day/${dayFilter}`
    : isMonth
    ? `/user/stats/sales/${yearFilter}/${monthFilter}/period`
    : isYear
    ? `/user/stats/sales/${yearFilter}`
    : `/user/stats/sales/${yearFilter}`;

  const [{ loading }, salesReq] = useAxios(
    {
      url: statsURL,
    },
    { manual: true }
  );

  const changeYearFilter = (year) => {
    setYearFilter(year);
    const monthOptions = getMonthOptions(year);
    if (monthOptions[monthFilter]?.disabled) {
      setMonthFilter((new Date().getMonth() + 1).toString());
    }
  };
  const changeMonthFilter = (month) => {
    setMonthFilter(month);
    if (month === 'false') {
      setDayFilter('false');
    }
  };

  const changeDayFilter = (day) => {
    setDayFilter(day);
  };

  const fetchSales = useCallback(async () => {
    const response = await salesReq();
    if (isMounted.current) {
      setStatsSalesTotal(response?.data);
      setTotalCamShows(response?.data?.cam_shows);
      setTotalCoins(response?.data?.total_coins);
      setTotalCustomers(response?.data?.customers);
      setStatsSales(response.data.data.filtered);
      setBillingPeriodClicked(false);
      setActiveBillingPeriodButton(null);
    }
  }, [salesReq]);

  useEffect(() => {
    const numDays = new Date(yearFilter, monthFilter, 0).getDate();
    setDays(Array.from({ length: numDays }, (_, i) => i + 1));
  }, [yearFilter, monthFilter, isMonth, isDay, isYear, dayFilter, statsSales]);

  useEffect(() => {
    fetchSales().catch((reason) => {
      console.error(reason);
    });
  }, [fetchSales]);

  const showBillingPeriod = (period) => {
    setBillingPeriodClicked(true);
    setTotalCamShows(statsSalesTotal.data.periods[period].cam_shows);
    setTotalCoins(statsSalesTotal.data.periods[period].coins);
    setTotalCustomers(statsSalesTotal.data.periods[period].customers);

    if (period === '1-15') {
      setStatsSales(statsSalesTotal?.data?.filtered.slice(0, 15));
      setBillingPeriodDays(days.slice(0, 15));
      setActiveBillingPeriodButton('firstQuarter');
    }
    if (period === `16-${days.length}`) {
      setStatsSales(statsSalesTotal?.data?.filtered.slice(15, days.length));
      setBillingPeriodDays(days.slice(15, days.length));
      setActiveBillingPeriodButton('secondQuarter');
    }
  };

  const dayMonthYearLabel = billingPeriodClicked
    ? billingPeriodDays.map((i) => `${i}.`)
    : statsSales && isDay
    ? statsSales.map((s, i) => {
        if (i < 10) {
          return '0' + i;
        } else {
          return i;
        }
      })
    : statsSales && isMonth
    ? days.map((i) => `${i}.`)
    : statsSales && isYear
    ? shortMonthNames.map((month) => intl.formatMessage({ id: month }))
    : shortMonthNames.map((month) => intl.formatMessage({ id: month }));

  const monthDateFormat = monthFilter < 10 ? `0${monthFilter}` : monthFilter;
  const dayDateFormat = dayFilter < 10 ? `0${dayFilter}` : dayFilter;
  const chartSettings = {
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translate(5px, -112px)',
      },
      [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
        transform: 'translate(calc(-50% + 5px), -19px)',
      },
    },
    slotProps: {
      legend: {
        hidden: true,
      },
    },
  };

  const handleClick = (event, d) => {
    event.stopPropagation();
    if (isMonth) {
      setDayFilter((d.dataIndex + 1).toString());
    }
    if (!isMonth && !isDay) {
      setMonthFilter(d?.dataIndex + 1);
    }
  };

  const maxValueStats = statsSales ? Math.max(...statsSales) : 2000;

  function calculatePercentage(highestNumber, percentage) {
    const extractedValue = (highestNumber * percentage) / 100;
    return extractedValue;
  }

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="stats-wrapper sales-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <div className="filter-wrapper">
              <div className="select-wrapper">
                <Formik>
                  <Field
                    as={Select}
                    name="day"
                    className="day"
                    intlTranslate={false}
                    value={dayFilter}
                    options={getDaysOptions(yearFilter, monthFilter, intl)}
                    onChange={(e) => changeDayFilter(e.target.value)}
                  />
                </Formik>
                <Formik>
                  <Field
                    as={Select}
                    name="month"
                    className="month"
                    intlTranslate={true}
                    value={monthFilter}
                    options={getMonthOptions(yearFilter)}
                    onChange={(e) => changeMonthFilter(e.target.value)}
                  />
                </Formik>
                <Formik>
                  <Field
                    as={Select}
                    name="year"
                    className="year"
                    value={yearFilter}
                    intlTranslate={false}
                    options={getYearOptions(intl)}
                    onChange={(e) => changeYearFilter(e.target.value)}
                  />
                </Formik>
              </div>
            </div>

            <div className="table-box">
              <h1 className="headline">
                {intl.formatMessage({ id: 'MY_SALES' })}
                {isDay
                  ? ` ${intl.formatMessage({ id: 'LABEL_ON' })} ${
                      language === 'de'
                        ? `${dayDateFormat}.${monthDateFormat}.${yearFilter}`
                        : `${monthDateFormat}/${dayDateFormat}/${yearFilter}`
                    }`
                  : isMonth
                  ? `  ${intl.formatMessage({
                      id: 'LABEL_IN',
                    })} ${intl.formatMessage({
                      id: monthFilterToIntlId(monthFilter),
                    })} ${yearFilter}`
                  : ` in ${yearFilter}`}
              </h1>
              {dayFilter === 'false' && monthFilter !== 'false' ? (
                <div className="btn-wrapper">
                  <Button
                    onClick={() => showBillingPeriod('1-15')}
                    className={`${
                      activeBillingPeriodButton === 'firstQuarter'
                        ? 'btn-default active'
                        : 'btn-default'
                    }`}
                    intlTranslate={false}
                    label={
                      language === 'de'
                        ? `01.${monthDateFormat}. - 15.${monthDateFormat}.`
                        : `${monthDateFormat}/01 - ${monthDateFormat}/15`
                    }
                  />
                  <Button
                    onClick={() => showBillingPeriod(`16-${days.length}`)}
                    className={`${
                      activeBillingPeriodButton === 'secondQuarter'
                        ? 'btn-default active'
                        : 'btn-default'
                    }`}
                    intlTranslate={false}
                    label={
                      language === 'de'
                        ? `16.${monthDateFormat}. - ${
                            getDaysOptions(yearFilter, monthFilter, intl)
                              ?.length - 1
                          }.${monthDateFormat}.`
                        : `${monthDateFormat}/16 - ${monthDateFormat}/${
                            getDaysOptions(yearFilter, monthFilter, intl)
                              ?.length - 1
                          }`
                    }
                    disabled={
                      currentYear === yearFilter &&
                      currentMonth.toString() === monthFilter &&
                      currentDay < 16
                    }
                  />

                  <Button
                    onClick={() => fetchSales()}
                    className={
                      billingPeriodClicked
                        ? `btn-default`
                        : `btn-default active`
                    }
                    label="LABEL_TOTAL"
                  />
                </div>
              ) : null}

              {loading ? (
                <SpinnerComponent />
              ) : statsSales?.length === dayMonthYearLabel?.length ||
                statsSales?.length === billingPeriodDays?.length ? (
                <BarChart
                  series={[
                    {
                      data: statsSales?.length ? statsSales : [],
                      label: 'Coins',
                    },
                  ]}
                  height={300}
                  margin={{ top: 70, bottom: 30, left: 50, right: 0 }}
                  grid={{ horizontal: true }}
                  onItemClick={handleClick}
                  onAxisClick={handleClick}
                  skipAnimation={true}
                  yAxis={[
                    {
                      label: 'Coins',
                      colorMap: statsSales?.length
                        ? {
                            type: 'piecewise',
                            thresholds: [
                              calculatePercentage(maxValueStats, 20),
                              calculatePercentage(maxValueStats, 50),
                              calculatePercentage(maxValueStats, 80),
                            ],
                            colors: [
                              '#F7A695',
                              '#FFF886',
                              '#CDE688',
                              '#9DE68A',
                            ],
                          }
                        : null,
                    },
                  ]}
                  xAxis={[
                    {
                      categoryGapRatio: 0.1,
                      data: dayMonthYearLabel,
                      scaleType: 'band',
                      label: isDay
                        ? intl.formatMessage({ id: 'LABEL_HOUR' })
                        : isMonth
                        ? intl.formatMessage({ id: 'LABEL_DAY' })
                        : isYear
                        ? intl.formatMessage({ id: 'LABEL_MONTH' })
                        : '',
                      tickPlacement: 'middle',
                      tickLabelPlacement: 'middle',
                      hideTooltip: true,
                    },
                  ]}
                  {...chartSettings}
                />
              ) : null}
            </div>
            <div className="table-box content">
              <div className="content-box">
                <img src={coinImg} alt="Total Coins" />
                <div className="text">
                  <span className="title">
                    {intl.formatMessage({ id: 'TOTAL_COINS' })}
                  </span>
                  <span className="number">{totalCoins}</span>
                </div>
              </div>
              <div className="content-box">
                <img src={customerImg} alt="Customers" />
                <div className="text">
                  <span className="title">
                    {intl.formatMessage({ id: 'CUSTOMERS' })}
                  </span>
                  <span className="number">{totalCustomers}</span>
                </div>
              </div>
              <div className="content-box">
                <img src={camImg} alt="Cam Shows" />
                <div className="text">
                  <span className="title">
                    {intl.formatMessage({ id: 'CAM_SHOWS' })}
                  </span>
                  <span className="number">{totalCamShows}</span>
                </div>
              </div>
            </div>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          hasBoxShadow={true}
          containsHeader={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="STATS_INFO_HEADER" />
            </h2>
            <p>{intl.formatMessage({ id: 'STATS_SALES' })}</p>
            <p>{intl.formatMessage({ id: 'STATS_PERFORMANCE' })}</p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Sales;
