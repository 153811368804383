import './ParkingCreditsTable.scss';

import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import DateItem from '../TableItems/DateItem';
import LinkItem from '../TableItems/LinkItem/LinkItem';
import NumberItem from '../TableItems/NumberItem';

function ParkingCreditsTable({
  items = [],
  precision,
  currency,
  translatedTitles,
  isMobileScreen,
  isMediumScreen,
  total,
}) {
  const navigate = useNavigate();
  const intl = useIntl();
  function redirectToBillDetails(billId) {
    navigate({
      pathname: '/my-account/commission/parking-credits-detail',
      search: `?bill_id=${billId}`,
    });
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            {translatedTitles.map((title, index) => {
              return (
                <th
                  style={{ width: title.width, textAlign: title.position }}
                  key={`${title}.${index}`}
                >
                  {title.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={`${item.key}.${index}`}>
                <td
                  className="link-parking-credits-item"
                  onClick={() => redirectToBillDetails(item.id)}
                >
                  <LinkItem data={item.bill_id} />
                </td>
                <td className="text-left">
                  <DateItem data={item.bill_date} />
                </td>
                {!isMobileScreen && !isMediumScreen ? (
                  <td className="text-left">
                    <span className="billing-period-item-left">
                      <DateItem data={item.start} />
                    </span>
                    {intl.formatMessage({ id: 'UNTIL' })}
                    <span className="billing-period-item-right">
                      <DateItem data={item.end} />
                    </span>
                  </td>
                ) : null}

                <td className={isMobileScreen ? 'text-center' : 'text-right'}>
                  <NumberItem number={item.rate} /> %
                </td>
                <td className="text-right">
                  <NumberItem
                    number={item.revenue}
                    precision={precision}
                    currency={currency}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="results-row">
        <span>{intl.formatMessage({ id: 'PARKING_TOTAL_REVENUE' })}</span>
        <span>
          <NumberItem
            number={total}
            precision={precision}
            currency={currency}
          />
        </span>
      </div>
    </>
  );
}

export default ParkingCreditsTable;
