import personalInfoIcon from '../../../assets/img/svg/personal-info.svg';
import profilePictureIcon from '../../../assets/img/svg/picture.svg';
import profileDetailsIcon from '../../../assets/img/svg/profile-details.svg';
import uploadDocumentIcon from '../../../assets/img/svg/upload-document.svg';
import verifyEmailIcon from '../../../assets/img/svg/verify-email.svg';
import OnboardingDoi from './Steps/OnboardingDoi';
import PersonalData from './Steps/PersonalData';
import ProfileDetails from './Steps/ProfileDetails';
import ProfilePicture from './Steps/ProfilePicture';
import UploadDocuments from './Steps/UploadDocuments';

export const onboardingItems = [
  {
    title: 'ONBOARDING_ITEM_DOI',
    icon: verifyEmailIcon,
    path: '/doi',
    component: OnboardingDoi,
    fields: [],
  },
  {
    title: 'ONBOARDING_ITEM_PROFILE_DETAILS',
    icon: profileDetailsIcon,
    path: '/profile-details',
    component: ProfileDetails,
    fields: ['nickname', 'gender', 'country_code', 'zip', 'native_language'],
  },
  {
    title: 'ONBOARDING_ITEM_PROFILE_PICTURE',
    icon: profilePictureIcon,
    path: '/profile-picture',
    component: ProfilePicture,
    fields: ['images'],
  },
  {
    title: 'ONBOARDING_ITEM_PERSONAL_DATA',
    icon: personalInfoIcon,
    path: '/personal-data',
    component: PersonalData,
    fields: ['forename', 'surname', 'birthday'],
  },
  {
    title: 'ONBOARDING_ITEM_UPLOAD_DOCUMENTS',
    icon: uploadDocumentIcon,
    path: '/upload-documents',
    component: UploadDocuments,
    fields: ['documents'],
  },
];
