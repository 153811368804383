import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-lodash-debounce';

import Scrollbar from '../../../Scrollbar';
import Stepper from '../../../Stepper';
import Content from '../../../Stepper/Content/Content';
import Items from '../../../Stepper/Items/Items';
import FinishedComponent from '../FinishedComponent';
import { getInitialItems, validateAllItems } from '../Onboarding.functions';
import { onboardingItems } from '../Onboarding.items';
import OnboardingNavigator from '../OnboardingNavigator';
import ReviewHeadline from '../ReviewHeadline';

function OnboardingStepper({ user, isMobile }) {
  const intl = useIntl();
  const { values, errors } = useFormikContext();

  const intialItems = useMemo(
    () => getInitialItems(onboardingItems, values, errors, user, intl),
    [errors, values, user, intl]
  );

  const [, feedbackForStateChangeRequest] = useAxios(
    {
      url: '/user/account',
    },
    { manual: true }
  );
  const myInterval = useRef(null);
  useEffect(() => {
    const intervalCheckStateChange = () => {
      if (user.account.state === 'pending') {
        feedbackForStateChangeRequest()
          .then((response) => {
            if (response.data.state !== 'pending') {
              window.location.reload();
            }
          })
          .catch(() => {});
      }
    };
    if (myInterval.current) {
      clearInterval(myInterval.current);
    }
    myInterval.current = setInterval(intervalCheckStateChange, 30000);
    return () => clearInterval(myInterval.current);
  }, [feedbackForStateChangeRequest, user.account.state]);

  const [items, setItems] = useState(intialItems);
  const debouncedNickname = useDebounce(values.nickname, 200);

  const [
    {
      data: nicknameData,
      error: nicknameError,
      loading: isNicknameDataLoading,
    },
  ] = useAxios(
    {
      url: '/user/account',
      method: 'PUT',
      data: {
        nickname: debouncedNickname,
      },
    },
    {
      manual:
        !debouncedNickname.trim() ||
        errors.nickname ||
        (['pending', 'review_needed'].indexOf(user?.account?.state) >= 0 &&
          user?.account?.review_state?.indexOf('nickname') < 0),
    }
  );

  const formClassName = classNames('onboarding-content', {
    'review-needed': user.account.state === 'review_needed',
  });

  return (
    <Stepper
      defaultPath={
        user.account.state === 'review_needed' ? '/review' : '/onboarding'
      }
      items={items}
      setItems={setItems}
      isPending={user.account.state === 'pending'}
      isFinished={
        user.account.state === 'pending' ||
        validateAllItems(user, items, values, errors, intl)
      }
      finishedComponent={
        user.account.state === 'pending' ? <FinishedComponent /> : null
      }
    >
      <Items state={user.account.state} />

      <Form className={formClassName}>
        <Scrollbar visible={true}>
          {user.account.state === 'review_needed' && isMobile && (
            <ReviewHeadline user={user} intl={intl} header={true} />
          )}
          <Content
            nicknameData={nicknameData}
            nicknameError={nicknameError}
            isNicknameDataLoading={isNicknameDataLoading}
            state={user.account.state}
          />
        </Scrollbar>
      </Form>

      <OnboardingNavigator
        allItemsAreValid={validateAllItems(
          user,
          items,
          values,
          errors,
          intl,
          isNicknameDataLoading,
          nicknameError
        )}
      />
    </Stepper>
  );
}

export default OnboardingStepper;
