import './Document.scss';
import '../../../../../node_modules/react-pdf/dist/cjs/Page/AnnotationLayer.css';
import '../../../../../node_modules/react-pdf/dist/cjs/Page/TextLayer.css';

import classNames from 'classnames';
import React, { useState } from 'react';
import { Document as Doc, Page, pdfjs } from 'react-pdf';

import closeIcon from '../../../../assets/img/svg/close.svg';
import VideoPlayerItem from '../../../../components/VideoPlayerItem/VideoPlayerItem';
import Button from '../../../Button/Button';
import Scrollbar from '../../../Scrollbar';

function Document({
  title,
  fileSource,
  closeOverlay,
  openDocumentStamp,
  isMobile,
  video,
  errorLog,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const [numPages, setNumPages] = useState(null);

  const documentNameSplit = title && title.split('.');
  const documentType =
    documentNameSplit && documentNameSplit[documentNameSplit.length - 1];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onLoadError(error) {
    console.log(error);
  }

  function onSourceError(error) {
    console.log(error);
  }

  const overlayWrapperClass = classNames('overlay-wrapper document', {
    pdf: documentType === 'pdf',
    image: documentType !== 'pdf',
  });

  const overlayContentClass = classNames('overlay-content', {
    mobile: isMobile,
  });

  const logImageError = (err) => {
    errorLog({
      message: 'Image not loaded',
      location: 'Document',
      data: { src: err?.target?.src },
    });
  };
  return (
    <>
      <div className={overlayWrapperClass}>
        <div className="overlay-header document">
          {title && <h1 className="headline">{title}</h1>}
        </div>
        <div className={overlayContentClass}>
          <Scrollbar visible={documentType === 'pdf'}>
            {documentType === 'pdf' ? (
              <Doc
                file={fileSource}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onLoadError}
                onSourceError={onSourceError}
              >
                {[...Array(numPages).keys()].map((p) => (
                  <Page pageNumber={p + 1} s key={p + 1} />
                ))}
              </Doc>
            ) : video ? (
              <div className="video-wrapper">
                <VideoPlayerItem autoplay data={fileSource} />
              </div>
            ) : (
              <div className="image-wrapper">
                <img
                  className="document-image"
                  id="document-image"
                  src={fileSource}
                  alt={title}
                  onError={logImageError}
                />
              </div>
            )}
          </Scrollbar>
        </div>

        <Button
          type="button"
          classNamesOnly="close-btn"
          icon={closeIcon}
          onClick={() => closeOverlay(openDocumentStamp)}
          intlTranslate={false}
        />
      </div>
      <div
        className="background"
        onClick={() => closeOverlay(openDocumentStamp)}
      />
    </>
  );
}

export default Document;

