import useAxios from 'axios-hooks';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CountriesSelectWithoutDefault from '../../../../Forms/CountriesSelect/CountriesSelectWithoutDefault';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import Select from '../../../../Forms/Select/Select';
import ZipCodeSelect from '../../../../Forms/ZipCodeSelect/ZipCodeSelectContainer';
import NicknameInputField from '../../../../Nickname/NicknameInputField';
import StepperContext from '../../../../Stepper/StepperContext';
import {
  checkIfAllFieldsAreValid,
  checkIfAnyFieldsAreTouched,
  checkIfReviewFieldsAreTouched,
  generateRequestData,
  hasReviewError,
  reviewDisabledField,
} from '../../Onboarding.functions';
import { getGenderOptions } from './ProfileDetails.functions';

function ProfileDetails({
  item,
  update = () => {},
  addOptions = () => {},
  languages = [],
  user = {},
  spinner = () => {},
  nicknameError,
  nicknameData,
  isNicknameDataLoading,
  isMobile,
}) {
  const intl = useIntl();
  const genderOptions = getGenderOptions(intl);
  const {
    currentItemIndex,
    nextItemIndex,
    setCurrentItemIndex,
    markCurrentItemAsValid,
    markCurrentItemAsInvalid,
    isLoading,
    sendingToReview,
    setSendingToReview,
    setIsDisabled,
  } = useContext(StepperContext);

  const {
    values,
    touched,
    setTouched,
    setFieldTouched,
    setFieldValue,
    errors,
  } = useFormikContext();

  const [
    {
      data: profileDetailsData,
      error: profileDetailsError,
      loading: isProfileDetailsLoading,
    },
    profileDetailsRequest,
  ] = useAxios(
    {
      url: '/user/public',
      method: 'PUT',
      data: generateRequestData(user, values, item),
    },
    {
      manual: true,
    }
  );
  const [
    {
      loading: isPrivateProfileDetailsLoading,
      error: privateProfileDetailsError,
    },
    privateProfileDetailsRequest,
  ] = useAxios(
    {
      url: '/user/private',
      method: 'PUT',
      data: generateRequestData(user, values, item, ['country_code']),
    },
    {
      manual: true,
    }
  );

  function changeNickname(item) {
    setFieldTouched('nickname', true);
    setFieldValue('nickname', item);
  }

  const targetRef = useRef();

  useEffect(() => {
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'end',
      });
    }
  }, []);

  useEffect(() => {
    setIsDisabled(isNicknameDataLoading && !errors.nickname);
  }, [errors.nickname, isNicknameDataLoading, setIsDisabled]);

  useEffect(() => {
    if (nicknameData) {
      if (nicknameData.nickname !== user.account.nickname) {
        update({ account: nicknameData });
      }
    }
  }, [
    item.isError,
    markCurrentItemAsValid,
    nicknameData,
    update,
    user.account.nickname,
    user.account.state,
    values,
  ]);

  useEffect(() => {
    if (
      nextItemIndex !== null &&
      nextItemIndex !== currentItemIndex &&
      !isLoading
    ) {
      if (checkIfAnyFieldsAreTouched(item.fields, touched)) {
        if (checkIfAllFieldsAreValid(item.fields, errors) && !nicknameError) {
          spinner(true);
          profileDetailsRequest();
          privateProfileDetailsRequest();
        } else {
          markCurrentItemAsInvalid();
          setCurrentItemIndex(nextItemIndex);
        }
      } else {
        setCurrentItemIndex(nextItemIndex);
      }
    }
  }, [
    profileDetailsRequest,
    privateProfileDetailsRequest,
    currentItemIndex,
    errors,
    isLoading,
    item.fields,
    markCurrentItemAsInvalid,
    nextItemIndex,
    setCurrentItemIndex,
    spinner,
    touched,
    nicknameError,
  ]);

  useEffect(() => {
    if (sendingToReview === 'pending') {
      profileDetailsRequest();
      spinner(true);
    }
  }, [profileDetailsRequest, sendingToReview, spinner]);

  useEffect(() => {
    if (isProfileDetailsLoading || isPrivateProfileDetailsLoading) {
      spinner(true);
    }
  }, [spinner, isProfileDetailsLoading, isPrivateProfileDetailsLoading]);

  useEffect(() => {
    if (profileDetailsData && nextItemIndex !== currentItemIndex) {
      spinner(false);

      if (user.account.state !== 'review_needed') {
        setTouched({
          nickname: false,
          gender: false,
          country_code: false,
          native_language: false,
          zip: false,
        });
        markCurrentItemAsValid();
        update({ public: profileDetailsData });
      } else {
        // If the state is in review, only mark the item as valid if all the error fields are fixed
        // The update of the review_state field will be done on the backend as well
        const propertiesToReview = _.intersection(
          user.account.review_state,
          item.fields
        );
        if (checkIfReviewFieldsAreTouched(propertiesToReview, touched)) {
          const updatedReviewState = _.union(
            user.account.review_state_changed_fields,
            propertiesToReview
          );
          update({
            public: profileDetailsData,
            account: {
              ...user.account,
              review_state_changed_fields: updatedReviewState,
            },
          });
          markCurrentItemAsValid();
        }
      }

      setCurrentItemIndex(nextItemIndex);
    }
  }, [
    profileDetailsData,
    currentItemIndex,
    markCurrentItemAsValid,
    nextItemIndex,
    setCurrentItemIndex,
    spinner,
    setTouched,
    addOptions,
    update,
    user.account,
    item,
    touched,
  ]);

  useEffect(() => {
    if (profileDetailsData && sendingToReview === 'pending') {
      update({ public: profileDetailsData });
      spinner(false);
      setSendingToReview('ready');
    }
  }, [
    profileDetailsData,
    sendingToReview,
    spinner,
    setSendingToReview,
    update,
  ]);

  useEffect(() => {
    if (nextItemIndex !== null && nextItemIndex !== currentItemIndex) {
      if (errors.nickname && touched.nickname) {
        markCurrentItemAsInvalid();
      }
    }
  }, [
    currentItemIndex,
    errors.nickname,
    markCurrentItemAsInvalid,
    nextItemIndex,
    touched.nickname,
  ]);

  useEffect(() => {
    if (
      profileDetailsError &&
      privateProfileDetailsError &&
      nicknameError &&
      nextItemIndex !== currentItemIndex
    ) {
      spinner(false);
      markCurrentItemAsInvalid();
      setCurrentItemIndex(nextItemIndex);
    }
  }, [
    profileDetailsError,
    privateProfileDetailsError,
    currentItemIndex,
    markCurrentItemAsInvalid,
    nextItemIndex,
    setCurrentItemIndex,
    spinner,
    nicknameError,
  ]);

  useEffect(() => {
    if (user.account.state === 'review_needed' && item.isError) {
      // Marking the current item as valid so that the finalise button is always shown properly
      const propertiesToReview = _.intersection(
        user.account.review_state,
        item.fields
      );
      if (checkIfReviewFieldsAreTouched(propertiesToReview, touched)) {
        markCurrentItemAsValid();
      }
    }
  });
  const addZipTab = values.country_code === 'DE' ? 1 : 0;
  return (
    <>
      {isMobile && user.account.state !== 'review_needed' && (
        <>
          <div className="intro-text">
            <span ref={targetRef} />
            <h1 className="headline">
              {intl.formatMessage({ id: 'ONBOARDING_TITLE' })}
            </h1>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'ONBOARDING_HEADLINE_1',
                  }),
                }}
              />
            </p>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'ONBOARDING_HEADLINE_2',
                  }),
                }}
              />
            </p>
          </div>
        </>
      )}
      <div className="content-inner profile-details-step">
        <span ref={targetRef} />
        <div className="content-grid">
          <div className="grid-col">
            <h2 className="step-headline">
              <FormattedMessage id="CHOOSE_NICKNAME" />
            </h2>
            <p>
              <FormattedMessage id="CHOOSE_NICKNAME_SUB" />
            </p>
            <NicknameInputField
              label={'MY_NICKNAME'}
              isNicknameDataLoading={isNicknameDataLoading}
              errors={errors.nickname}
              touched={touched.nickname}
              hasReviewError={hasReviewError(user, 'nickname', user.nickname)}
              nicknameError={nicknameError}
              changeNickname={changeNickname}
              nicknameData={nicknameData}
              reviewError={
                hasReviewError(user, 'nickname', user.nickname)
                  ? intl.formatMessage({ id: 'NICKNAME_REVIEW_ERROR' })
                  : null
              }
              onChange={(e) => {
                setFieldTouched('nickname', true);
                setFieldValue('nickname', e.target.value);
              }}
            />
            <div className="bottom">
              <p className="bold">
                <FormattedMessage id="ONBOARDING_NICKNAME_CONTENT_1" />
              </p>
              <p className="no-margin">
                <FormattedMessage id="ONBOARDING_NICKNAME_CONTENT_2" />
              </p>
            </div>
          </div>
          <div className="grid-col">
            <h1 className="step-headline">
              <FormattedMessage id="PROFILE_DETAILS" />
            </h1>
            <p>
              <FormattedMessage id="PROFILE_DETAILS_SUB" />
            </p>
            <FieldWithErrors
              name="gender"
              as={Select}
              id="gender"
              label="FIELD_GENDER"
              tabIndex="1"
              reviewError={
                hasReviewError(user, 'gender', touched.gender)
                  ? intl.formatMessage({ id: 'GENDER_REVIEW_ERROR' })
                  : null
              }
              disabled={isLoading || reviewDisabledField(user, 'gender')}
              options={genderOptions}
              intlTranslate={false}
              onChange={(event) => {
                setFieldTouched('gender', true);
                setFieldValue('gender', event.target.value);
              }}
            />
            <FieldWithErrors
              id="country_code"
              name="country_code"
              label="FIELD_LIVINGCOUNTRY"
              tabIndex="2"
              as={CountriesSelectWithoutDefault}
              asSelect={Select}
              reviewError={
                hasReviewError(user, 'country_code', touched.country_code)
                  ? intl.formatMessage({ id: 'COUNTRY_CODE_REVIEW_ERROR' })
                  : null
              }
              disabled={isLoading || reviewDisabledField(user, 'country_code')}
              intlTranslate={false}
              onChange={(event) => {
                setFieldTouched('country_code', true);
                setFieldTouched('zip', true);
                setFieldValue('country_code', event.target.value);
              }}
              breakLineIndex={8}
            />

            {values.country_code === 'DE' ? (
              <FieldWithErrors
                id="zip"
                name="zip"
                label="FIELD_ZIP_CODE"
                tabIndex="3"
                as={ZipCodeSelect}
                asSelect={Select}
                reviewError={
                  hasReviewError(user, 'zip', touched.zip)
                    ? intl.formatMessage({ id: 'ZIP_CODE_INVALID' })
                    : null
                }
                disabled={
                  isLoading ||
                  (reviewDisabledField(user, 'zip') &&
                    reviewDisabledField(user, 'country_code')) ||
                  values.country_code !== 'DE'
                }
                intlTranslate={false}
                onChange={(event) => {
                  setFieldTouched('zip', true);
                  setFieldValue('zip', event.target.value);
                }}
              />
            ) : (
              (values.zip = '')
            )}

            <FieldWithErrors
              id="native_language"
              name="native_language"
              label="FIELD_NATIVE_LANGUAGE"
              tabIndex={3 + addZipTab}
              as={Select}
              reviewError={
                hasReviewError(user, 'native_language', touched.native_language)
                  ? intl.formatMessage({ id: 'NATIVE_LANGUAGE_REVIEW_ERROR' })
                  : null
              }
              options={languages}
              defaultValueOption="Loading..."
              intlTranslate={false}
              disabled={
                isLoading || reviewDisabledField(user, 'native_language')
              }
              onChange={(event) => {
                setFieldTouched('native_language', true);
                setFieldValue('native_language', event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileDetails;
