import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import infocardDE from '../../../../../assets/img/wiki/userInfocard/infocard_de.jpg';
import infocardEN from '../../../../../assets/img/wiki/userInfocard/infocard_en.jpg';
import messengerDE from '../../../../../assets/img/wiki/userInfocard/messenger_de.jpg';
import messengerEN from '../../../../../assets/img/wiki/userInfocard/messenger_en.jpg';
import personalDE from '../../../../../assets/img/wiki/userInfocard/personal_de.jpg';
import personalEN from '../../../../../assets/img/wiki/userInfocard/personal_en.jpg';
import BackButton from '../../../../BackButton';

function UserInfocard({ locale, unconfirmed, onBackButtonClicked }) {
  const intl = useIntl();
  return (
    <div className="wiki-content user-infocard-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="INFOCARD_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage
          id="INFOCARD_INTRO"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="INFOCARD_INTRO_LINK" />
            ) : (
              <Link key="infocard-link-messenger" to="/multi-messenger">
                <FormattedMessage id="INFOCARD_INTRO_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={messengerEN}
            alt="Multi Messenger"
          />
        ) : (
          <img
            className="img-responsive"
            src={messengerDE}
            alt="Multi Messenger"
          />
        )}
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_USERINFO" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="INFOCARD_USERINFO_TEXT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_NOTES" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="INFOCARD_NOTES_TEXT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_GAMES" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="INFOCARD_GAMES_TEXT" />
          </p>
        </div>
      </div>

      <h2 className="headline">
        <FormattedMessage id="INFOCARD_USERINFO" />
      </h2>
      <p>
        <FormattedMessage id="INFOCARD_USERINFO_TEXT_1" />
      </p>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={infocardEN}
            alt="User Infocard"
          />
        ) : (
          <img
            className="img-responsive"
            src={infocardDE}
            alt="User Infocard"
          />
        )}
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_USERINFO_PICTURE" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="INFOCARD_USERINFO_PICTURE_TEXT"
              values={{
                link: unconfirmed ? (
                  <FormattedMessage id="INFOCARD_USERINFO_PICTURE_LINK" />
                ) : (
                  <Link
                    key="infocard-link-options"
                    to="/my-account/settings/options"
                  >
                    <FormattedMessage id="INFOCARD_USERINFO_PICTURE_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="INFOCARD_USERINFO_PICTURE_TEXT_1" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_USERINFO_FEATURES" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="INFOCARD_USERINFO_FEATURES_TEXT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_USERINFO_DATA" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="INFOCARD_USERINFO_DATA_TEXT" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive"
                src={personalEN}
                alt={intl.formatMessage({ id: 'INFOCARD_USERINFO_PERSONAL' })}
              />
            ) : (
              <img
                className="img-responsive"
                src={personalDE}
                alt={intl.formatMessage({ id: 'INFOCARD_USERINFO_PERSONAL' })}
              />
            )}
          </div>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="INFOCARD_USERINFO_REVENUE" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="INFOCARD_USERINFO_REVENUE_TEXT" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserInfocard;
