import './Blog.scss';

import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { regionalFormat } from '../../../utils/date';
import Button from '../../Button/Button';
import SpinnerComponent from '../../Spinner/SpinnerComponent';

function Blog() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [messages, setMessages] = useState(null);

  const [{ loading: blogLoading }, blogtotalRequest] = useAxios(
    {
      url: `/user/blog`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const language = useSelector((state) => state.user.account.language);
  const goToLink = (link) => {
    navigate(link);
  };
  const goToNewsletter = (newsletterId) => {
    navigate('/newsletter?id=' + newsletterId);
  };

  useEffect(() => {
    let isMounted = true;
    if (!messages) {
      blogtotalRequest()
        .then((response) => {
          isMounted && setMessages(response.data);
        })
        .catch((error) => {
          isMounted && console.error(error);
        });
      return () => (isMounted = false);
    }
  }, [blogtotalRequest, messages]);

  return blogLoading ? (
    <SpinnerComponent />
  ) : (
    <div className="blog-container">
      <h3 className="headline">
        {intl.formatMessage({ id: 'BLOG_HEADLINE' })}
      </h3>
      {messages?.length > 0 ? (
        messages.map((message, index) => {
          let buttonLabel = message?.buttonLabel;
          let translate = false;
          if (!buttonLabel) {
            buttonLabel = 'READ_MORE';
            translate = true;
          }
          return (
            <div key={index} className={`blog-item ${message.type}-entry`}>
              <span className="title">
                <span dangerouslySetInnerHTML={{ __html: message.subject }} />
              </span>
              <span className="date">
                {regionalFormat(message.date, language)}
              </span>
              <p>
                <span dangerouslySetInnerHTML={{ __html: message.message }} />
              </p>
              {message.link ? (
                <div className="btn-wrapper">
                  <Button
                    intlTranslate={translate}
                    label={buttonLabel}
                    className="btn-small"
                    variant={'primary'}
                    onClick={() => goToLink(message.link)}
                  />
                </div>
              ) : message.type === 'newsletter' ? (
                <div className="btn-wrapper">
                  <Button
                    intlTranslate={translate}
                    label={buttonLabel}
                    className="btn-small"
                    variant="primary"
                    onClick={() => goToNewsletter(message.newsletterId)}
                  />
                </div>
              ) : null}
            </div>
          );
        })
      ) : (
        <p>{intl.formatMessage({ id: 'BLOG_PLACEHOLDER' })}</p>
      )}
    </div>
  );
}

export default Blog;
