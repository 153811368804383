import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default (locale) => (
  <div className="terms-text">
    <ol>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_SUBJECT" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_SUBJECT_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_SUBJECT_2" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_SUBJECT_3" />
            <p>
              <FormattedMessage id="LEGAL_TERMS_SUBJECT_3_1" />
            </p>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_SUBJECT_4" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_SUBJECT_5" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_CONCLUSION" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CONCLUSION_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CONCLUSION_2" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CONCLUSION_3" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CONCLUSION_4" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CONCLUSION_5" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CONCLUSION_6" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_IPB_SERVICES" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_IPB_SERVICES_1" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_PERFORMER_SERVICES" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_PERFORMER_SERVICES_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_PERFORMER_SERVICES_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_PERFORMER_SERVICES_3" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_RENUMERATION" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_1" />
            <p>
              <FormattedMessage id="LEGAL_TERMS_RENUMERATION_1_1">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_2" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
            <p>
              <FormattedMessage id="LEGAL_TERMS_RENUMERATION_3_1" />
            </p>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_4" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_5" />
            <p>
              <FormattedMessage id="LEGAL_TERMS_RENUMERATION_5_1" />
            </p>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_6" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_7" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_8" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_RENUMERATION_9" />
            <p>
              <FormattedMessage id="LEGAL_TERMS_RENUMERATION_9_1">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="LEGAL_TERMS_RENUMERATION_9_2" />
            </p>
            <p>
              <FormattedMessage id="LEGAL_TERMS_RENUMERATION_9_3" />
            </p>
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </li>
          <li>
            <p>
              <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_3" />
            </p>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_3_1">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
            <p>
              <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_3_2" />
            </p>
            <p>
              <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_3_3" />
            </p>
            <p>
              <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_3_4" />
            </p>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_4" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_5" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_6" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_7" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_8" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_9" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_10" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_11" />
          </li>
          {locale === 'en' && (
            <li>
              <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_12" />
              <p>
                <FormattedMessage id="LEGAL_TERMS_OBLIGATIONS_12_1" />
              </p>
            </li>
          )}
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_LIABILITY" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_LIABILITY_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_LIABILITY_2" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_LIABILITY_3" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_LIABILITY_4" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_LIABILITY_5" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_LIABILITY_6" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_INDEMNIFICATION" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_INDEMNIFICATION_1" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_OPERATION" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OPERATION_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OPERATION_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OPERATION_3" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_OPERATION_4" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_CANCELLATION" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CANCELLATION_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CANCELLATION_2" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_CANCELLATION_3" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_PRIVACY" />
        </h2>
        <ol>
          <li>
            <FormattedMessage
              id="LEGAL_TERMS_PRIVACY_1"
              values={{
                link: (
                  <Link key="link-terms-to-privacy" to="/privacy-policy">
                    <FormattedMessage id="LEGAL_TERMS_PRIVACY" />
                  </Link>
                ),
              }}
            />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_MODIFICATION" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_MODIFICATION_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_MODIFICATION_2" />
          </li>
        </ol>
      </li>
      <li className="first">
        <h2 className="headline">
          <FormattedMessage id="LEGAL_TERMS_FINAL" />
        </h2>
        <ol>
          <li>
            <FormattedMessage id="LEGAL_TERMS_FINAL_1" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_FINAL_2" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_FINAL_3" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_FINAL_4" />
          </li>
          <li>
            <FormattedMessage id="LEGAL_TERMS_FINAL_5" />
          </li>
        </ol>
      </li>
    </ol>
  </div>
);
