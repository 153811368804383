import './Button.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

function Button({
  type = 'button',
  icon,
  label,
  variant = 'primary',
  isLoading,
  noShadow = false,
  className,
  adjustedWidth,
  classNamesOnly,
  svgClassname = 'icon',
  addEmptySpan = false,
  intlTranslate,
  renumerateIRIElements = true,
  children,
  labelValues,
  clicked,
  innerRef,
  active,
  img,
  ...other
}) {
  const intl = useIntl();
  const values = labelValues || {};

  const translatedLabel =
    intlTranslate === false ? label : intl.formatMessage({ id: label }, values);

  let classes =
    classNamesOnly ||
    classNames('btn', `btn-${variant}`, className, {
      'btn-icon': icon && variant !== 'icon-label',
      'btn-loading': isLoading,
      'no-shadow': noShadow,
      clicked,
      active,
    });

  if (variant === 'icon-only') {
    classes = classNamesOnly || classNames('btn', `btn-${variant}`, className);
  }

  const SVGrenumerateIRIElements =
    renumerateIRIElements === false ? false : true;

  return (
    <button
      ref={innerRef}
      type={type}
      className={classes}
      disabled={isLoading || other.disabled}
      {...other}
    >
      {addEmptySpan && <span />}
      {icon && (
        <ReactSVG
          src={icon}
          className={svgClassname}
          wrapper="span"
          renumerateIRIElements={SVGrenumerateIRIElements}
        />
      )}
      {img && <img className="icon-img" src={img} alt="" />}
      {variant !== 'icon-only' && (
        <span className="btn-content">
          {isLoading ? intl.formatMessage({ id: 'LOADING' }) : translatedLabel}
        </span>
      )}
      {children}
    </button>
  );
}

export default Button;
