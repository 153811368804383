import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import BackButton from '../../../../BackButton';

function Attachments({ unconfirmed, onBackButtonClicked }) {
  return (
    <div className="wiki-content attachments-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="ATTACHMENTS_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage
          id="ATTACHMENTS_CONTENT_1"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="ATTACHMENTS_CONTENT_1_LINK" />
            ) : (
              <Link
                key="attachements-link-attachements"
                to="/moneymakers/attachments"
              >
                <FormattedMessage id="ATTACHMENTS_CONTENT_1_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <p className="list-headline">
        <FormattedMessage id="ATTACHMENTS_CONTENT_1_1" />
      </p>
      <p>
        <FormattedMessage id="ATTACHMENTS_CONTENT_1_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>

      <h2 className="headline">
        <FormattedMessage id="ATTACHMENTS_HEADLINE_2" />
      </h2>
      <p>
        <FormattedMessage id="ATTACHMENTS_CONTENT_2" />
      </p>
      <p className="list-headline">
        <FormattedMessage id="ATTACHMENTS_CONTENT_2_1" />
      </p>
      <p>
        <FormattedMessage id="ATTACHMENTS_CONTENT_2_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p className="list-headline">
        <FormattedMessage id="ATTACHMENTS_CONTENT_2_3" />
      </p>
      <p>
        <FormattedMessage id="ATTACHMENTS_CONTENT_2_4">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}

export default Attachments;
