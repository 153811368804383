import './ArticlesCoinsTable.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../hooks/useTranslateTableTitles';
import {
  fullArticleCoinsTableTitles,
  mediumArticleCoinsTableTitles,
  mobileArticleCoinsTableTitles,
} from '../../Content/Commission/BillingPeriod/BillingPeriod.functions';
import {
  dashboardLargeArticleCoinsTableTitles,
  dashboardMobileArticleCoinsTableTitles,
} from '../../Content/Dashboard/Dashboard.functions';
import DateItem from '../TableItems/DateItem';
import LinkItem from '../TableItems/LinkItem/LinkItem';
import NumberItem from '../TableItems/NumberItem';

function ArticlesCoinsTable({
  items = [],
  results = [],
  isSubmenuShown,
  location,
  translateName = true,
  linksAvailable,
}) {
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen =
    useMediaQuery('(min-width: 1024px) and (max-width: 1599px)') &&
    isSubmenuShown;
  const titles =
    location === 'billing-period' || location === 'bill-details'
      ? isMobileScreen
        ? mobileArticleCoinsTableTitles
        : isMediumScreen
        ? mediumArticleCoinsTableTitles
        : fullArticleCoinsTableTitles
      : isMobileScreen
      ? dashboardMobileArticleCoinsTableTitles
      : dashboardLargeArticleCoinsTableTitles;

  const translatedTitles = useTranslateTableTitles(titles);
  const intl = useIntl();
  const navigate = useNavigate();

  function redirectToBillArticles(type) {
    if (!linksAvailable) {
      return;
    }
    navigate({
      pathname: '/my-account/commission/bill-articles',
      search: `?type=${type}`,
    });
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            {translatedTitles.map((title, index) => {
              return (
                <th
                  style={{ width: title.width, textAlign: title.position }}
                  key={`${title}.${index}`}
                >
                  {title.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items
            .filter(
              (item) =>
                item.display_if_empty ||
                item.coins ||
                item.revenue ||
                item.number
            )
            .map((item, index) => {
              const data = translateName
                ? intl.formatMessage({ id: item.name })
                : item.name;
              const itemClassName = classNames('link-table-item', {
                'no-link': !linksAvailable,
              });
              return (
                <tr key={`${item.key}.${index}`}>
                  <td
                    className={itemClassName}
                    onClick={() => {
                      redirectToBillArticles(item.key);
                    }}
                  >
                    {linksAvailable ? (
                      <LinkItem data={data} />
                    ) : (
                      <div>{data}</div>
                    )}
                  </td>
                  <td className={isMobileScreen ? 'text-center' : 'text-right'}>
                    <NumberItem number={item.number} />
                  </td>
                  {!isMobileScreen &&
                  !isMediumScreen &&
                  location !== 'dashboard' ? (
                    <td className="text-left">
                      {item.first && <DateItem data={item.first} />}
                    </td>
                  ) : null}
                  {!isMobileScreen &&
                  !isMediumScreen &&
                  location !== 'dashboard' ? (
                    <td className="text-left">
                      {item.last && <DateItem data={item.last} />}
                    </td>
                  ) : null}

                  <td className="text-right">
                    {' '}
                    <NumberItem number={item.coins} /> Coins
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="results-row">
        <span>{results[0].data}</span>
        <span>
          <NumberItem number={results[1].data} /> Coins
        </span>
      </div>
    </>
  );
}

export default ArticlesCoinsTable;
