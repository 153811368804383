import './GallerySelect.scss';

import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import closeIcon from '../../../../../assets/img/svg/close.svg';
import {
  FMOTD_MOBILE_PAGINATION_SIZE,
  FMOTD_PAGINATION_SIZE,
} from '../../../../../constants';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import Button from '../../../../Button/Button';
import Pagination from '../../../../Pagination/Pagination';
import SpinnerComponent from '../../../../Spinner/SpinnerComponent';
import GallerySelectItem from './GallerySelectItem/';

function GallerySelect({ replaceGallery, onSelect, onClose }) {
  const intl = useIntl();
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [galleries, setGalleries] = useState([]);
  const isMobileScreen = useMediaQuery('(max-width: 768px)');
  const [paginationSize, setPaginationSize] = useState(
    isMobileScreen ? FMOTD_MOBILE_PAGINATION_SIZE : FMOTD_PAGINATION_SIZE
  );
  const [{ loading }, getGalleriesData] = useAxios(
    `/fmd/available?limit=${paginationSize}&offset=${offset}`,
    { manual: true }
  );

  const scrollToTop = () => {
    const elements = document.getElementsByClassName('fmotd-wrapper');
    if (elements?.length && elements[0]) {
      elements[0].scrollIntoView();
    }
  };
  const loadGalleries = useCallback(async () => {
    try {
      const { data } = await getGalleriesData();
      setGalleries(data.data);
      setTotalItems(data.total);
      scrollToTop();
    } catch (error) {
      console.error(error);
    }
  }, [getGalleriesData]);

  useEffect(() => {
    setPaginationSize(
      isMobileScreen ? FMOTD_MOBILE_PAGINATION_SIZE : FMOTD_PAGINATION_SIZE
    );
    setOffset(0);
    setPageNumber(1);
  }, [isMobileScreen]);

  useEffect(() => {
    loadGalleries();
  }, [loadGalleries]);
  return (
    <div className="gallery-select-wrapper" id="fmotd-gallery-select">
      <div className="gallery-header">
        <h2 className="headline">
          {replaceGallery?.title?.content
            ? `${intl.formatMessage({ id: 'FMOTD_REPLACE' })} ${
                replaceGallery.title.content
              }`
            : intl.formatMessage({ id: 'FMOTD_AVAILABLE_GALLERIES' })}
        </h2>

        <Button
          variant="icon-only"
          type="button"
          icon={closeIcon}
          onClick={() => onClose()}
          intlTranslate={false}
        />
      </div>
      {loading ? (
        <SpinnerComponent />
      ) : totalItems > 0 ? (
        <div>
          <div className="gallery-select-container">
            {galleries.map((gallery, index) => {
              return (
                <GallerySelectItem
                  key={index}
                  gallery={gallery}
                  replaceGallery={replaceGallery}
                  onSelect={onSelect}
                />
              );
            })}{' '}
          </div>
          <Pagination
            offset={offset}
            totalItems={totalItems}
            pageNumber={pageNumber}
            paginationSize={paginationSize}
            setOffset={setOffset}
            setPageNumber={setPageNumber}
            onPageChange={scrollToTop}
          />
        </div>
      ) : (
        <div className="gallery-select-empty">
          {intl.formatMessage(
            {
              id: 'FMOTD_NO_GALLERIES',
            },
            {
              link: (
                <Link
                  key={'fmotd-link-button'}
                  to={`/my-content/videos/my-videos`}
                >
                  {intl.formatMessage({
                    id: 'FMOTD_GALLERIES',
                  })}
                </Link>
              ),
            }
          )}
        </div>
      )}
    </div>
  );
}

export default GallerySelect;
