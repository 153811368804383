import './LovenseInstallation.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import appleStoreImage from '../../../../../assets/img/appstore.png';
import googlePlayImage from '../../../../../assets/img/playstore.png';
import videoPosterImgDesktopDe from '../../../../../assets/img/videoPoster/lovense_desktop_de.jpg';
import videoPosterImgDesktopEn from '../../../../../assets/img/videoPoster/lovense_desktop_en.jpg';
import videoPosterImgDe from '../../../../../assets/img/videoPoster/lovense_mobile_de.jpg';
import videoPosterImgEn from '../../../../../assets/img/videoPoster/lovense_mobile_en.jpg';
import BackButton from '../../../../BackButton';
import VideoWithOverlay from '../../../../VideoWithOverlay';

function LovenseInstallation({ locale, unconfirmed, onBackButtonClicked }) {
  const videoMobile =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/lovense_mobile_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/lovense_mobile_de.mp4`;
  const videoPosterImgMobile =
    locale === 'en' ? videoPosterImgEn : videoPosterImgDe;

  const videoDesktop =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/lovense_desktop_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/lovense_desktop_de.mp4`;
  const videoPosterImgDesktop =
    locale === 'en' ? videoPosterImgDesktopEn : videoPosterImgDesktopDe;

  const appleLink =
    'https://apps.apple.com/us/app/lovense-connect/id1273067916';
  const androidLink =
    'https://play.google.com/store/apps/details?id=com.lovense.connect';
  return (
    <div className="wiki-content lovense-installation-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage
          id="WIKI_LOVENSE_INSTALLATION_SETTINGS"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="MENU_ITEM_SETTINGS" />
            ) : (
              <Link
                key="lovense-link-options"
                to="/my-account/settings/options"
              >
                <FormattedMessage id="MENU_ITEM_SETTINGS" />
              </Link>
            ),
          }}
        />
      </p>
      <h2 className="headline">
        <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_HEADLINE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </h2>
      <p>
        <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_SUBTEXT">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h2 className="headline">
        <FormattedMessage id="WIKI_HEADLINE_VIDEO" />
      </h2>
      <VideoWithOverlay
        video={videoMobile}
        poster={videoPosterImgMobile}
        showOverlay={false}
      />

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_SUBTEXT" />
          </span>
        </div>
        <div className="wiki-list-content">
          <div className="image-wrapper two-images">
            <a href={appleLink} target="_blank" rel="noopener noreferrer">
              <img src={appleStoreImage} alt="App Store" />
            </a>
            <a href={androidLink} target="_blank" rel="noopener noreferrer">
              <img src={googlePlayImage} alt="Google Play" />
            </a>
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_HEADLINE" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_1" />
          </p>
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_HEADLINE_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_4" />
          </p>
        </div>
      </div>

      <h2 className="headline">
        <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE" />
      </h2>
      <p>
        <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_ADAPTER" />
      </p>
      <h2 className="headline">
        <FormattedMessage id="WIKI_HEADLINE_VIDEO" />
      </h2>
      <VideoWithOverlay
        video={videoDesktop}
        poster={videoPosterImgDesktop}
        showOverlay={false}
      />
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_SUBTEXT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </span>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_1" />
          </p>
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_4" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default LovenseInstallation;
