import './Options.scss';

import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { spinnerRequest } from '../../../../shared/spinnerRequest';
import Tag from '../../../Forms/Tag/Tag';
import ToggleButton from '../../../Forms/ToggleButton/ToggleButton';
import OptionsSoundMenu from '../../../Livecam/SoundOptions/OptionsSoundMenu';

function Options({
  livecamAllowed = true,
  toycontrolEnabled = false,
  toycontrolActive = false,
  spinner,
  toggleToycontrol,
  isStreaming,
  safeMode,
  changeSafeMode,
  addVoyeurRoomTypes,
  removeVoyeurRoomTypes,
  gamesBlocked,
  user,
  userUpdate,
}) {
  const [optionsData, setOptionsData] = useState(null);

  const [, getOptionsDataRequest] = useAxios(
    {
      url: '/user/options',
    },
    { manual: true }
  );

  const [{ data: tagsData }, getTagsRequest] = useAxios(
    {
      url: '/data/tags',
    },
    { manual: true }
  );

  const [, postOptionsDataRequest] = useAxios(
    {
      url: '/user/options',
      method: 'PUT',
    },
    { manual: true }
  );

  const isConfirmed = user.account.state === 'confirmed';

  useEffect(() => {
    let isMounted = true;
    spinnerRequest({
      request: getOptionsDataRequest,
      spinner,
    })
      .then((response) => {
        isMounted && setOptionsData(response.data);
      })
      .catch(() => {});

    spinnerRequest({
      request: getTagsRequest,
      spinner,
    }).catch(() => {});

    return () => (isMounted = false);
  }, [getOptionsDataRequest, getTagsRequest, spinner]);

  function toggleButtonHandler(name) {
    const payload = {
      [name]: [
        'newsletter',
        'dating_chat',
        'datingcams',
        'voyeurcams',
        'game',
      ].includes(name)
        ? optionsData[name] === 'true'
          ? 'false'
          : 'true'
        : optionsData[name] === 'all'
          ? 'false'
          : 'all',
    };
    spinnerRequest({
      request: postOptionsDataRequest,
      spinner,
      payload: {
        data: payload,
      },
    }).then((response) => {
      setOptionsData(response.data);
      if (payload?.game && user?.account) {
        userUpdate({
          account: {
            ...user.account,
            game: payload.game === 'true' ? 'enabled' : 'disabled',
          },
        });
      }
      if (payload.voyeurcams) {
        if (response?.data?.voyeurcams === 'true') {
          addVoyeurRoomTypes();
        } else if (response?.data?.voyeurcams === 'false') {
          removeVoyeurRoomTypes();
        }
      }
    });
  }

  function tagChangeHandler(name) {
    const index = optionsData.user_tags.findIndex((tag) => tag === name);
    let newTags = [...optionsData.user_tags];
    if (index === -1) {
      if (newTags.length >= 5) {
        return;
      }
      newTags.push(name);
    } else {
      newTags = [...newTags.slice(0, index), ...newTags.slice(index + 1)];
    }

    spinnerRequest({
      request: postOptionsDataRequest,
      spinner,
      payload: {
        data: {
          user_tags: newTags,
        },
      },
    }).then((response) => {
      setOptionsData(response.data);
    });
  }

  return (
    <div className="options-wrapper data-columns">
      {isConfirmed && (
        <div className="data-left">
          <div className="group-container">
            <h2 className="headline">
              <FormattedMessage id="OPTIONS_FEATURES_HEADER" />
            </h2>
            <p>
              <FormattedMessage id="OPTIONS_FEATURES_TEXT_1" />
            </p>
            <ToggleButton
              data={
                optionsData?.dating_chat === 'false' ||
                optionsData?.dating_chat === 'blocked'
                  ? false
                  : true
              }
              click={() => toggleButtonHandler('dating_chat')}
              label="OPTIONS_FEATURES_APPS"
              disabled={optionsData?.dating_chat === 'blocked'}
              name="dating_chat"
            />
            {livecamAllowed && (
              <ToggleButton
                data={optionsData?.datingcams === 'true'}
                click={() => toggleButtonHandler('datingcams')}
                label="OPTIONS_FEATURES_DATING_CAMS"
                name="datingcams"
                disabled={isStreaming || optionsData?.datingcams === 'blocked'}
              />
            )}
            <ToggleButton
              data={optionsData?.voyeurcams === 'false' ? false : true}
              click={() => toggleButtonHandler('voyeurcams')}
              label="OPTIONS_FEATURES_VOYEUR"
              disabled={isStreaming}
              name="voyeurcams"
            />
            {toycontrolEnabled && (
              <ToggleButton
                data={toycontrolActive}
                click={toggleToycontrol}
                label="OPTIONS_FEATURES_TOYCONTROL"
                name="toycontrol"
                disabled={isStreaming}
              />
            )}
            <ToggleButton
              data={safeMode}
              click={() => changeSafeMode()}
              label="OPTIONS_FEATURES_SAFE_MODE"
              name="safemode"
            />
            {!gamesBlocked && (
              <ToggleButton
                data={optionsData?.game === 'false' ? false : true}
                click={() => toggleButtonHandler('game')}
                label="OPTIONS_FEATURES_COMMUNITY_GAMES"
                name="toycontrol"
                disabled={isStreaming}
              />
            )}
          </div>
          <div className="group-container">
            <OptionsSoundMenu />
          </div>
        </div>
      )}

      <div className="data-right">
        <div className="group-container">
          <>
            <h2 className="headline">
              <FormattedMessage id="OPTIONS_CREATOR_TAGS_HEADER" />
            </h2>
            <p>
              <FormattedMessage id="OPTIONS_CREATOR_TAGS_TEXT" />
            </p>
            <div className="tags-wrapper">
              {Array.isArray(tagsData) &&
                tagsData.map((tag, index) => (
                  <Tag
                    status={
                      optionsData ? optionsData.user_tags.includes(tag) : false
                    }
                    name={tag}
                    key={`${tag}.${index}`}
                    click={() => tagChangeHandler(tag)}
                    disabled={
                      optionsData?.user_tags?.length >= 5 &&
                      !optionsData?.user_tags?.includes(tag)
                    }
                  />
                ))}
            </div>
          </>
        </div>

        <div className="group-container">
          <h2 className="headline">
            <FormattedMessage id="OPTIONS_NOTIFICATION_HEADER" />
          </h2>
          <p>
            <FormattedMessage id="OPTIONS_NOTIFICATION_TEXT_1" />
          </p>
          <ToggleButton
            data={
              optionsData?.email_notification === 'bounce' ||
              optionsData?.email_notification === 'false'
                ? false
                : true
            }
            click={() => toggleButtonHandler('email_notification')}
            label="OPTIONS_NOTIFICATION_EMAIL"
            name="email_notification"
            disabled={
              optionsData?.email_notification !== 'all' &&
              optionsData?.email_notification !== 'false'
            }
          />

          <p>
            <FormattedMessage id="OPTIONS_NOTIFICATION_TEXT_2" />
          </p>
          <ToggleButton
            data={
              optionsData?.newsletter === 'doubleoptin' ||
              optionsData?.newsletter === 'false'
                ? false
                : true
            }
            click={() => toggleButtonHandler('newsletter')}
            label="OPTIONS_NOTIFICATION_NEWSLETTER"
            subtext="OPTIONS_NOTIFICATION_RECOMMENDED"
            name="newsletter"
            disabled={
              optionsData?.newsletter !== 'true' &&
              optionsData?.newsletter !== 'false'
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Options;
