import dayjs from 'dayjs';
import _ from 'lodash';
import * as Yup from 'yup';

export const getValidationScheme = (intl) => {
  const t = (id) => intl.formatMessage({ id });
  const required = t('VALIDATION_REQUIRED_FIELD');
  const nameRegEx = /^[a-zßäöüáéóúàèòù-]+(?:[-][a-zßäöüáéóúàèòù-]+)*$/iu;

  return Yup.object().shape({
    nickname: Yup.string()
      .required(required)
      .min(5, t('VALIDATION_NICKNAME_TO_SHORT'))
      .max(20, t('VALIDATION_NICKNAME_TO_LONG'))
      .test({
        test: function (value) {
          if (
            typeof value === 'string' &&
            value.match(/^(?:VX|(?:user|sender)-)/i)
          ) {
            return this.createError({
              message: t('VALIDATION_NICKNAME_NOT_VALID_1'),
            });
          }

          return true;
        },
      })
      .matches(
        /^[a-z][a-z0-9]*[_-]{0,1}[a-z0-9]+$/i,
        t('VALIDATION_NICKNAME_NOT_VALID_2')
      ),

    images: Yup.array().min(1, required),

    gender: Yup.string().required(required),
    country_code: Yup.string().required(required),
    native_language: Yup.string().required(required),
    zip: Yup.string().when('country_code', {
      is: 'DE',
      then: () => Yup.string().required(required),
    }),
    additional_languages: Yup.array(),

    forename: Yup.string()
      .required(required)
      .matches(nameRegEx, t('VALIDATION_NAME_NOT_CORRECT'))
      .max(64, t('VALIDATION_PROFILE_FORENAME_MAX')),
    surname: Yup.string()
      .required(required)
      .matches(nameRegEx, t('VALIDATION_NAME_NOT_CORRECT'))
      .max(64, t('VALIDATION_PROFILE_SURENAME_MAX')),
    birthday: Yup.string()
      .required(required)
      .test({
        test: function (value) {
          if (Math.abs(dayjs.utc().diff(dayjs.utc(value), 'year')) < 18) {
            return this.createError({
              message: t('VALIDATION_PROFILE_AGE_18'),
            });
          }

          return true;
        },
      }),

    documents: Yup.array().min(1, required),
  });
};
export function checkIfAnyFieldsAreTouched(fields, touched) {
  if (Object.keys(touched).length === 0) {
    return false;
  }

  const slicedObj = _.pick(touched, fields);

  return Object.keys(slicedObj).some((f) => touched[f]);
}

export function checkIfReviewFieldsAreTouched(fields, touched) {
  if (Object.keys(touched).length === 0) {
    return false;
  }
  return fields.every((f) => touched[f]);
}

export function markAllAsUntouched(fields, setIsTouched) {
  let touchedObj = {};

  for (const f of fields) {
    touchedObj[f] = false;
  }

  setIsTouched(touchedObj);
}

export function checkIfAllFieldsAreValid(fields, errors) {
  if (Object.keys(errors).length === 0) {
    return true;
  }

  const slicedObj = _.pick(errors, fields);

  return Object.keys(slicedObj).every((f) => !errors[f]);
}

export function checkIfAllRequiredFieldsHaveValuesAndAreValid(
  fields,
  values,
  intl
) {
  if (Object.keys(values).length === 0) {
    return false;
  }
  const validationSchema = getValidationScheme(intl);
  const slicedValuesObj = _.pick(values, fields);
  const slicedSchema = validationSchema.pick(fields);
  // validateSync throws an error if it fails, hence the try catch
  try {
    slicedSchema.validateSync(slicedValuesObj);
    return true;
  } catch (e) {
    return false;
  }
}

function checkReviewOrChangedFields(fields, errors) {
  for (let field of fields) {
    if (errors.includes(field)) {
      return true;
    }
  }
  return false;
}

function checkReviewAndChangedFields(fields, review, changed) {
  for (let field of fields) {
    if (review.includes(field) && !changed.includes(field)) {
      return true;
    }
  }
  return false;
}
export function getInitialItems(items, values, errors, user, intl) {
  const returnArr = _.cloneDeep(items).map((i) => {
    i.title = intl.formatMessage({ id: i.title });
    return i;
  });
  for (const item of returnArr) {
    if (item) {
      if (
        !checkIfAllFieldsAreValid(item.fields, errors) ||
        checkReviewAndChangedFields(
          item.fields,
          user.account.review_state,
          user.account.review_state_changed_fields
        )
      ) {
        item.isError = true;
        item.isSuccess = false;
      } else if (
        checkIfAllRequiredFieldsHaveValuesAndAreValid(item.fields, values, intl)
      ) {
        item.isSuccess = true;
        item.isError = false;
      }
      if (
        user.account.state === 'pending' ||
        (user.account.state === 'review_needed' &&
          // Condition needs to be changed once the review fields will be updated
          !checkReviewOrChangedFields(item.fields, user.account.review_state))
      ) {
        item.disabled = true;
      }
    }
  }
  if (
    !user.account.doi &&
    user.account.state === 'onboarding' &&
    !returnArr[0].isError
  ) {
    if (user?.account?.touched_fields?.includes('doi')) {
      returnArr[0].isError = true;
      returnArr[0].isSuccess = false;
    } else {
      returnArr[0].isError = null;
      returnArr[0].isSuccess = null;
    }
  }

  if (user.account.doi) {
    returnArr[0].isError = false;
    returnArr[0].isSuccess = true;
  }
  return returnArr;
}

export function validateAllItems(
  user,
  items,
  values,
  errors,
  intl,
  isNicknameDataLoading,
  nicknameError
) {
  if (!user.account.doi || isNicknameDataLoading || nicknameError) {
    return false;
  }
  for (const item of items) {
    if (
      user.account.state === 'review_needed' &&
      !checkReviewOrChangedFields(item.fields, user.account.review_state)
    ) {
      // If the state is review_needed check only the incorrect items
      continue;
    }
    if (!checkIfAllFieldsAreValid(item.fields, errors)) {
      return false;
    } else if (
      !checkIfAllRequiredFieldsHaveValuesAndAreValid(item.fields, values, intl)
    ) {
      return false;
    } else if (item.isError) {
      return false;
    }
  }
  return true;
}

export function hasReviewError(user, field, touched) {
  return (
    user.account.review_state.includes(field) &&
    !user.account.review_state_changed_fields.includes(field) &&
    !touched
  );
}

export function reviewDisabledField(user, field) {
  if (user.account.state === 'review_needed') {
    if (user.account.review_state.includes(field)) {
      return false;
    }
    return true;
  }
  return false;
}

export function generateRequestData(user, values, item, inArray) {
  let request = {};
  let fieldsToAssign = item.fields;
  if (inArray && inArray?.length > 0) {
    fieldsToAssign = inArray;
  }
  for (const field of item.fields) {
    if (!fieldsToAssign.includes(field)) {
      continue;
    }
    if (user.account.state === 'review_needed') {
      if (user.account.review_state.includes(field)) {
        request[field] = values[field];
      }
    } else {
      request[field] = values[field];
    }
  }
  return request;
}

export const itemTabIndexes = new Map([
  [
    // Index of the current item
    0,
    {
      // Tab indices of back/next/support buttons on the current item
      back: 2,
      next: 3,
      support: 4,
    },
  ],
  [
    1,
    {
      back: 2,
      next: 4,
      support: 5,
    },
  ],
  [
    2,
    {
      back: 20,
      next: 21,
      support: 22,
    },
  ],
  [
    4,
    {
      back: 6,
      next: 7,
      support: 8,
    },
  ],
  [
    5,
    {
      back: 2,
      next: 4,
      support: 5,
    },
  ],
]);
