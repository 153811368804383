import './ProfilePictures.scss';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { uuid } from '../../../../utils';
import { getUpload } from '../../../../utils/uploadUtils';
import StyledLink from '../../../StyledLink/StyledLink';
import ThumbnailItem from '../../../ThumbnailItem/ThumbnailItem';
import Uploader from '../../../Uploader';

function ProfilePictures({
  updateImages = () => {},
  isMobile,
  openOverlay = () => {},
  uploads = [],
  uploadFile,
  spinner = () => {},
  profilePictureError,
}) {
  const intl = useIntl();

  const intervalRef = useRef(null);
  const [inProgressImgs, setInProgressImgs] = useState([]);
  const [inQueueImgs, setInQueueImgs] = useState([]);
  const [approvedImgs, setApprovedImgs] = useState([]);
  const [defaultPicState, setDefaultPicState] = useState(null);

  const [defaultPictureId, setDefaultPictureId] = useState(null);
  const [profilePicError, setProfilePicError] = useState({
    top: null,
    bottom: null,
  });
  const [imageError, setImageError] = useState(null);
  const imagesMax = [...approvedImgs, ...inProgressImgs, ...inQueueImgs].length;

  const indicator = 'profilepictures';

  const { uploadIndicator, uploadError, uploadResult } = getUpload(
    uploads,
    indicator
  );

  const [, approvedPicturesReq] = useAxios(
    {
      url: '/user/profile-pictures',
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  let todayDate = new Date().toISOString().slice(0, 10);

  const [, cleanUpReq] = useAxios(
    {
      url: '/user/profile-pictures/cleanup',
      method: 'PUT',
      data: {
        date: todayDate,
      },
    },
    {
      manual: true,
    }
  );

  const acceptedFileTypes = {
    'image/jpeg': ['.jpg', '.jpeg', '.jpe'],
    'image/png': ['.png'],
  };

  useEffect(() => {
    let isMounted = true;
    approvedPicturesReq()
      .then((response) => {
        if (isMounted) {
          setInProgressImgs(response.data.data.in_progress);
          setInQueueImgs(response.data.data.queue);
          setApprovedImgs(response.data.data.approved);
          const defaultPic = response.data.data.approved.filter(
            (i) => i.default === 1
          );
          if (defaultPic.length > 0) {
            setDefaultPicState(defaultPic);
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          if (String(error) === 'Cancel') {
            console.log('re rendering error'); // this is not realy an error
          } else {
            profilePictureError();
            console.log(error);
          }
        }
      });
    cleanUpReq().catch((error) => console.log(error));
    return () => (isMounted = false);
  }, [approvedPicturesReq, cleanUpReq, defaultPictureId, profilePictureError]);

  useEffect(() => {
    let isMounted = true;
    if (inQueueImgs.length > 0) {
      intervalRef.current = setInterval(() => {
        approvedPicturesReq()
          .then((response) => {
            if (isMounted) {
              setInProgressImgs(response.data.data.in_progress);
              setInQueueImgs(response.data.data.queue);
              setApprovedImgs(response.data.data.approved);
            }
          })
          .catch((error) => isMounted && console.log(error));
        cleanUpReq().catch((error) => console.log(error));
        return () => (isMounted = false);
      }, 5000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [approvedPicturesReq, cleanUpReq, inQueueImgs.length]);

  useEffect(() => {
    if (defaultPictureId) {
      const defpic = approvedImgs.filter((i) => i.id === defaultPictureId);
      if (defpic?.length > 0) {
        setDefaultPicState(defpic);
      }
      setProfilePicError(null);
    }
  }, [approvedImgs, defaultPictureId]);

  const deletePic = (id) => {
    const isApproved = approvedImgs.find((item) => item.id === id);
    if (isApproved) {
      const newItems = approvedImgs.filter((item) => item.id !== id);
      setApprovedImgs(newItems);
    } else {
      const newItems = inProgressImgs.filter((item) => item.id !== id);
      setInProgressImgs(newItems);
    }
    setProfilePicError(null);
    setImageError(null);
  };

  const openCam = () => {
    const stamp = uuid();

    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        camstamp: stamp,
        indicator: indicator,
        uploadPath: '/user/profile-pictures',
        title: intl.formatMessage({ id: 'SNAPSHOT_PROFILE_PICTURE_TITLE' }),
        minScreenshotWidth: 1080,
        minScreenshotHeight: 1080,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        cropping: true,
      },
    });
  };

  useEffect(() => {
    const localIndicator = indicator;

    if (uploadIndicator === localIndicator) {
      if (uploadError) {
        setImageError(uploadError?.message);
        uploadFile('', false, localIndicator);
      } else if (uploadResult && uploadResult?.data) {
        updateImages(uploadResult.data.data.in_progress);
        setInProgressImgs(uploadResult.data.data.in_progress);
        setInQueueImgs(uploadResult.data.data.queue);
        setApprovedImgs(uploadResult.data.data.approved);
        setImageError(null);
        setProfilePicError(null);
        document
          .getElementById('in-progress')
          .scrollIntoView({ behavior: 'smooth', block: 'end' });
        uploadFile('', false, localIndicator);
      }
    }
  }, [
    uploadIndicator,
    uploadResult,
    uploadError,
    uploadFile,
    indicator,
    intl,
    imageError,
    updateImages,
  ]);
  return (
    <>
      <div className="profile-pictures-wrapper">
        <div
          className={`profile-pictures ${imagesMax < 20 ? '' : 'full-space'}`}
        >
          <div className="content-box default-img">
            <h1 className="headline">
              <FormattedMessage id="PROFILE_PICTURES_DEFAULT_HEADLINE" />
            </h1>
            <div className="content-inner">
              {defaultPicState ? (
                <ThumbnailItem
                  id={defaultPicState[0]?.id}
                  item={defaultPicState[0]}
                  type={'photo'}
                  itemMenuVisible={false}
                  spinner={spinner}
                  setImageError={setImageError}
                  component={'profilePicture'}
                  showEditIcon={true}
                  squareImage={true}
                />
              ) : (
                <ThumbnailItem
                  item={{}}
                  type={'photo'}
                  itemMenuVisible={false}
                  spinner={spinner}
                  setImageError={setImageError}
                  component={'profilePicture'}
                  showEditIcon={true}
                  squareImage={true}
                />
              )}
              <div className="default-image-text">
                <p>
                  {intl.formatMessage({
                    id: 'PROFILE_PICTURES_DEFAULT_TEXT_1',
                  })}
                </p>
                <p>
                  <FormattedMessage
                    id="PROFILE_PICTURES_DEFAULT_TEXT_2"
                    values={{
                      link: (
                        <StyledLink
                          key="profile-pictures-wiki-link"
                          url="/wiki/start#profileImage"
                          text={'PROFILE_PICTURES_DEFAULT_TEXT_LINK'}
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          {imagesMax < 20 ? (
            <Uploader
              indicator={indicator}
              uploadPath="/user/profile-pictures"
              cropping={true}
              minDimensions={{ width: 1080, height: 1080 }}
              label="PROFILE_PICTURES_UPLOADER"
              acceptedFileTypes={acceptedFileTypes}
              handleError={(error) => {
                if (error) {
                  setImageError(error);
                }
              }}
              imageError={imageError}
              hasChildrenWrapper={true}
              dropzoneProps={{ multiple: false }}
              className="profile-picture"
              openCam={() => openCam()}
              isMobile={isMobile}
            />
          ) : null}
        </div>

        {approvedImgs.length !== 0 ? (
          <div className="content-box">
            <h2 className="headline">
              <FormattedMessage id="PROFILE_PICTURES_GALLERY" /> ({imagesMax}
              /20)
            </h2>
            {profilePicError?.top ? (
              <div className="error-message">
                {intl.formatMessage({ id: profilePicError.top?.toUpperCase() })}
              </div>
            ) : null}
            <div className="image-grid">
              {approvedImgs.map((item) => (
                <div className={item.default ? 'main-img' : ''} key={item.id}>
                  <ThumbnailItem
                    key={item.id}
                    id={item.id}
                    item={item}
                    type={'photo'}
                    itemDeleted={deletePic}
                    spinner={spinner}
                    setImageError={setImageError}
                    component={'profilePicture'}
                    setImageAsDefault={true}
                    squareImage={true}
                    setDefaultPictureId={setDefaultPictureId}
                    showEditIcon={item.default}
                    setProfilePicError={setProfilePicError}
                    canDeleteImage={true}
                    errorPosition={'top'}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div id="in-progress">
          {inQueueImgs.length !== 0 || inProgressImgs.length !== 0 ? (
            <div className="content-box">
              <h2 className="headline">
                <FormattedMessage id="PROFILE_PICTURES_PROGRESS" />
              </h2>
              {profilePicError?.bottom ? (
                <div className="error-message">
                  {intl.formatMessage({
                    id: profilePicError.bottom?.toUpperCase(),
                  })}
                </div>
              ) : null}
              <div className="image-grid">
                {inQueueImgs.map((item) => (
                  <ThumbnailItem
                    key={item.id}
                    id={item.id}
                    item={item}
                    type={
                      item.state === 'uploaded' ||
                      (item.state === 'converting' && item.status !== 'failed')
                        ? 'spinner'
                        : 'photo'
                    }
                    spinner={spinner}
                    setImageError={setImageError}
                    component={'profilePicture'}
                    canDeleteImage={true}
                    setProfilePicError={setProfilePicError}
                    errorPosition={'bottom'}
                  />
                ))}
                {inProgressImgs.map((item) => (
                  <ThumbnailItem
                    key={item.id}
                    id={item.id}
                    item={item}
                    type={'photo'}
                    itemDeleted={deletePic}
                    spinner={spinner}
                    setImageError={setImageError}
                    component={'profilePicture'}
                    canDeleteImage={true}
                    squareImage={true}
                    rejectedProfilePic={item.state === 'rejected'}
                    setProfilePicError={setProfilePicError}
                    errorPosition={'bottom'}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ProfilePictures;
