export default {
  SNAPSHOT_PROFILE_PICTURE_TITLE: `Profile picture - Take snapshot`,
  SNAPSHOT: `Snapshot`,
  TAKE_SNAPSHOT: 'Take a snapshot with your webcam.',
  UPLOADER_PICTURE_CROPPER_TEXT_1: `Here you can choose the exact section from your picture.`,
  UPLOADER_PICTURE_CROPPER_TEXT_2: `The formats 1:1, 4:3 and 16:9 are used in our communities. It is recommended to use images in landscape format.`,
  UPLOADER_PICTURE_CROPPER_TEXT_3: `The frame tool allows you to select the best area of your image in the correct format.`,
  UPLOADER_PICTURE_CROPPER_TEXT_4: `You can adjust the frame as you wish and expand it from the minimum size to the maximum by dragging the corners.`,

  GALLERY_PREVIEW_IMAGE: `Preview image`,
  GALLERY_UPLOADER_HEADLINE: `Drag your photos or zip folder here`,
  GALLERY_VIDEOS_UPLOADER_HEADLINE: `Drag your video file here`,
  GALLERY_VIDEOS_UPLOADER_TEXT_1: `Upload a video in mp4, avi, mpg, or wmv format`,
  GALLERY_UPLOADER_TEXT_1: `Photos in your gallery have to be at least 800 x 600 pixels`,
  GALLERY_UPLOADER_TEXT_2: `Your preview image has to be at least 1024 x 576 pixels`,

  UPLOAD_COMMENT: `Comment:`,
  BUTTON_CHOOSE_FILE: `Choose file`,
  BUTTON_OPEN_CAMERA: `Open camera`,
  BUTTON_ZIP_ARCHIVE: `ZIP archive`,
  BUTTON_CROP_IMAGE: `Crop image`,
  BUTTON_UPLOADER_UPLOAD_NOW: `Upload now`,
  BUTTON_UPLOAD_TAKE_IMAGE: `Use image`,
  BUTTON_UPLOAD_IMAGE: `Upload image`,
  BUTTON_REVERT: `Cancel`,
  UPLOAD_DROP_INDICATOR: 'Drop your files here',

  FILE_TYPE_NOT_SUPPORTED:
    'This filetype is not supported. Please upload a different file',
};
