import '../Newsletter.scss';

import { FormattedMessage } from 'react-intl';

import { NEWSLETTER_PAGINATION_SIZE } from '../../../../constants';
import Pagination from '../../../Pagination/Pagination';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import DateItem from '../../../Tables/TableItems/DateItem';

function NewsletterList({
  newsletters,
  loading,
  pageNumber,
  offset,
  setPageNumber,
  setOffset,
  data,
  setOpenNewsletterId,
  totalNewsletter,
}) {
  return loading ? (
    <SpinnerComponent />
  ) : (
    <div className="data-wrapper">
      <h1 className="headline">
        <FormattedMessage id="HEADER_TITLE_NEWSLETTER" />
      </h1>
      <div className="newsletter-list">
        {newsletters.length ? (
          newsletters.map((item) => {
            return (
              <div
                key={item.id}
                className={`btn btn-icon btn-primary ${
                  data?.id === item?.id ? 'active' : ''
                }${item.test ? 'test' : ''}`}
              >
                <div
                  className="newsletter-item"
                  onClick={() => setOpenNewsletterId(item.id)}
                >
                  <span className="subject-item">{item.subject}</span>
                  <span className="icon" />
                  <DateItem data={item.date} className="date-item" />
                </div>
              </div>
            );
          })
        ) : (
          <p>
            <FormattedMessage id="ERROR_NO_NEWS_LETTER" />
          </p>
        )}
      </div>
      <Pagination
        offset={offset}
        totalItems={totalNewsletter?.total}
        pageNumber={pageNumber}
        setOffset={setOffset}
        setPageNumber={setPageNumber}
        paginationSize={NEWSLETTER_PAGINATION_SIZE}
      />
    </div>
  );
}

export default NewsletterList;
