import './Uploader.scss';

import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { lStorage } from '../../services/storage';
import { uuid } from '../../utils';
import GalleryPhotoUploader from './GalleryPhotoUploader/GalleryPhotoUploader';
import GalleryVideosUploader from './GalleryVideosUploader/GalleryVideosUploader';
import StandardUploader from './StandardUploader/StandardUploader';
import { checkImage, getImageSource } from './Uploader.functions';

function Uploader({
  label,
  markAsRequired,
  acceptedFileTypes,
  handleDrop = () => {},
  openCam = () => {},
  handleError,
  helpContent,
  dropzoneProps = {},
  hasChildrenWrapper = true,
  children,
  isMobile,
  imageError,
  uploadPath,
  uploadFormData = new FormData(),
  cropping = false,
  minDimensions = {},
  minGalleryDimensions,
  onlineImage,
  className = '',
  uploadingNotAvaialable,
  updateUploadingVisibility,
  hasChildren,
  openOverlay = () => {},
  indicator = '',
  uploadFile = () => {},
  onChoose = () => {},
  videoUrl,
  uploadZip,
  uploadThumbnail,
  additionalAcceptedFileTypes,
  type,
  thumbnailUrl,
  title,
  activeGallery,
  imageSuccess,
  withVideo,
  thumbnailComment,
  profileVideosLoading,
  singleFile = false,
  containsVideo,
  poster,
  widescreen = false,
}) {
  const intl = useIntl();
  const translatedLabel = intl.formatMessage({ id: label });
  const fileErrorMessage = intl.formatMessage({id: 'FILE_TYPE_NOT_SUPPORTED'})

  const [isHelpContentShown, setIsHelpContentShown] = useState(false);
  const doUpload = useCallback(
    async (acceptedFiles, completedCrop, rotate) => {
      handleDrop(acceptedFiles);
      const data = new FormData();
      if (singleFile) {
        data.append('file', acceptedFiles[0], acceptedFiles[0].name);
      } else {
        acceptedFiles.forEach((file) => {
          data.append('files[]', file, file.name);
        });
      }

      if (completedCrop) {
        data.append('crop[0][x]', completedCrop.x);
        data.append('crop[0][y]', completedCrop.y);
        data.append('crop[0][width]', completedCrop.width);
        data.append('crop[0][height]', completedCrop.height);
      }

      if (rotate) {
        data.append('rotate[]', rotate);
      }

      for (let pair of uploadFormData.entries()) {
        data.append(pair[0], pair[1]);
      }
      if (uploadPath === '/user/profile-videos') {
        uploadFile(uploadPath, data, indicator, true);
      } else {
        uploadFile(uploadPath, data, indicator);
      }

      // Setting the focus for the tab index after upload
      document.getElementById('chooser-picture-upload').focus();
    },
    [handleDrop, uploadPath, uploadFile, indicator, uploadFormData, singleFile]
  );

  const openPictureCropper = useCallback(
    (acceptedfiles, source, altUploadPath) => {
      const stamp = uuid();
      openOverlay({
        stamp,
        Component: 'PictureCropper',
        props: {
          imageSource: source,
          cropperstamp: stamp,
          uploadFormData,
          uploadPath: altUploadPath ? altUploadPath : uploadPath,
          indicator,
          file: acceptedfiles[0],
          title: translatedLabel,
          minDimensions,
          widescreen,
        },
      });
    },
    [
      widescreen,
      openOverlay,
      uploadFormData,
      uploadPath,
      indicator,
      translatedLabel,
      minDimensions,
    ]
  );

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      onChoose();
      if (rejectedFiles.length) {
        const fileError = rejectedFiles[0].errors[0]
        if(fileError.code === 'file-invalid-type') {

        handleError(fileErrorMessage);
        }
      }

      setIsHelpContentShown(false);

      if (cropping) {
        if (
          !dropzoneProps?.multiple &&
          minDimensions?.width &&
          minDimensions?.height
        ) {
          try {
            const iSource = await checkImage(
              await getImageSource(acceptedFiles[0], intl),
              intl,
              minDimensions
            );
            openPictureCropper(acceptedFiles, iSource);
          } catch (error) {
            return handleError(error.message);
          }
        }
      } else {
        if (acceptedFiles.length > 0) {
          doUpload(acceptedFiles);
        }
      }
    },
    [
        fileErrorMessage,
      onChoose,
      cropping,
      handleError,
      dropzoneProps?.multiple,
      minDimensions,
      intl,
      openPictureCropper,
      doUpload,
    ]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: { ...acceptedFileTypes, ...additionalAcceptedFileTypes },
  });

  function setFsk18() {
    lStorage.setItem('fsk18', 1);
    updateUploadingVisibility(true);
  }

  const wrapperClassName = classNames('uploader', className, {
    'drag-active': isDragActive,
    'help-content-visible': isHelpContentShown,
    'uploader-has-error': (imageError && imageError !== '') || thumbnailComment,
  });

  const labelClassName = classNames('label', {
    'field-label-required-mark-container': markAsRequired,
  });

  return (
    <div>
      {type === 'gallery-photo' ? (
        <GalleryPhotoUploader
          handleDrop={handleDrop}
          uploadPath={uploadPath}
          uploadZip={uploadZip}
          uploadThumbnail={uploadThumbnail}
          uploadFormData={uploadFormData}
          uploadFile={uploadFile}
          indicator={indicator}
          openOverlay={openOverlay}
          onChoose={onChoose}
          handleError={handleError}
          dropzoneProps={dropzoneProps}
          minDimensions={minDimensions}
          minGalleryDimensions={minGalleryDimensions}
          acceptedFileTypes={acceptedFileTypes}
          additionalAcceptedFileTypes={additionalAcceptedFileTypes}
          wrapperClassName={wrapperClassName}
          labelClassName={labelClassName}
          title={title}
          openPictureCropper={openPictureCropper}
          thumbnailUrl={thumbnailUrl}
          imageError={imageError}
          imageSuccess={imageSuccess}
          activeGallery={activeGallery}
          isMobile={isMobile}
          videoUrl={videoUrl}
          withVideo={withVideo}
          thumbnailComment={thumbnailComment}
        />
      ) : type === 'gallery-video' ? (
        <GalleryVideosUploader
          wrapperClassName={wrapperClassName}
          labelClassName={labelClassName}
          isMobile={isMobile}
          dropzoneProps={dropzoneProps}
          uploadFile={uploadFile}
          uploadPath={uploadPath}
          uploadFormData={uploadFormData}
          indicator={indicator}
          title={title}
          activeGallery={activeGallery}
          thumbnailUrl={thumbnailUrl}
          handleError={handleError}
          minDimensions={minDimensions}
          openPictureCropper={openPictureCropper}
          uploadThumbnail={uploadThumbnail}
          videoError={imageError}
          videoSuccess={imageSuccess}
          containsVideo={containsVideo}
          thumbnailComment={thumbnailComment}
        />
      ) : (
        <StandardUploader
          wrapperClassName={wrapperClassName}
          indicator={indicator}
          labelClassName={labelClassName}
          uploadingNotAvaialable={uploadingNotAvaialable}
          markAsRequired={markAsRequired}
          isMobile={isMobile}
          openCam={openCam}
          hasChildren={hasChildren}
          hasChildrenWrapper={hasChildrenWrapper}
          uploaderChildren={children}
          imageError={imageError}
          onlineImage={onlineImage}
          helpContent={helpContent}
          poster={poster}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          open={open}
          setFsk18={setFsk18}
          translatedLabel={translatedLabel}
          isHelpContentShown={isHelpContentShown}
          setIsHelpContentShown={setIsHelpContentShown}
          videoUrl={videoUrl}
          withVideo={withVideo}
          profileVideosLoading={profileVideosLoading}
        />
      )}
    </div>
  );
}

export default Uploader;
