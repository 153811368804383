import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import MessengerIcon from '../../../../../assets/img/svg/camMessenger.svg';
import videoPosterImgDe from '../../../../../assets/img/videoPoster/webcam_settings_de.jpg';
import videoPosterImgEn from '../../../../../assets/img/videoPoster/webcam_settings_en.jpg';
import BackButton from '../../../../BackButton';
import VideoWithOverlay from '../../../../VideoWithOverlay';

function LivecamSetting({
  locale,
  isLivecamCreator,
  nickname,
  onBackButtonClicked,
}) {
  const intl = useIntl();
  const account = nickname
    ? nickname
    : intl.formatMessage({ id: 'LINK_TEXT_ACCOUNT' });
  const video =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/webcam_settings_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/webcam_settings_de.mp4`;
  const videoPosterImg = locale === 'en' ? videoPosterImgEn : videoPosterImgDe;

  return (
    <div className="wiki-content livecam-setting-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="LIVECAM_SETTING_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="LIVECAM_SETTING_CONTENT_1" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="WIKI_HEADLINE_VIDEO" />
      </h2>
      <VideoWithOverlay
        video={video}
        poster={videoPosterImg}
        showOverlay={false}
      />

      <h3 className="headline">
        <FormattedMessage id="LIVECAM_SETTING_HEADLINE_2" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_2_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>

      <h3 className="headline">
        <FormattedMessage id="LIVECAM_SETTING_HEADLINE_3" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="LIVECAM_SETTING_CONTENT_STEP_4_CONTENT"
              values={{
                link: isLivecamCreator ? (
                  <Link
                    key="livecam-link-settings"
                    to="/my-account/settings/livecam"
                  >
                    <FormattedMessage
                      id="LINK_TEXT_CAM_SETTINGS"
                      values={{ account }}
                    />
                  </Link>
                ) : (
                  <FormattedMessage
                    id="LINK_TEXT_CAM_SETTINGS"
                    values={{ account }}
                  />
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_4_1_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_4_2_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_4_3_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_5" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="LIVECAM_SETTING_CONTENT_STEP_5_CONTENT"
              values={{
                link: isLivecamCreator ? (
                  <Link
                    key="livecam-link-prices"
                    to="/my-account/settings/prices"
                  >
                    <FormattedMessage
                      id="LINK_TEXT_PRICE"
                      values={{ account }}
                    />
                  </Link>
                ) : (
                  <FormattedMessage id="LINK_TEXT_PRICE" values={{ account }} />
                ),
              }}
            />
          </p>
        </div>
      </div>

      <h3 className="headline">
        <FormattedMessage id="LIVECAM_SETTING_HEADLINE_4" />
      </h3>
      <p>
        <FormattedMessage id="LIVECAM_SETTING_CONTENT_4" />
      </p>
      <p className="has-margin-bottom">
        <FormattedMessage id="LIVECAM_SETTING_CONTENT_4_1" />
      </p>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_6" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_6_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_6_1_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_7" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_7_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_7_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline no-margin">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_8" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="LIVECAM_SETTING_CONTENT_STEP_8_CONTENT"
              values={{
                icon: (
                  <ReactSVG
                    key="messenger-icon"
                    src={MessengerIcon}
                    wrapper="span"
                    className="wiki-content-icon messenger-icon"
                  />
                ),
                link: isLivecamCreator ? (
                  <Link key="livecam-link-messenger" to="/multi-messenger">
                    <FormattedMessage id="MENU_ITEM_CAM_MESSENGER" />
                  </Link>
                ) : (
                  <FormattedMessage id="MENU_ITEM_CAM_MESSENGER" />
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_8_1_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="LIVECAM_SETTING_CONTENT_STEP_8_2_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LivecamSetting;
