import './VirtualGifts.scss';

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

function VirtualGifts({ items }) {
  const intl = useIntl();

  const [routes, setRoutes] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/my-account/virtual-gifts') {
      navigate('/my-account/virtual-gifts/my-virtual-gifts');
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const routes = items.map((route, i) => {
      const Component = route.component;
      return (
        <Route
          key={`/my-account/virtual-gifts${route.path}`}
          path={route.path + '/*'}
          element={<Component />}
        />
      );
    });

    setRoutes(routes);
  }, [location.pathname, intl, items]);
  return (
    <>
      <Routes>{routes}</Routes>
    </>
  );
}

export default VirtualGifts;
