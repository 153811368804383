import { useDropzone } from 'react-dropzone';

function useGetPhotoGalleriesDropzone({
  acceptedFileTypes,
  additionalAcceptedFileTypes,
  dropzoneProps,
  onDrop,
  onDropThumbnail,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: { ...acceptedFileTypes, ...additionalAcceptedFileTypes },
  });

  const {
    getRootProps: getRootPropsImage,
    getInputProps: getInputPropsImage,
    open: openImage,
  } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: acceptedFileTypes,
  });

  const {
    getRootProps: getRootPropsZip,
    getInputProps: getInputPropsZip,
    open: openZip,
  } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: additionalAcceptedFileTypes,
  });

  const {
    getRootProps: getRootPropsThumbnail,
    getInputProps: getInputPropsThumbnail,
    open: openThumbnail,
  } = useDropzone({
    ...dropzoneProps,
    onDrop: onDropThumbnail,
    noClick: true,
    noKeyboard: true,
    accept: acceptedFileTypes,
  });

  return {
    getRootProps,
    getInputProps,
    getRootPropsImage,
    getInputPropsImage,
    openImage,
    getRootPropsZip,
    getInputPropsZip,
    openZip,
    getRootPropsThumbnail,
    getInputPropsThumbnail,
    openThumbnail,
  };
}

export default useGetPhotoGalleriesDropzone;
